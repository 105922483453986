const EventType = require('../../../../../enums/event_types');
const Messages = require('../../../../../api/messages');
const APIRoutes = require('../../../../../api/routes');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');

module.exports = {
    mixins: [MasterWidget],
    methods: {
        emitEvent(event, value = 'NA', opts = {}) {
            return (e) => {
                e.preventDefault();
                const payload = {
                    commit: this.$store.commit,
                    eventType: EventType.ALL,
                    event,
                    eventValue: value,
                    eventOptions: opts,
                };

                this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
            };
        },
        open(e) {
            e.preventDefault();
            const device = this.$store.state.infos.device;
            this.$store.dispatch('call_api', {
                path: APIRoutes.desk_close,
                method: 'PUT',
                body: {
                    _id: device._id,
                    status: { open: true, pause: false },
                },
            });
            /* this.$store.dispatch('api_call', (`${this.prefix}/idevice/close`, 'close-desk', { _id: device._id, status: { open: true } });*/
            // this.emitEvent(this.events.home)(e);
        },
    },
    computed: {
        actions() {
            const suborganization = this.$store.state.infos.suborganization;
            const device = this.$store.state.infos.device;
            const close = {
                render: suborganization.floating_desks,
                text: this.texts.closing[this.lang],
                icon: 'fa fa-times',
                click: this.emitEvent(this.events.desk_closing),
            };


            const open = {
                render: true,
                text: this.texts.open[this.lang],
                icon: 'fa fa-check',
                click: this.open,
            };

            const actions = [];
            if (device.status.open) {
                actions.push(close);
            } else {
                actions.push(open);
            }

            /*actions.push({
                render: true,
                text: this.texts.settings[this.lang],
                icon: 'fa fa-cog',
                click: this.emitEvent(this.events.desk_settings),
            });*/

            return actions;
        },
        inactive_device() {
            return this.$store.state.infos.device.inactive || false;
        },
    },
};
