const _ = require('lodash');
const APIRoutes = require('../../api/routes');
const BrowserUtils = require('../../utils/browser');
const Messages = require('../../api/messages');

module.exports = {
    data() {
        return {
            state: {
                email: '',
                password: '',
                validated: true,
            },
        };
    },
    methods: {
        login() {
            this.state.validated = true;

            if (this.state.email.trim() === '' || this.state.password.trim() === '') {
                this.state.validated = false;
                return;
            }

            this.$store.dispatch('call_api', {
                path: APIRoutes.authenticate,
                method: 'POST',
                body: {
                    email: this.state.email,
                    password: this.state.password,
                },
            });
        },
    },
    watch: {
        login_result(lr) {
            if (!lr) {
                return;
            }

            this.state.validated = lr.ok;
            if (this.state.validated) {
                const device = lr.device;
                BrowserUtils.localSet('device_account', JSON.stringify({
                    key: _.get(device, ['auth', 'token', 'key']),
                    secret: _.get(device, ['auth', 'token', 'secret']), 
                }));
                this.$router.push({ path: `/${device.type}/${device.mac}` });
            }
        },
    },
    computed: {
        login_result() {
            const content = this.$store.state.content;
            if (content && content instanceof Array && content.length > 0) {
                return content[0];
            }
            return null;
        },
    },
    beforeMount() {
        BrowserUtils.localRemove('device_account');
        document.documentElement.className += ` account`;
        this.$store.commit(Messages.ERROR, {
            error_type: null,
        });
    }
};
