function dist2(v, w) { return ((v.x - w.x) ** 2) + ((v.y - w.y) ** 2); }
function distToSegmentSquared(p, v, w) {
    const l2 = dist2(v, w);
    if (l2 === 0) return dist2(p, v);
    let t = ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2;
    t = Math.max(0, Math.min(1, t));
    return dist2(p, { x: v.x + t * (w.x - v.x),
        y: v.y + t * (w.y - v.y) });
}
function distToSegment(p, v, w) { return Math.sqrt(distToSegmentSquared(p, v, w)); }

function get_polygons_from_divs(divs) {
    return divs.map((link) => {
        const rect = link.getBoundingClientRect();
        const top_line = [{ x: rect.left, y: rect.top }, { x: rect.right, y: rect.top }];
        const bottom_line = [{ x: rect.left, y: rect.bottom }, { x: rect.right, y: rect.bottom }];
        const left_line = [{ x: rect.left, y: rect.top }, { x: rect.left, y: rect.bottom }];
        const right_line = [{ x: rect.right, y: rect.top }, { x: rect.right, y: rect.bottom }];
        return [top_line, right_line, bottom_line, left_line];
    });
}

function get_closest_polygon(polygons, point) {
    let min_index = -1;
    let min_distance = Number.MAX_VALUE;

    polygons.forEach((polygon, idx) => {
        const _distance = polygon.reduce((min, line) => {
            const d = distToSegment(point, line[0], line[1]);
            if (min === undefined) {
                return d;
            }
            if (min > d) {
                return d;
            }
            return min;
        }, undefined);

        if (min_distance > _distance) {
            min_distance = _distance;
            min_index = idx;
        }
    });
    return min_index;
}

module.exports = {
    get_polygons_from_divs,
    get_closest_polygon,
};
