const _ = require('lodash');
const Vuex = require('vuex');
const Messages = require('../../../../../api/messages');
const EventType = require('../../../../../enums/event_types');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');
const WithTimeout = require('../../../mixins/with_timeout/WithTimeout');
const PrequalsMixin = require('../../../mixins/prequalifications/PrequalificationsMixin');

module.exports = {
    mixins: [MasterWidget, WithTimeout, PrequalsMixin],
    data() {
        return {
            state: {
                selected: [],
                current_page: 0,
            },
        };
    },
    methods: {
        select_prequalification(key, e) {
            try {
                e.preventDefault();
                e.stopPropagation();
            } catch (err) {} //eslint-disable-line

            const found = this.state.selected.find(k => k === key);
            if (!found && this.conditions.capped_selection
                && (this.variables.max_selection || Infinity) === this.state.selected.length) {
                return;
            }

            if (!found) {
                this.state.selected.push(key);
            } else {
                this.state.selected = this.state.selected.filter(k => k !== key);
            }
        },
        validate(e) {
            e.preventDefault();
            e.stopPropagation();
            this.trigger_payload(this.state.selected.map(k => [k]),
                this.variables.trigger, true);
        },
        tap_validate(e) {
            if (this.conditions.disable_tap) {
                return;
            }
            this.validate(e);
        },
        swipe_validate(dir, e) {
            if (this.conditions.disable_swipe) {
                return;
            }
            this.validate(e);
        },
        tap(key) {
            if (this.conditions.disable_tap) {
                return () => {};
            }
            return e => this.select_prequalification(key, e);
        },
        swipe(key) {
            if (this.conditions.disable_swipe) {
                return () => {};
            }
            return (dir, e) => this.select_prequalification(key, e);
        },
        change_page(page) {
            const validated_page = Math.max(0, Math.min(this.total_pages - 1, page));
            this.state.current_page = validated_page;
        },
        tap_page(page) {
            return () => this.change_page(page);
        },
        swipe_page(page) {
            return () => this.change_page(page);
        },
    },
    watch: {
        depth(newDepth) {
            this.state.depth = newDepth;
            this.state.path = this.$store.state.template.view_infos.prequalification_path;
            this.state.prequalificationKeys = this.computeKeys(this.state.path);
        },
    },
    computed: _.merge({
        total_pages() {
            return this.prequalifications_per_page.length;
        },
        prequalifications_per_page() {
            const per_page = this.variables.items_per_page || 4;
            return this.state.prequalificationKeys
                .reduce((rows, key, index) => (index % per_page === 0 ? rows.push([key])
                            : rows[rows.length - 1].push(key)) && rows, []);
        },
        matrix() {
            return page => this.prequalifications_per_page[page]
                    .reduce((rows, key, index) => (index % 2 === 0 ? rows.push([key])
                            : rows[rows.length - 1].push(key)) && rows, []);
        },
        is_checked() {
            return key => this.state.selected.find(k => k === key);
        },
        max_selection_reached() {
            return (key) => {
                const found = this.state.selected.find(k => k === key);
                if (found) {
                    return false;
                }

                if (this.conditions.capped_selection
                    && (this.variables.max_selection || Infinity)
                    === this.state.selected.length) {
                    return true;
                }
                return false;
            };
        },
    }, Vuex.mapGetters(['depth'])),
};
