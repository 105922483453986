const MasterWidget = require('../../../mixins/master_widget/MasterWidget');
const moment = require('moment');
const momenttz = require('moment-timezone');
// Workaround with browserify to include all locales of moment
require('moment/min/locales.min');

module.exports = {
    mixins: [MasterWidget],
    data() {
        return {
            state: {
                current_datetime: momenttz.tz(this.$store.state.infos.suborganization.tz),
                interval: null,
            },
        };
    },
    methods: {
        update_datetime() {
            this.state.current_datetime = momenttz.tz(this.$store.state.infos.suborganization.tz);
        },
    },
    computed: {
    },
    beforeCreate() {
        moment.locale(this.$store.state.interfaceLang.toLowerCase());
    },
    beforeDestroy() {
        if (this.state.interval) {
            clearInterval(this.state.interval);
        }
    },
    mounted() {
        this.state.interval = setInterval(this.update_datetime.bind(this),
                30000);
    },
};
