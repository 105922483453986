const _ = require('lodash');
const moment = require('moment');
const momenttz = require('moment-timezone');
const Messages = require('../../../../../api/messages');
const EventType = require('../../../../../enums/event_types');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');
const WithTimeout = require('../../../mixins/with_timeout/WithTimeout');
const Utils = require('../../../../../utils/utils');
const Handlebars = require('../../../../../../../app/modules/utils/ehandlebars');
const APIRoutes = require('../../../../../api/routes');

const HoursSelection = require('./subcomponents/HoursSelection.vue');
const Customer = require('./subcomponents/Customer.vue');

module.exports = {
    mixins: [MasterWidget, WithTimeout],
    components: {
        HoursSelection,
        Customer,
    },
    data() {
        return {
            state: {
                step: 'selection_1',
                selection: '',
                skip: 0,
            },
        };
    },
    methods: {
        customersPageChanged(newSkip) {
            this.state.skip = newSkip;
        },
        selectCustomer(customer) {
            const { customerEvent, registrationInfo } = this.variables;
            
            let input = null; 
            if(registrationInfo.startsWith('code')) {
                const code = (customer.extra_codes || []).find((c) => c.name === registrationInfo)
                input = code ? code.value : null;
            } else {
                input = customer[registrationInfo];
            }

            this.emitEvent(customerEvent || 'customer', {
                registration: {
                    customer: {
                        input,
                    },
                },
            });
        },
        emitEvent(type, options = {}) {
            console.log('emit event', type, options);
            const payload = {
                eventType: EventType.ALL,
                eventValue: 'NA',
                event: type,
                eventOptions: options,
                commit: this.$store.commit,
            };
            return this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
        },
        select(e, hour) {
            e.preventDefault();
            const now = moment();
            const date = now.format('YYYY-MM-DD');
            if (hour.start < 10) {
                hour.start = `0${hour.start}`;
            }
            if (hour.end < 10) {
                hour.end = `0${hour.end}`;
            }

            const tz = this.$store.state.infos.suborganization.tz;
            const start = momenttz(`${date}T${hour.start}`, 'YYYY-MM-DDTHH').tz(tz).toISOString();
            const end = momenttz(`${date}T${hour.end}`, 'YYYY-MM-DDTHH').tz(tz).toISOString();

            this.state.selection = {
                start,
                end,
                suborganization: this.$store.state.infos.suborganization._id,
            };
            this.changeStep('selection_2');
        },
        changeStep(step) {
            this.state.step = step;
        },
        grabCustomers() {
            const { search } = this.variables;
            const { selection } = this.state;
            let finalSearch = search;
            if (search && selection) {
                finalSearch = Handlebars.compile(search)({ selection });
            }

            const route = APIRoutes.searchCustomers(finalSearch);
            this.$store.dispatch('call_api', {
                path: route,
                method: 'GET',
            });
        },
    },
    watch: {
        myStep(nv) {
            if (nv === 'selection_2') {
                this.grabCustomers();
            }
        },
    },
    computed: {
        myStep() {
            return this.state.step;
        },
        customers() {
            return this.$store.state.content || [];
        },
        matrixCustomers() {
            const { customersPerRow } = this.variables;
            return Utils.toMatrix(this.paginatedCustomers, parseInt(customersPerRow, 10));
        },
        paginatedCustomers() {
            const { customersPerPage } = this.variables;
            const { skip } = this.state;
            const c = this.customers.slice(skip, skip + parseInt(customersPerPage, 10));
            c.sort((a, b) => (+moment(a.mtimestamp) - +moment(b.mtimestamp)));
            return c;
        },
        loading() {
            return this.$store.state.loading;
        },
    },
    mounted() {
        const { hoursSelectionEnabled } = this.conditions;
        if (!hoursSelectionEnabled) {
            this.state.selection = {
                suborganization: this.$store.state.infos.suborganization._id,
            };
            this.state.step = 'selection_2';
        }
    },
};
