<template>
<div class="container is-fluid" v-if="!$store.state.loading">
    <div class="columns is-mobile is-centered" v-if="has_only_standard_closing || yes_no_actions_on">
        <div class="column" v-for="info in actions">
            <div v-if="info.render" class="has-equal-height notification fv-notification-padding is-dark has-text-centered" @click.prevent="info.click">
                <p class="icon">
                    <i :class="info.icon"></i>
                </p>
                <p>{{info.text}}</p>
            </div>
        </div>
    </div>
    <div class="columns is-mobile is-centered" v-else-if="has_multiple_actions && nothing_is_on">
        <div class="column" v-for="info in close_actions">
            <div v-if="info.render" class="has-equal-height notification fv-notification-padding is-dark has-text-centered" @click.prevent="info.click">
                <p class="icon">
                    <i :class="info.icon"></i>
                </p>
                <p>{{info.text}}</p>
            </div>
        </div>
    </div>
    <div class="columns is-mobile is-centered" v-else-if="state.redirect_on && redirect_ready">
        <div class="column">
            <div class="columns is-mobile is-centered">
                <div class="column">
                    <div class="card">
                        <header class="card-header">
                            <p class="card-header-title has-text-centered">
                            {{texts.select_prequalifications[lang]}} 
                                </p>
                            <p class="card-header-title is-centered">
                                <a href='#' :class="['button', {'is-danger': all_selected_prequalifications()}]"
                                    @click.prevent="toggle_selection(null, true)">
                                        {{all_selected_prequalifications() ? texts.none[lang] : texts.all[lang]}}
                                </a>
                            </p>
                            <p class="card-header-title is-centered">
                                <a href='#' class="button" @click.prevent="state.see_prequalifications = !state.see_prequalifications">
                                    {{state.see_prequalifications ? texts.show[lang] : texts.hide[lang]}}
                                </a>
                            </p>
                        </header>
                        <div class="card-content" v-if="!state.see_prequalifications">
                            <div class="content">
                                <div class="field" v-for="prequal in last_level_prequalifications">
                                    <p class="control is-expanded">
                                        <a :class="['button is-fullwidth', {'is-danger': state.selected_prequalifications[prequal._id]}]" 
                                            @click.prevent="toggle_selection(prequal._id)">
                                                {{prequal.name[lang]}}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns is-mobile is-centered">
                <div class="column">
                    <div class="card">
                        <header class="card-header">
                            <p class="card-header-title has-text-centered">
                                {{texts.select_desks[lang]}} 
                                </p>
                            <p class="card-header-title is-centered">
                                <a href='#' :class="['button', {'is-danger': all_selected_desks()}]"
                                    @click.prevent="toggle_selection(null, true, 'device')">
                                        {{all_selected_desks() ? texts.none[lang] : texts.all[lang]}}
                                </a>
                            </p>
                            <p class="card-header-title is-centered">
                                <a href='#' class="button" @click.prevent="state.see_desks = !state.see_desks">
                                    {{state.see_desks ? texts.show[lang] : texts.hide[lang]}}
                                </a>
                            </p>
                        </header>
                        <div class="card-content" v-if="!state.see_desks">
                            <div class="content">
                                <div class="field" v-for="desk in redirectable_desks">
                                    <p class="control is-expanded">
                                        <a :class="['button is-fullwidth', {'is-danger': state.selected_desks[desk._id]}]" 
                                            @click.prevent="toggle_selection(desk._id, false, 'device')">
                                                {{desk.name}}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns is-mobile is-centered">
                <div class="column" v-for="info in redirect_actions">
                    <div v-if="info.render" class="has-equal-height notification fv-notification-padding is-dark has-text-centered" @click.prevent="info.click">
                        <p class="icon">
                            <i :class="info.icon"></i>
                        </p>
                        <p>{{info.text}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
module.exports = require('./Close');
</script>
