<template>
    <div
        :class="['card', {'card-disabled': customer.expired}]"
        @click.stop="(e) => {e.preventDefault(); select(e, customer)}"
        v-touch:tap="(e) => {e.preventDefault(); select(e, customer)}"
        v-touch:swipe="(dir, e) => {e.preventDefault(); select(e, customer)}"
        >
            <h5 class="h4-portrait" v-html="customer.name || customer.ticket" />
            <div>
                <h5 class="is-pulled-left h4-portrait has-text-grey" v-html="generateExtraInformation(customer)" />
                <h5 v-if="mode === 'meeting'" class="is-pulled-right h4-portrait" v-html="generateMeetingTime(customer)" />
            </div>
            <h6 class="h5-portrait has-text-grey" v-if="customer.expired" v-html="$text('expiredMeeting', lang)"></h6>
    </div>
</template>

<script>
    module.exports = require('./Customer');
</script>
