module.exports = {
    name: 'EmailPad',
    props: {
        keyClasses: { default: '' },
        primaryColor: {},
        secondaryColor: { default: '#424242' },
        onKeyChange: {},
    },
    data() {
        return {
            keyboard: [
                [this.defaultKey('@'), this.defaultKey('1'), this.defaultKey('2'),
                    this.defaultKey('3'), this.defaultKey('4'), this.defaultKey('5'),
                    this.defaultKey('6'), this.defaultKey('7'), this.defaultKey('8'),
                    this.defaultKey('9'), this.defaultKey('0')],
                [this.defaultKey('A'), this.defaultKey('Z'), this.defaultKey('E'),
                    this.defaultKey('R'), this.defaultKey('T'), this.defaultKey('Y'),
                    this.defaultKey('U'), this.defaultKey('I'), this.defaultKey('O'),
                    this.defaultKey('P'), this.defaultKey('BCKSPACE')],
                [this.defaultKey('Q'), this.defaultKey('S'), this.defaultKey('D'),
                    this.defaultKey('F'), this.defaultKey('G'), this.defaultKey('H'),
                    this.defaultKey('J'), this.defaultKey('K'), this.defaultKey('L'),
                    this.defaultKey('M')],
                [this.defaultKey('W'), this.defaultKey('X'), this.defaultKey('C'),
                    this.defaultKey('V'), this.defaultKey('B'), this.defaultKey('N'),
                    this.defaultKey('.'), this.defaultKey('_'), this.defaultKey('-'),
                    this.defaultKey('+'), this.enterKey()],
                [this.defaultKey(' ')],
            ],
        };
    },
    methods: {
        defaultKey(digit) {
            return {
                class: this.keyClasses,
                primaryColor: this.secondaryColor,
                digit,
                onKeyChange: this.onKeyChange,
            };
        },
        enterKey() {
            return {
                class: this.keyClasses,
                primaryColor: this.primaryColor,
                digit: 'ENTER',
                enter: 'OK',
                onKeyChange: this.onKeyChange,
            };
        },
    },
};
