module.exports = {
    data() {
        return {
            state: {
                sounds: [],
                sound_timeouts: [],
            },
        };
    },
    methods: {
        play_sound() {
            const device = this.$store.state.infos.device;
            if (device.notifications && device.notifications.sound
                && device.notifications.sound.activated) {
                this.state.sounds.push(true);
                this.state.sound_timeouts.push(setTimeout(() => {
                    this.state.sounds.shift();
                    clearTimeout(this.state.sound_timeouts.shift());
                }, 5000));
            }
        },
    },
    mounted() {
    },
    beforeDestroy() {
    },
    computed: {
        sound_path() {
            const device = this.$store.state.infos.device;
            if (device.notifications && device.notifications.sound) {
                return `/public/front/sounds/${device.notifications.sound.filename}`;
            }
            return '';
        },
    },
};
