const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const phone_country = require('./phones_countries');
const country_phone = require('./countries_phones');

function find_phone_code(phone, default_code) {
    let code;
    if (phone.startsWith('+')) {
        if (phone.length < 4) {
            return undefined;
        }
        code = phone.slice(1, 4);
    } else if (phone.startsWith('00')) {
        if (phone.length < 5) {
            return undefined;
        }
        code = phone.slice(2, 5);
    }

    if (code) {
        const code_two = code.slice(0, 2);
        const code_three = code;

        if (phone_country.hasOwnProperty(code_two)) {
            return phone_country[code_two];
        } else if (phone_country.hasOwnProperty(code_three)) {
            return phone_country[code_three];
        }
        return [default_code];
    }
    return [default_code];
}

function parse_phone_number(phone, default_code) {
    const codes = find_phone_code(phone, default_code);

    const all_parsed = [];
    for (const i in codes) {
        const code = codes[i];
        try {
            const parsed = phoneUtil.parse(phone, code);
            all_parsed.push(parsed);
        } catch (err) {
            // noop
        }
    }
    return all_parsed;
}

function validate_phone_number(phone, default_code) {
    const all_parsed = parse_phone_number(phone, default_code);
    let valid = false;
    let parsed;
    for (const i in all_parsed) {
        parsed = all_parsed[i];
        if (phoneUtil.isValidNumber(parsed)) {
            valid = true;
            break;
        }
    }
    return [valid, parsed];
}

function format_phone_number(phone, default_code, form = 'international') {
    const [valid, parsed] = validate_phone_number(phone, default_code);
    if (!valid) {
        return undefined;
    }
    let intl;

    if (form === 'international') {
        intl = phoneUtil.format(parsed, PNF.INTERNATIONAL);
    } else if (form === 'national') {
        intl = `0${parsed.getNationalNumber().toString()}`;
    }
    intl = intl.replace('+', '00');
    intl = intl.replace(/ /g, '');
    intl = intl.replace(/-/g, '');
    return intl;
}

module.exports = {
    parse_phone_number,
    format_phone_number,
    validate_phone_number,
};
