<template>
<div class="columns is-centered is-fullwidth has-no-margin-left-right">
    <audio autoplay="autoplay" v-for="(s, i) in state.sounds" :key="i">
        <source :src="`${sound_path}.mp3`" type="audio/mpeg" />
        <source :src="`${sound_path}.ogg`" type="audio/ogg" />
        <embed hidden="true" autostart="true" loop="false" :src="`${sound_path}.mp3`" />
    </audio>
    <div class="column is-10">
        <div class="columns is-gapless is-multiline" v-for="(c, i) in customers">
            <div class="column is-9">
            </div>
            <div class="column is-3">
                <div class="card desk-card">
                    <h3 v-if="i === 0">{{texts.adviser[lang]}}</h3>
                </div>
            </div>
            <div class="column is-10">
                <div class="card customer-card">
                    <h3 v-if="customers.length > i">{{displayed_customers[i]}}</h3>
                    <h3 class="has-transparent-color" v-else>...</h3>
                </div>
            </div>
            <div class="column is-2">
                <div class="card ticket-card">
                    <h1 v-if="customers.length > i">{{displayed_desks[customers[i].desk]}}</h1>
                    <h1 v-else>{{texts.question_mark[lang]}}</h1>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    module.exports = require('./RecentCallingScreen');
</script>
