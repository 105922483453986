module.exports = {
    depth: state => state.template.view_infos.depth,
    view_infos: state => state.template.view_infos,
    registration: state => state.template.view_infos.registration,
    registration_type: state => state.template.view_infos.registration_type,
    desk_customer: state => state.desk.customer,
    device: (state) => {
        if (state.infos) {
            return state.infos.device || null;
        }
        return null;
    },
    suborganization: (state) => {
        if (state.infos) {
            return state.infos.suborganization || null;
        }
        return null;
    },
    screen_customers: (state) => {
        let last = [];
        let wait = [];
        if (state.infos.last_called_customers) {
            last = state.infos.last_called_customers;
        }
        if (state.infos.waiting_customers) {
            wait = state.infos.waiting_customers;
        }
        return [...last, ...wait];
    },
    last_called_customers: (state) => {
        if (state.infos.last_called_customers) {
            return state.infos.last_called_customers;
        }
        return [];
    },
    waiting_customers: (state) => {
        if (state.infos.waiting_customers) {
            return state.infos.waiting_customers;
        }
        return [];
    },
    next_error: state => state.desk.next_error,
    remove_name: state => state.desk.remove_name,
    vibrator_configuration: state => state.machine.vibrator_configuration,
    barcode_result: state => state.machine.barcode_result,
    global_waiting: state => state.machine.global_waiting,
    texts: state => (type) => {
        const page = state.infos.template.pages[state.template.currentPage];
        if (type in page && page[type].widget != null) {
            return page[type].view_texts;
        }
        return {};
    },
};
