function zipLongest(placeholder = undefined, ...arrays) {
    const length = Math.max(...arrays.map(arr => arr.length));
    return Array.from(
    { length }, (value, index) => arrays.map(
        (array) => {
            if (array.length - 1 >= index) {
                return array[index];
            }
            return placeholder;
        },
    ));
}

function zip(...arrays) {
    const length = Math.min(...arrays.map(arr => arr.length));
    return Array.from(
    { length }, (value, index) => arrays.map(
        array => array[index],
    ));
}

module.exports = {
    zipLongest,
    zip,
};
