const _ = require('lodash');
const Messages = require('../../../../api/messages');
const EventType = require('../../../../enums/event_types');
const Utils = require('../../../../utils/utils');

module.exports = {
    data() {
        return {
            _mixin_debounced: null,
        };
    },
    methods: {
        _mixin_idle_detection() {
            this._mixin_debounced = _.debounce(this._mixin_go_back_home.bind(this),
                    this.variables.timeout);
            this._mixin_debounced();
            window.onload = this._mixin_debounced;
            window.onmousemove = this._mixin_debounced;
            // catches touchscreen presses
            window.onmousedown = this._mixin_debounced;
            // catches touchpad clicks
            window.onclick = this._mixin_debounced;
            // catches scrolling with arrow keys
            window.onscroll = this._mixin_debounced;
            window.onkeypress = this._mixin_debounced;
            window.onkeydown = this._mixin_debounced;
            window.ontouchstart = this._mixin_debounced;
            window.ontouchend = this._mixin_debounced;
            window.ontouchmove = this._mixin_debounced;
            window.ontouchleave = this._mixin_debounced;
        },
        _mixin_go_back_home() {
            // console.log('go home');
            const payload = {
                commit: this.$store.commit,
                eventType: EventType.ALL,
                event: this.events.home,
                eventOptions: Utils.resetInformation(),
            };

            this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
            this.$store.commit(Messages.RESET_GLOBAL_STATE);
        },
    },
    mounted() {
        this._mixin_idle_detection();
    },
    beforeDestroy() {
        if (this._mixin_debounced) {
            this._mixin_debounced.cancel();
        }
        window.onload = null;
        window.onmousemove = null;
        window.onmousedown = null;
        window.onclick = null;
        window.onscroll = null;
        window.onkeypress = null;
        window.onkeydown = null;
        window.ontouchstart = null;
        window.ontouchend = null;
        window.ontouchmove = null;
        window.ontouchleave = null;
    },
};
