const MasterWidget = require('../../../mixins/master_widget/MasterWidget');

module.exports = {
    mixins: [MasterWidget],
    computed: {
        headers() {
            const sep = this.variables.text_separator;
            const { headers = {} } = this.texts;
            const info = headers[this.lang] || '';
            return info.split(sep).filter(h => h !== '');
        },
        subheaders() {
            const sep = this.variables.text_separator;
            const { subheaders = {} } = this.texts;
            const info = subheaders[this.lang] || '';
            return info.split(sep).filter(h => h !== '');
        },
    },
};
