const Vuex = require('vuex');
const moment = require('moment');
const _ = require('lodash');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');
const SoundMixin = require('../../../mixins/sound/Sound');

module.exports = {
    mixins: [MasterWidget, SoundMixin],
    data() {
        return {
            state: {
            },
        };
    },
    methods: {
    },
    computed: _.merge({
        customers() {
            const last_called = this.last_called_customers.filter(c => (!c.processed && c.called));
            last_called.sort((a, b) => (+moment(b.ctimestamp)) - +moment(a.ctimestamp));
            return last_called.slice(0, this.variables.desks_number).reverse();
        },
        displayed_customers() {
            return this.customers.map(c => c.display);
        },
        desks() {
            return this.$store.state.infos.desks;
        },
        displayed_desks() {
            return _.reduce(this.desks, (obj, val, key) => {
                obj[key] = val.name;
                return obj;
            }, {});
        },
        last() {
            return this.$store.state.screen.last || '';
        },
    }, Vuex.mapGetters(['last_called_customers'])),
    watch: {
        customers(nc, oc) {
            if (nc.length < oc.length) {
                return; // End of processing was trigger
            }

            if (nc.length > oc.length) {
                this.play_sound();
                return;
            }

            const newS = new Set(nc.map(c => c._id));
            const equality = oc.every(c => newS.has(c._id));
            if (equality) {
                return;
            }

            this.play_sound();
        },
    },
    mounted() {
    },
};
