const Vue = require('vue');
const _ = require('lodash');
const moment = require('moment');
const momenttz = require('moment-timezone');
const Vuex = require('vuex');
const Messages = require('../../../../../api/messages');
const EventType = require('../../../../../enums/event_types');
const Phones = require('../../../../../../../app/modules/phones');
const StringUtils = require('../../../../../utils/strings');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');
const WithTimeout = require('../../../mixins/with_timeout/WithTimeout');

// TODO NEED TO BE REFACTORIZED URGENTLY USING Registration MIXIN

module.exports = {
    mixins: [MasterWidget, WithTimeout],
    data() {
        return {
            state: {
                showValidation: false,
                input: '', // this.augmented_input,
                placeholder: '&nbsp;',
                placeholder_to_complete: false,
            },
        };
    },
    methods: {
        is_input_valid(input) {
            const suborganization = this.$store.state.infos.suborganization;
            switch (this.itype) {
            case 'phone': {
                return input != null;
            }
            case 'cc':
                return input.length === 4 || input.length === 6;
            case 'name':
                return input.length > 0;
            case 'code0':
            case 'code1':
            case 'code2':
            case 'code3':
            case 'code4': {
                if (!(this.itype in suborganization.extra_codes)) {
                    return false;
                }
                const format_re = new RegExp(suborganization.extra_codes[this.itype].superformat);
                return format_re.test(input.trim());
            }
            default:
                return false;
            }
        },
        format_input(input) {
            input = input.trim();
            const suborganization = this.$store.state.infos.suborganization;

            switch (this.itype) {
            case 'phone': {
                const intl = Phones.format_phone_number(input.trim(),
                        suborganization.sms.default_code);
                return intl;
            }
            default:
                return input;
            }
        },
        keyboard_change(k, e) {
            e.preventDefault();
            switch (k) {
            case 'ENTER':
                this.validate({ preventDefault() {} });
                break;
            case 'BCKSPACE': {
                let new_input = this.state.input.slice(0, -1);
                if (new_input.length === 0) {
                    new_input = '';
                }
                this.state.input = new_input;
                this.state.showValidation = false;
                break;
            }
            default: {
                if (this.pad_component === 'date-pad') {
                    if (this.state.input.length === 2) {
                        this.state.input += '/';
                    } else if (this.state.input.length === 5) {
                        this.state.input += '/';
                    }
                }

                this.state.input += k;
                this.state.showValidation = false;
                break;
            }
            }
        },
        validate(e) {
            e.preventDefault();
            const input = this.format_input(this.state.input);
            if (!this.is_input_valid(input)) {
                this.state.showValidation = true;
                return;
            }
            this.state.showValidation = false;

            const view_infos = this.$store.state.template.view_infos;
            let extra_information = [];
            let registration = {};
            if ('registration' in view_infos) {
                registration = view_infos.registration;
                if ('extra_information' in registration) {
                    extra_information = registration.extra_information;
                }
            }

            extra_information.push({ name: this.itype, value: input });
            registration.extra_information = extra_information;
            this.emitEvent(this.variables.next_event, this.variables.next_event_value, { registration });
        },
        emitEvent(event, value = 'NA', opts = {}) {
            const payload = {
                commit: this.$store.commit,
                eventType: EventType.ALL,
                event,
                eventValue: value,
                eventOptions: opts,
            };

            this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
        },
        reset_input() {
            this.state.input = this.augmented_input;
            if (this.pad_component === 'date-pad') {
                this.state.placeholder = 'JJ/MM/YYYY';
                this.state.placeholder_to_complete = true;
            }
        },
    },
    watch: {
    },
    computed: _.merge({
        itype() {
            return this.variables.information_type;
        },
        lang() {
            return this.$store.state.interfaceLang;
        },
        pad_component() {
            const type = this.variables.information_type;

            const suborganization = this.$store.state.infos.suborganization;
            switch (type) {
            case 'meeting':
            case 'cc':
            case 'phone':
                return 'phone-pad';
            case 'email':
                return 'email-pad';
            case 'prm':
            case 'name':
                return 'alpha-pad';
            case 'vibrator':
                return '';
            case 'code0':
            case 'code1':
            case 'code2':
            case 'code3':
            case 'code4':
                return suborganization.extra_codes[type].keyboard;
            default:
                return '';
            }
        },
        pad_component_options() {
            const organization = this.$store.state.infos.organization;
            switch (this.pad_component) {
            default:
            case 'email-pad':
            case 'alpha-pad':
            case 'alpha-num-pad':
                return {
                    primaryColor: organization.colorone,
                    keyClasses: 'h4',
                    onKeyChange: this.keyboard_change,
                };
            case 'date-pad':
            case 'phone-pad':
                return {
                    primaryColor: organization.colorone,
                    keyClasses: 'h5',
                    onKeyChange: this.keyboard_change,
                };
            }
        },
        augmented_input() {
            const suborganization = this.$store.state.infos.suborganization;
            const type = this.variables.information_type;

            switch (type) {
            case 'meeting':
            case 'phone': {
                const prefix = suborganization.sms.prefix || '';
                return `${prefix}`;
            }
            case 'email': {
                const prefix = suborganization.email.prefix || '';
                return `${prefix}`;
            }
            default:
                return '';
            }
        },
    }),
    mounted() {
        this.reset_input();
        this.state.showValidation = false;
    },
    updated() {
    },
    beforeDestroy() {
    },
};
