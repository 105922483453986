require('babel-polyfill'); // For very very old browser

const Vue = require('vue');
const Buefy = require('buefy');
const router = require('./router');
const store = require('./store');
// const VueTouch = require('vue-touch');
const Vue2TouchEvents = require('vue2-touch-events');

const Loader = require('./components/loader/Loader.vue');
const CenteredBox = require('./components/centered_box/CenteredBox.vue');
const Key = require('./components/key/Key.vue');
const GenericPad = require('./components/pad/GenericPad.vue');
const AlphaPad = require('./components/pad/AlphaPad.vue');
const AlphaNumPad = require('./components/pad/AlphaNumPad.vue');
const EmailPad = require('./components/pad/EmailPad.vue');
const PhonePad = require('./components/pad/PhonePad.vue');
const DeskCustomer = require('./components/desk_customer/DeskCustomer.vue');
const Stepper = require('./components/stepper/Stepper.vue');
const Paginator = require('./components/paginator/Paginator.vue');

// Machine
// // Headers
const SliderHeader = require('./pages/widgets/headers/machine/slider_header/SliderHeader.vue');
const PrequalificationHeader = require('./pages/widgets/headers/machine/prequalification_header/PrequalificationHeader.vue');
const RegistrationHeader = require('./pages/widgets/headers/machine/registration_header/RegistrationHeader.vue');
const InformationHeader = require('./pages/widgets/headers/machine/information_header/InformationHeader.vue');

// // Middles
const Slider = require('./pages/widgets/middles/machine/slider/Slider.vue');
const Prequalifications = require('./pages/widgets/middles/machine/prequalifications/Prequalifications.vue');
const PrequalificationsMulti = require('./pages/widgets/middles/machine/prequalifications_multi/PrequalificationsMulti.vue');
const Registration = require('./pages/widgets/middles/machine/registration/Registration.vue');
const Information = require('./pages/widgets/middles/machine/information/Information.vue');
const InfoChooser = require('./pages/widgets/middles/machine/info_chooser/InfoChooser.vue');
const CustomerInformation = require('./pages/widgets/middles/machine/customer_information/CustomerInformation.vue');
const IFramePage = require('./pages/widgets/middles/machine/iframe/Iframe.vue');
const MeetingSelect = require('./pages/widgets/middles/machine/meeting/meeting_select/MeetingSelect.vue');
const MeetingDatetime = require('./pages/widgets/middles/machine/meeting/meeting_datetime/MeetingDatetime.vue');
const MeetingInformation = require('./pages/widgets/middles/machine/meeting/meeting_information/MeetingInformation.vue');
const MeetingReview = require('./pages/widgets/middles/machine/meeting/meeting_review/MeetingReview.vue');
const DesksChooser = require('./pages/widgets/middles/machine/desks/Desks.vue');
const YesNoQuestion = require('./pages/widgets/middles/machine/ynquestion/YesNoQuestion.vue');
const CustomersProvider = require('./pages/widgets/middles/machine/customers_provider/CustomersProvider.vue');
const LangSelector = require('./pages/widgets/middles/machine/lang_selector/LangSelector.vue');
const Landing = require('./pages/widgets/middles/machine/landing/Landing.vue');

// // Footers
const RegistrationFooter = require('./pages/widgets/footers/machine/registration_footer/RegistrationFooter.vue');
const PrequalificationFooter = require('./pages/widgets/footers/machine/prequalification_footer/PrequalificationFooter.vue');

// Screen
// // Headers
const ScreenHeader = require('./pages/widgets/headers/screen/screen_header/ScreenHeader.vue');
const DatetimeScreenHeader = require('./pages/widgets/headers/screen/datetime_screen_header/DatetimeScreenHeader.vue');
const LogoScreenHeader = require('./pages/widgets/headers/screen/logo_header/LogoHeader.vue');

// // Middles
const WaitingScreen = require('./pages/widgets/middles/screen/waiting_screen/WaitingScreen.vue');
const CallingScreen = require('./pages/widgets/middles/screen/calling_screen/CallingScreen.vue');
const RecentCallingScreen = require('./pages/widgets/middles/screen/recent_calling_screen/RecentCallingScreen.vue');
const SplitScreen = require('./pages/widgets/middles/screen/split_screen/SplitScreen.vue');

// // Footers
const SlidingScreen = require('./pages/widgets/footers/screen/sliding_footer/SlidingFooter.vue');
const StaticScreenFooter = require('./pages/widgets/footers/screen/static_footer/StaticFooter.vue');

// Desk
// // Headers

// // Middles
const BasicDesk = require('./pages/widgets/middles/desk/basic/Basic.vue');
const CustomerInformationDesk = require('./pages/widgets/middles/desk/customer_information/CustomerInformation.vue');
const QueueDesk = require('./pages/widgets/middles/desk/queue/Queue.vue');
const CloseDesk = require('./pages/widgets/middles/desk/close/Close.vue');
const VendorSelector = require('./pages/widgets/middles/desk/vendor_selector/VendorSelector.vue');

// // Footers
const ActionsDesk = require('./pages/widgets/footers/desk/action_desk/ActionDesk.vue');

const App = require('./pages/App.vue');

/* Vue.use(VueTouch, { name: 'v-touch' });
VueTouch.config.tap = {
    threshold: 500,
    interval: 1,
};*/

Vue.use(Buefy.default, {
    defaultIconPack: 'fa',
});

Vue.component('loader', Loader);
Vue.component('centered-box', CenteredBox);
Vue.component('key', Key);
Vue.component('generic-pad', GenericPad);
Vue.component('alpha-pad', AlphaPad);
Vue.component('alpha-num-pad', AlphaNumPad);
Vue.component('email-pad', EmailPad);
Vue.component('phone-pad', PhonePad);
Vue.component('date-pad', PhonePad);
Vue.component('desk-customer', DeskCustomer);
Vue.component('stepper', Stepper);
Vue.component('paginator', Paginator);

Vue.component('header-machine-slider', SliderHeader);
Vue.component('header-machine-prequalification', PrequalificationHeader);
Vue.component('header-machine-registration', RegistrationHeader);
Vue.component('header-machine-information', InformationHeader);

Vue.component('header-screen-header', ScreenHeader);
Vue.component('header-screen-datetime', DatetimeScreenHeader);
Vue.component('header-screen-logo', LogoScreenHeader);

Vue.component('middle-machine-slider', Slider);
Vue.component('middle-machine-prequalification', Prequalifications);
Vue.component('middle-machine-multiprequalifications', PrequalificationsMulti);
Vue.component('middle-machine-registration', Registration);
Vue.component('middle-machine-information', Information);
Vue.component('middle-machine-iframe', IFramePage);
Vue.component('middle-machine-info-chooser', InfoChooser);
Vue.component('middle-machine-customer-information', CustomerInformation);
Vue.component('middle-machine-meeting-select', MeetingSelect);
Vue.component('middle-machine-meeting-datetime', MeetingDatetime);
Vue.component('middle-machine-meeting-information', MeetingInformation);
Vue.component('middle-machine-meeting-review', MeetingReview);
Vue.component('middle-machine-desks-chooser', DesksChooser);
Vue.component('middle-machine-yesno-question', YesNoQuestion);
Vue.component('middle-machine-customers-provider', CustomersProvider);
Vue.component('middle-machine-lang-selector', LangSelector);
Vue.component('middle-machine-landing', Landing);

Vue.component('middle-screen-waiting', WaitingScreen);
Vue.component('middle-screen-calling', CallingScreen);
Vue.component('middle-screen-calling-recent', RecentCallingScreen);
Vue.component('middle-screen-split', SplitScreen);

Vue.component('middle-desk-basic', BasicDesk);
Vue.component('middle-desk-customer-information', CustomerInformationDesk);
Vue.component('middle-desk-queue', QueueDesk);
Vue.component('middle-desk-close', CloseDesk);
Vue.component('middle-desk-vendor-selector', VendorSelector);

Vue.component('footer-machine-registration', RegistrationFooter);
Vue.component('footer-machine-prequalification', PrequalificationFooter);
Vue.component('footer-desk-actions', ActionsDesk);

Vue.component('footer-screen-sliding', SlidingScreen);
Vue.component('footer-screen-static', StaticScreenFooter);

Vue.use(Vue2TouchEvents, {
    disableClick: true,
});

new Vue({
    el: '#app',
    store,
    router,
    render: h => h(App),
});
