const _ = require('lodash');
const Messages = require('../../../../../api/messages');
const EventType = require('../../../../../enums/event_types');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');
const WithTimeout = require('../../../mixins/with_timeout/WithTimeout');
const Utils = require('../../../../../utils/utils');

module.exports = {
    mixins: [MasterWidget, WithTimeout],
    data() {
        return {};
    },
    methods: {
        adapt_event(payload, type) {
            const event_name = this.variables[`event_${type}`] || '';
            const event_value = this.variables[`event_value_${type}`] || '';

            if (event_name.trim() !== '') {
                payload.event = event_name.trim();
            }
            if (event_value.trim() !== '') {
                payload.eventValue = event_value.trim();
            }

            return payload;
        },
        emitTap(type) {
            return e => this.emitEvent(type, e);
        },
        emitSwipe(type) {
            return (direction, e) => this.emitEvent(type, e);
        },
        emitEvent(type, e) { // TODO need to be factorized !!
            e.preventDefault();
            let payload = {
                eventType: EventType.ALL,
                eventValue: 'NA',
                eventOptions: {},
                commit: this.$store.commit,
            };

            switch (type) {
            default:
            case 'home': {
                payload.event = this.events.home;
                payload.eventOptions = Utils.resetInformation();
                break;
            }
            case 'vibrator': {
                payload.event = this.events.vibrator;
                payload.eventOptions = {
                    registration_type: type,
                    registration: {},
                };
                break;
            }
            case 'prequal': {
                payload.event = this.events.next;
                payload.eventOptions = Utils.resetInformation();
                break;
            }
            case 'meeting': {
                payload.event = this.events.meeting;
                payload.eventOptions = {
                    registration_type: type,
                    registration: {},
                };
                break;
            }
            case 'phone': {
                payload.event = this.events.next_prequal;
                payload.eventOptions = {
                    registration_type: type,
                    registration: {},
                };
                payload.eventValue = 'phone';
                break;
            }
            case 'name': {
                payload.event = this.events.name;
                payload.eventOptions = {
                    registration_type: type,
                    registration: {},
                };
                break;
            }
            case 'barcode': {
                payload.event = this.events.barcode;
                payload.eventOptions = {
                    registration_type: type,
                    registration: {},
                };
                break;
            }
            case 'paper': {
                payload.event = this.events.paper;
                payload.eventOptions = {
                    registration_type: type,
                    registration: {},
                };
                break;
            }
            case 'email': {
                payload.event = this.events.email;
                payload.eventOptions = {
                    registration_type: type,
                    registration: {},
                };
                break;
            }
            case 'code0':
            case 'code1':
            case 'code2':
            case 'code3':
            case 'code4': {
                payload.event = this.events[type];
                payload.eventOptions = {
                    registration_type: type,
                    registration: {},
                };
                break;
            }
            }

            payload = this.adapt_event(payload, type);
            return this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
        },
    },
    computed: {
        buttons() {
            const suborganization = this.$store.state.infos.suborganization;
            let extra = {
                vibrator: _.get(suborganization, 'vibrator.activated', false),
                email: _.get(suborganization, 'email.activated', false),
                phone: _.get(suborganization, 'sms.activated', false),
                paper: _.get(suborganization, 'printer.activated', false),
                name: _.get(suborganization, 'undigital', false),
            };
            extra = _.range(5).reduce((obj, i) => {
                if (`code${i}` in suborganization.extra_codes) {
                    obj[`code${i}`] = suborganization.extra_codes[`code${i}`].activated;
                }
                return obj;
            }, extra);

            let buttons = this.variables.buttons.split('+');
            buttons = buttons.filter(b => !(b in extra) || extra[b]);
            /* if (!suborganization.undigital) {
                buttons = buttons.filter(b => b !== 'name');
            }*/
            return buttons;
        },
        matrix() {
            const m = this.buttons.reduce(
                (rows, key, index) => (index % this.variables.buttons_per_line === 0 ? rows.push([key])
                    : rows[rows.length - 1].push(key)) && rows, []);

            return m;
        },
    },

    mounted() {
        if (this.buttons.length === 1) {
            this.emitEvent(this.buttons[0], { preventDefault: () => {} });
        }
    },
};
