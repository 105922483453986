const Vue = require('vue');
const _ = require('lodash');
const Messages = require('../../../../../api/messages');
const EventType = require('../../../../../enums/event_types');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');
const WithTimeout = require('../../../mixins/with_timeout/WithTimeout');

module.exports = {
    mixins: [MasterWidget, WithTimeout],
    data() {
        return {
            state: {
                selectedDesks: [],
                ALL_KEY: '___all___',
                current_page: 0,
                desksPerRow: 3,
            },
        };
    },
    methods: {
        change_page(page) {
            const validated_page = Math.max(0, Math.min(this.num_of_pages - 1, page));
            this.state.current_page = validated_page;
        },
        tap(key) {
            return e => this.clickDesk(key, e);
        },
        swipe(key) {
            return (dir, e) => this.clickDesk(key, e);
        },
        tap_page(page) {
            return () => this.change_page(page);
        },
        swipe_page(page) {
            return () => this.change_page(page);
        },
        clickDesk(key) {
            const opts = {};
            if (key !== this.state.ALL_KEY) {
                this.state.selectedDesks.push(key);
                opts.registration = {
                    forced_desks: {
                        enabled: true,
                        desks: this.state.selectedDesks,
                    },
                };
            }

            const payload = {
                eventType: EventType.ALL,
                event: this.variables.click_event,
                eventValue: 'NA',
                eventOptions: opts,
                commit: this.$store.commit,
            };
            this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
        },
        compute_size_of_desk_card(key) {
            const per_element = Math.floor(12 / this.state.desksPerRow);
            if (key === this.state.ALL_KEY) {
                return this.variables.all_desks_size || per_element;
            }
            return per_element;
        },
    },
    watch: {
    },
    computed: {
        num_of_pages() {
            return Math.ceil(this.deskKeys.length / this.variables.items_per_page);
        },
        current_items() {
            return this.deskKeys.slice(
                this.state.current_page * this.variables.items_per_page,
                (this.state.current_page + 1) * this.variables.items_per_page);
        },
        matrix() {
            return this.current_items
                .reduce((rows, key, index) => (index % this.state.desksPerRow === 0 ? rows.push([key])
                            : rows[rows.length - 1].push(key)) && rows, []);
        },
        lang() {
            return this.$store.state.interfaceLang;
        },
        deskKeys() {
            const prequalifications_path = this.viewInformation.prequalification_path || [];
            let prequalification = null;
            let desksOfPrequalification = new Set();
            if (prequalifications_path.length > 0) {
                prequalification = prequalifications_path[prequalifications_path.length - 1];
                prequalification = this.$store.state.infos.prequalifications[prequalification];
                desksOfPrequalification = new Set((prequalification.desks || []).map(d => `${d}`));
            }

            let desks = _.map(this.$store.state.infos.desks, desk => desk)
                .filter(desk => (!desk.autosink && desk.status.open));

            if (prequalification) {
                desks = desks.filter(desk => desksOfPrequalification.has(`${desk._id}`));
            }

            desks.sort((a, b) => a.name.localeCompare(b.name));
            if (this.conditions.all_is_incorporated_in_desks) {
                desks.unshift({ _id: this.state.ALL_KEY });
            }
            return desks.map(desk => desk._id);
        },
        viewInformation() {
            return this.$store.getters.view_infos;
        },
    },
    mounted() {
        this.state.desksPerRow = this.variables.desks_per_row || 4;
    },
};
