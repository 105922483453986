function getFirstBrowserLanguage() {
    const nav = window.navigator;
    const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];

    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
        return nav.languages.find(language => language && language.length);
    }

    // support for other well known properties in browsers
    return browserLanguagePropertyKeys.find((val) => {
        const language = nav[val];
        return language && language.length;
    });
}

function normalizeBrowserLanguage(lang) {
    if (lang == null || lang === '') {
        return null;
    }
    return lang.split('-')[0].toUpperCase();
}

function vibrate(pattern) {
    if (!navigator) {
        return;
    }

    navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;

    if (!navigator.vibrate) {
        return;
    }

    navigator.vibrate(pattern);
}

function ask_before_closing(msg = 'message') {
    window.addEventListener('beforeunload', (e) => {
        e = e || window.event;

        // For IE and Firefox
        if (e) {
            e.returnValue = msg;
        }

        // For Safari
        return msg;
    });

    window.onbeforeunload = function () {
        return msg;
    };
}

function localSet(name, value) {
    if (value instanceof Object) {
        localStorage.setItem(name, JSON.stringify(value));
    } else {
        localStorage.setItem(name, value);
    }
}

function localGet(name) {
    try {
        return JSON.parse(localStorage.getItem(name));
    } catch (err) {
        return localStorage.getItem(name);
    }
}

function localRemove(name) {
    localStorage.removeItem(name);
}

module.exports = {
    getFirstBrowserLanguage,
    normalizeBrowserLanguage,
    ask_before_closing,
    vibrate,
    localRemove,
    localGet,
    localSet,
};
