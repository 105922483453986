const _ = require('lodash');
const Utils = require('../../../../../utils/utils');
const Vuex = require('vuex');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');

module.exports = {
    mixins: [MasterWidget],
    data() {
        const path = this.$store.state.template.view_infos.prequalification_path;
        return {
            state: {
                prequalificationKey: path && path.length > 0 ?
                         path[path.length - 1] : null,
            },
        };
    },
    methods: {
        getPrequalification(prequalifications, key) {
            if (key == null) {
                key = Object.keys(prequalifications)
                    .find(k => prequalifications[k].root);
                if (key == null) {
                    const prequalifications_with_parents =
                        new Set(_.flatten(Object.keys(prequalifications)
                            .map(key => (prequalifications[key].prequalifications || []))));

                    key = Object.keys(prequalifications)
                    .find(key => !prequalifications_with_parents.has(key));
                }
            } else {
                key = prequalifications[key].prequalifications[0];
            }
            return prequalifications[key];
        },
    },
    watch: {
        depth() {
            const path = this.$store.state.template.view_infos.prequalification_path;
            this.state.prequalificationKey = path && path.length > 0 ?
                path[path.length - 1] : null;
        },
    },
    computed: _.merge({
        headers() {
            const prequalifications = this.$store.state.infos.prequalifications;
            const key = this.state.prequalificationKey;
            const prequalification = this.getPrequalification(prequalifications, key);
            const lang = this.$store.state.interfaceLang;
            if (prequalification.header && prequalification.header[lang] && prequalification.header[lang].trim() !== '') {
                return prequalification.header[lang].split('||').filter(c => c && c.trim() !== '');
            }
            return [];
        },
        subheaders() {
            const prequalifications = this.$store.state.infos.prequalifications;
            const key = this.state.prequalificationKey;
            const prequalification = this.getPrequalification(prequalifications, key);
            const lang = this.$store.state.interfaceLang;
            if (prequalification.subheader && prequalification.subheader[lang] && prequalification.subheader[lang].trim() !== '') {
                return prequalification.subheader[lang].split('||').filter(c => c && c.trim() !== '');
            }
            return [];
        },
        unreachable_prequalifications() {
            const { allow_close_suborganization, close } = 
                this.$store.state.infos.suborganization;
            const { prequalifications } = this.$store.state.infos;
            if (allow_close_suborganization && close) {
                return Utils.find_unreachable_prequalifications(
                    Object.keys(prequalifications), prequalifications
                );
            }
            return new Set();
        },
        no_reachable_prequalifications() {
            const { prequalifications } = this.$store.state.infos;
            return this.unreachable_prequalifications.size === _.size(prequalifications); 
        }
    },
    Vuex.mapGetters([
        'view_infos',
        'depth',
    ])),
};
