<template>
  <div class="container is-fluid registration container-top">
    <div class="columns">
      <div class="column is-paddingless is-marginless">
        <div v-for="item in headers">
          <h2 class="header h1-portrait" v-html="item" />
        </div>
        <div v-for="item in subheaders">
          <h2 class="subheader h1-portrait" v-html="item" />
        </div>
      </div>
      <div
        class="column is-paddingless is-marginless logo-container"
        v-if="variables.logo != null && variables.logo !== ''"
      >
        <img class="logo" :src="variables.logo" alt="Logo" title="Logo" />
      </div>
    </div>
  </div>
</template>

<script>
module.exports = require("./RegistrationHeader");
</script>
