<template>
<div class="fv-loader">
    <div class="fv-square fv-color-black" :style="css.secondary" />
    <div class="fv-square fv-color-black" :style="css.secondary" />
    <div class="fv-square fv-color-black fv-loader-last" :style="css.secondary" />
    <div class="fv-square fv-color-brand fv-loader-clear" :style="css.primary" />
    <div class="fv-square fv-color-brand" :style="css.primary" />
    <div class="fv-square fv-color-brand fv-loader-last" :style="css.primary" />
    <div class="fv-square fv-color-black fv-loader-clear" :style="css.secondary" />
    <div class="fv-square fv-color-black" :style="css.secondary" />
    <div class="fv-square fv-color-black fv-loader-last" :style="css.secondary" />
</div>
</template>

<script>
module.exports = require('./Loader');
</script>
