const _ = require('lodash');

module.exports = {
    mixins: [],
    props: {
        numberOfSteps: {
            type: Number,
            required: true,
        },
        checkIfCompleted: {
            default: true,
            type: Boolean,
        },
        currentStep: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            state: {
                current_step: this.currentStep,
            },
        };
    },
    methods: {
        next() {
            this.go(this.state.current_step + 1);
        },
        previous() {
            this.go(this.state.current_step - 1);
        },
        go(step, emit = true) {
            this.state.current_step = _.clamp(step, 0, this.numberOfSteps);
            if (emit) {
                this.$emit('step-change', {
                    step: this.state.current_step,
                    next_step: _.clamp(step + 1, 0, this.numberOfSteps),
                    prev_step: _.clamp(step - 1, 0, this.numberOfSteps),
                });
            }
        },
    },
    mounted() {
        this.go(this.state.current_step, true);
    },
};
