const MasterWidget = require('../../../mixins/master_widget/MasterWidget');
const WithTimeout = require('../../../mixins/with_timeout/WithTimeout');
const EventHub = require('../../../../../store/event_hub');
const EventType = require('../../../../../enums/event_types');
const Messages = require('../../../../../api/messages');


module.exports = {
    mixins: [MasterWidget, WithTimeout],
    data() {
        return {};
    },
    methods: {
        click_on_screen(e) {
            e.preventDefault();
            const payload = {
                commit: this.$store.commit,
                eventType: EventType.ALL,
                event: this.events.landing,
            };

            this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
        },
    },
    mounted() {
        EventHub.$on('meta-click-on-screen', (e) => {
            this.click_on_screen(e);
        });
    },
    computed: {
    },
    beforeDestroy() {
        EventHub.$off('meta-click-on-screen');
    }
};
