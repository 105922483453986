const Vuex = require('vuex');
const _ = require('lodash');
const EventType = require('../../../../../enums/event_types');
const Messages = require('../../../../../api/messages');
const APIRoutes = require('../../../../../api/routes');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');

module.exports = {
    mixins: [MasterWidget],
    data() {
        return {
            state: {
                see_prequalifications: true,
                see_desks: true,
                close_on: false,
                pause_on: false,
                close_suborganization_on: false,
                redirect_on: false,
            },
        };
    },
    methods: {
        generate_selected_prequalifications(pqs, selected) {
            if (!pqs) {
                return {};
            }

            return Object.keys(pqs).reduce((obj, id) => {
                if (!(id in obj)) {
                    obj[id] = false;
                }
                return obj;
            }, selected);
        },
        generate_selected_desks(dids, selected) {
            if (!dids) {
                return {};
            }

            return dids.reduce((obj, device) => {
                if (!(device._id in obj)) {
                    obj[device._id] = false;
                }
                return obj;
            }, selected);
        },
        toggle_selection(pid, global, type = 'prequalification') {
            const all = type === 'prequalification' ?
                this.all_selected_prequalifications() : this.all_selected_desks();
            const objs = type === 'prequalification' ?
                this.last_level_prequalifications : this.redirectable_desks;
            const instate = type === 'prequalification' ?
                'selected_prequalifications' : 'selected_desks';
            const func = type === 'prequalification' ?
                this.generate_selected_prequalifications : this.generate_selected_desks;

            if (global) {
                if (all) {
                    this.$set(this.state, instate, func(objs || {}, {}));
                } else if (type === 'prequalification') {
                    _.forEach(objs, (val, key) => {
                        this.$set(this.state[instate], key, true);
                    });
                } else {
                    objs.forEach((device) => {
                        this.$set(this.state[instate], device._id, true);
                    });
                }
            } else {
                this.$set(this.state[instate], pid, !this.state[instate][pid]);
            }
            this.$forceUpdate(); // Force update...
        },
        back(e) {
            e.preventDefault();
            this.emitEvent(this.events.home);
        },
        emitEvent(event, value = 'NA', opts = {}) {
            const payload = {
                commit: this.$store.commit,
                eventType: EventType.ALL,
                eventValue: value,
                event,
                eventOptions: opts,
            };

            this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
        },
        close(e, redirect = false) {
            if (this.state.close_suborganization_on) {
                this._close_suborganization(e);
            } else if (this.state.pause_on) {
                this._close_device(e, redirect, true);
            } else {
                this._close_device(e, redirect, false);
            }
        },
        _close_suborganization(e) {
            const suborganization = this.$store.state.infos.suborganization;

            const body = {
                suborganization: suborganization._id,
                close: !suborganization.close,
            };

            this.$store.dispatch('call_api', {
                path: APIRoutes.suborganization_close,
                method: 'POST',
                body,
            });
            this.back(e);
        },
        _close_device(e, redirect = false, pause = false) {
            const device = this.$store.state.infos.device;

            const body = {
                _id: device._id,
                status: {
                    open: false,
                    pause,
                },
            };

            if (redirect) {
                body.redirect = {
                    desks: _.reduce(this.state.selected_desks, (arr, val, key) => {
                        if (val) {
                            arr.push(key);
                        }
                        return arr;
                    }, []),
                    prequalifications: _.reduce(this.state.selected_prequalifications,
                        (arr, val, key) => {
                            if (val) {
                                arr.push(key);
                            }
                            return arr;
                        }, []),
                };
            }

            this.$store.dispatch('call_api', {
                path: APIRoutes.desk_close,
                method: 'PUT',
                body,
            });
            this.back(e);
        },
        all_selected_prequalifications() {
            return Object.keys(this.last_level_prequalifications).length
                === _.filter(this.state.selected_prequalifications, v => v).length;
        },
        all_selected_desks() {
            return this.redirectable_desks.length
                === _.filter(this.state.selected_desks, v => v).length;
        },
    },
    watch: {
        last_level_prequalifications(pqs) {
            this.state.selected_prequalifications =
                this.generate_selected_prequalifications(pqs,
                    this.state.selected_prequalifications);
        },
        redirectable_desks(devices) {
            this.state.selected_desks =
                this.generate_selected_desks(devices, this.state.selected_desks);
        },
    },
    computed: _.merge({
        actions() {
            const actions = [
                {
                    render: true,
                    text: this.texts.yes[this.lang],
                    icon: 'fa fa-check',
                    click: this.close,
                },
                {
                    render: true,
                    text: this.texts.no[this.lang],
                    icon: 'fa fa-times',
                    click: this.back,
                },
            ];

            return actions;
        },
        close_actions() {
            const suborg = this.$store.state.infos.suborganization;
            const allow_close_suborg = suborg.allow_close_suborganization && suborg.allow_desks_close_suborganization;
            const allow_pause_desk = suborg.allow_pause_desk && suborg.allow_pause_desk;
            let close_suborg_text = '';
            if (allow_close_suborg) {
                close_suborg_text = suborg.close ?
                    this.texts.open_suborganization[this.lang] :
                    this.texts.close_suborganization[this.lang];
            }

            const actions = [
                {
                    render: allow_close_suborg,
                    text: close_suborg_text,
                    icon: 'fa fa-clock-o',
                    click: () => {
                        this.state.close_suborganization_on
                            = !this.state.close_suborganization_on;
                    },
                },
                {
                    render: this.can_redirect,
                    text: this.can_redirect ? this.texts.redirections[this.lang] : '',
                    icon: 'fa fa-check',
                    click: () => { this.state.redirect_on = !this.state.redirect_on; },
                },
                {
                    render: allow_pause_desk,
                    text: this.texts.pause_closing ? this.texts.pause_closing[this.lang] : '',
                    icon: 'fa fa-clock-o',
                    click: () => { this.state.pause_on = !this.state.pause_on; },
                },
                {
                    render: true,
                    text: this.texts.standard_closing ? this.texts.standard_closing[this.lang] : '',
                    icon: 'fa fa-times',
                    click: () => { this.state.close_on = !this.state.close_on; },
                },
            ];

            return actions;
        },
        redirect_actions() {
            const actions = [
                {
                    render: true,
                    text: this.texts.yes[this.lang],
                    icon: 'fa fa-check',
                    click: e => this.close(e, true),
                },
                {
                    render: true,
                    text: this.texts.no[this.lang],
                    icon: 'fa fa-times',
                    click: this.back,
                },
            ];

            return actions;
        },
        last_level_prequalifications() {
            return _.reduce(this.$store.state.infos.prequalifications || [], (obj, value, key) => {
                if (value.prequalifications && value.prequalifications.length > 0) {
                    return obj;
                }
                obj[key] = value;
                return obj;
            }, {});
        },
        redirectable_desks() {
            if (!this.$store.state.content || this.$store.state.content.length === 0) {
                return [];
            }

            const info = this.$store.state.content[0];
            return (info.opened_desks || []).filter((x) => !x.inactive);
        },
        can_redirect() {
            if (!this.$store.state.infos.suborganization.redirect_desks) {
                return false;
            }
            if (!this.$store.state.content || this.$store.state.content.length === 0) {
                return false;
            }

            const info = this.$store.state.content[0];
            return info.can_redirect;
        },
        redirect_ready() {
            if (!this.can_redirect) {
                return false;
            }

            return this.can_redirect
                && Object.keys(this.state.selected_prequalifications).length > 0
                && Object.keys(this.state.selected_desks).length > 0;
        },
        has_multiple_actions() {
            return this.close_actions.filter(a => a.render).length > 1;
        },
        yes_no_actions_on() {
            return this.state.close_on || this.state.close_suborganization_on || this.state.pause_on;
        },
        nothing_is_on() {
            return !this.yes_no_actions && !this.state.redirect_on;
        },
        has_only_standard_closing() {
            return !this.has_multiple_actions && !this.can_redirect;
        },
    }, Vuex.mapGetters([])),
    beforeMount() {
        this.$store.dispatch('call_api', {
            path: APIRoutes.redirection_information(this.$store.state.infos.suborganization._id,
                    this.$store.state.infos.device._id),
            method: 'GET',
        });
        this.$set(this.state, 'selected_prequalifications',
            this.generate_selected_prequalifications(this.last_level_prequalifications || {}, {}));
        this.$set(this.state, 'selected_desks',
            this.generate_selected_desks(this.redirectable_desks || [], {}));
    },
    mounted() {
    },
};
