<template>
    <div class="has-small-margins">
        <div class="columns is-centered" v-if="variables.hoursPerPage < computedCards.length">
            <paginator :items-per-page="variables.hoursPerPage" :number-of-items="computedCards.length" :use-arrows="true" @page-change="pageChanged" />
        </div>
        <div :class="['columns is-centered']" v-for="(row, idx) in matrixComputedCards">
            <div :class="`is-unselectable column is-${12/variables.hoursPerRow} has-text-centered`" 
                v-for="key in row"
            >
                <div
                    :class="['card']"
                    :key="key.text"
                    @click.stop="(e) => {e.preventDefault(); selectHour(e, key)}"
                    v-touch:tap="(e) => {e.preventDefault(); selectHour(e, key)}"
                    v-touch:swipe="(dir, e) => {e.preventDefault(); selectHour(e, key)}"
                >
                    <h4 class="h3-portrait" v-html="key.text" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    module.exports = require('./HoursSelection');
</script>
