<template>
<div class="container" v-if="!inactive_device">
    <div class="columns is-mobile is-centered">
        <div class="column" v-for="info in actions">
            <div class="notification fv-notification-padding is-dark has-text-centered" @click="info.click">
                <p class="icon">
                    <i :class="info.icon" />
                </p>
                <p>{{info.text}}</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    module.exports = require('./ActionDesk');
</script>
