<template>
<div class="container is-fluid customers-provider">
    <template v-if="myStep === 'selection_1'">
        <div class="columns is-centered">
            <div v-if="$text('hoursTitle', lang)" class="column is-10 has-text-centered">
                <h3 v-html="$text('hoursTitle', lang)"></h3>
            </div>
        </div>
        <hours-selection type='middle' @select-hour="select"/> 
    </template>
    <template v-else-if="myStep === 'selection_2'">
        <template v-if="loading">
            <div class="columns">
                <div class="column is-10 is-offset-1">
                    <loader primary-color="$store.state.infos.organization.colorone" />
                </div>
            </div>
        </template>
        <template v-else>
            <template v-if="customers.length === 0">
            <div class="columns is-centered">
                <div class="column is-10 has-text-centered has-bottom-margin">
                    <h3 v-html="$text('noResults')"/>
                </div>
            </div>
            <div class="columns is-mobile is-centered">
                <div class="column is-centered is-4" v-if="conditions.hoursSelectionEnabled">
                    <div
                        :class="['card-button is-unselectable']"
                        @click.stop="(e) => {e.preventDefault(); state.step = 'selection_1'}"
                        v-touch:swipe="(dir, e) => {e.preventDefault(); state.step = 'selection_1'}"
                        v-touch:tap="(e) => {e.preventDefault(); state.step = 'selection_1'}"
                    >
                        <h5 class="has-text-centered" v-html="$text('otherHours', lang)"></h5>
                    </div>
                </div>
                <div :class="['column is-centered is-4', {'is-offset-2': conditions.hoursSelectionEnabled}]" 
                    v-if="conditions.fallbackEnabled">
                    <div
                        :class="['card-button is-unselectable']"
                        @click.stop="(e) => {e.preventDefault(); emitEvent(variables.fallbackEvent || 'fallback')}"
                        v-touch:swipe="(dir, e) => {e.preventDefault(); emitEvent(variables.fallbackEvent || 'fallback')}"
                        v-touch:tap="(e) => {e.preventDefault(); emitEvent(variables.fallbackEvent || 'fallback')}"
                    >
                        <h5 class="has-text-centered" v-html="$text('fallback', lang)"></h5>
                    </div>
                </div>
            </div>
            </template>
            <div class="has-small-margins" v-else>
                <div class="columns is-centered">
                    <div v-if="$text('customersTitle', lang)" class="column is-10 has-text-centered">
                        <h3 v-html="$text('customersTitle', lang)"></h3>
                    </div>
                </div>
                <div :class="['columns is-mobile is-centered is-marginless-t']" v-for="(row, idx) in matrixCustomers" :key="idx">
                    <div :class="['is-unselectable', 'is-paddingless-tb', 
                        'column', `is-${12/variables.customersPerRow}`, 
                        'has-text-centered']"
                        :key="customer._id"
                        v-for="customer in row"
                    >
                        <customer :mode="variables.mode || 'meeting'" :customer="customer" type="middle" @select-customer="selectCustomer" />
                    </div>
                </div>
                <div class="columns is-mobile is-centered is-marginless-tb">
                    <div class="column is-4 is-paddingless-tb" v-if="variables.customersPerPage < customers.length">
                        <div class="is-vertically-centered" >
                            <paginator 
                                :items-per-page="parseInt(variables.customersPerPage, 10)" 
                                :number-of-items="customers.length" 
                                :use-arrows="true" 
                                @page-change="customersPageChanged" />
                        </div>
                    </div>
                    <div class="column is-centered is-4 is-paddingless-tb" v-if="conditions.hoursSelectionEnabled">
                        <div
                            :class="['card-button is-unselectable']"
                            @click.stop="(e) => {e.preventDefault(); state.step = 'selection_1'}"
                            v-touch:swipe="(dir, e) => {e.preventDefault(); state.step = 'selection_1'}"
                            v-touch:tap="(e) => {e.preventDefault(); state.step = 'selection_1'}"
                        >
                            <h5 class="has-text-centered" v-html="$text('otherHours', lang)"></h5>
                        </div>
                    </div>
                    <div :class="['column is-centered is-4 is-paddingless-tb']" 
                        v-if="conditions.fallbackEnabled">
                        <div
                            :class="['card-button is-unselectable']"
                            @click.stop="(e) => {e.preventDefault(); emitEvent(variables.fallbackEvent || 'fallback')}"
                            v-touch:swipe="(dir, e) => {e.preventDefault(); emitEvent(variables.fallbackEvent || 'fallback')}"
                            v-touch:tap="(e) => {e.preventDefault(); emitEvent(variables.fallbackEvent || 'fallback')}"
                        >
                            <h5 class="has-text-centered" v-html="$text('fallback', lang)"></h5>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </template>
</div>
</template>

<script>
    module.exports = require('./CustomersProvider');
</script>
