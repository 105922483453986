<template>
<div class="container is-fluid info-chooser">
    <div class="columns is-centered">
        <div class="column is-10 is-landscape">
            <h3 class="has-text-centered">{{texts.header[lang]}}</h3> 
        </div>
        <div class="column is-12 is-portrait">
            <h3 class="has-text-centered">{{texts.header[lang]}}</h3> 
        </div>
    </div>
    <div class="columns is-mobile is-centered" v-for="(row, index) in matrix">
        <div :class="`column is-landscape has-text-centered ${row.length === 1 ? 'is-6' : ''}`" v-for="btn in row">
            <div
                class="card is-unselectable"
                @click="emitEvent(btn, $event)"
                v-touch:tap="emitTap(btn)"
                v-touch:swipe="emitSwipe(btn)"
            >
            <component :is="`h${index+2}`" v-if="conditions.progressive_size" class="has-text-centered" v-html="texts[btn][lang]"></component>
            <h3 v-else class="has-text-centered" v-html="texts[btn][lang]"></h3>
            </div>
        </div>
        <div :class="`column is-portrait has-text-centered ${row.length === 1 ? 'is-8' : ''}`" v-for="btn in row">
            <div
                class="card is-unselectable"
                v-touch:swipe="emitSwipe(btn)"
                v-touch:tap="emitTap(btn)"
            >
            <component :is="`h${index+2}`" v-if="conditions.progressive_size" class="has-text-centered" v-html="texts[btn][lang]"></component>
            <h3 v-else class="has-text-centered" v-html="texts[btn][lang]"></h3>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    module.exports = require('./InfoChooser');
</script>
