const MasterWidget = require('../../../mixins/master_widget/MasterWidget');
const WithTimeout = require('../../../mixins/with_timeout/WithTimeout');
const Registration = require('../../../mixins/registration/Registration');

module.exports = {
    mixins: [MasterWidget, WithTimeout, Registration],
    data() {
        return {};
    },
    mounted() {
        if (this.conditions.auto_registration) {
            const rtype = 'name';
            const suborganization = this.$store.state.infos.suborganization;
            const ret = this.register_customer(rtype, 'INFORMATION', suborganization, this.lang, 'Mr');
        }
    },
    computed: {
        steps() {
            const sep = this.variables.text_separator;
            const steps = this.texts.steps[this.lang].split(sep)
                .filter(s => s.trim() !== '');
            return steps;
        },
    },
};
