<template>
    <div class="columns is-mobile">
        <div class="column has-text-centered" 
             v-if="state.customActionsEnabled && state.hasCurrentCustomer">
            <template v-if="!state.showCancel">
                <a
                    class="button is-large is-outlined"
                    @click.prevent="onEndCustomer(variables.custom_action_1_id, variables.custom_action_1_value)"
                    v-if="state.renderedAction === 'END' || state.endCustomAction"
                    v-html="$text('custom_action_1_name', lang)"
                    :disabled="!state.buttonsActivated"
                    ></a>
                <a
                    class="button is-large is-outlined"
                    @click.prevent="onNextCustomer(variables.custom_action_1_id, variables.custom_action_1_value)"
                    v-else
                    :disabled="!state.hasNextCustomer || !state.buttonsActivated"
                    v-html="$text('custom_action_1_name', lang)"
                    ></a>
            </template>
            <template v-else>
                <a
                    class="button is-large is-outlined"
                    @click.prevent="onCancelAction"
                >
                    {{$text('cancel_action', lang)}} ({{state.countDown}})
                </a>
            </template>
        </div>
        <div class="column has-text-centered">
            <template v-if="!state.showCancel">
                <a
                    class="button is-large is-outlined"
                    @click.prevent="onSelectVendor"
                    v-if="state.allowVendorsSelection && state.renderedAction !== 'END'"
                    >{{$text('select_vendor', lang)}}
                </a>
                <a
                    class="button is-large is-outlined"
                    @click.prevent="() => onEndCustomer()"
                    v-if="state.renderedAction === 'END'"
                    :disabled="!state.buttonsActivated"
                    >{{$text('end_of_calling', lang)}}</a>
                <a
                    class="button is-large is-outlined"
                    @click.prevent="() => onNextCustomer()"
                    v-else-if="showNextButton"
                    :disabled="!state.hasNextCustomer || !state.buttonsActivated"
                >
                    {{$text('next', lang)}}
                </a>
            </template>
            <template v-else>
                <a
                    class="button is-large is-outlined"
                    @click.prevent="onCancelAction"
                    >
                        {{$text('cancel_action', lang)}} ({{state.countDown}})
                </a>
            </template>
        </div>
    </div>
</template>

<script>
    module.exports = require('./Buttons');
</script>
