<template>
<div class="hero queue-template">
    <div class="hero-body">
        <div class="container">
            <div class="columns is-centered">
                <div class="column is-4">
                    <div class="card">
                      <header class="card-header">
                        <p class="card-header-title">
                            En attente 
                        </p>
                      </header>
                      <div class="card-content">
                        <div class="content">
                        </div>
                      </div>
                    </div>
                </div>
                <div class="column is-4">
                    <div class="card">
                      <header class="card-header">
                        <p class="card-header-title">
                            En cours 
                        </p>
                      </header>
                      <div class="card-content">
                        <div class="content">
                        </div>
                      </div>
                    </div>
                </div>
                <div class="column is-4">
                    <div class="card">
                      <header class="card-header">
                        <p class="card-header-title">
                            Traités 
                        </p>
                      </header>
                      <div class="card-content">
                        <div class="content">
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="columns is-centered">
                <div class="column is-12">
                    <div class="card">
                      <header class="card-header">
                        <p class="card-header-title">
                            Vue détaillée 
                        </p>
                      </header>
                      <div class="card-content">
                          <div class="content">
                              <div class="columns is-centered">
                                    <div class="column is-12">
                                        <div class="tabs is-centered is-toggle">
                                            <ul>
                                                <li class="is-active"><a>Clients en attente</a></li>
                                                <li><a>Clients en cours de traitement</a></li>
                                                <li><a>Clients traités</a></li>
                                            </ul>
                                        </div>
                                    </div>
                              </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
module.exports = require('./Queue');
</script>
