const _ = require('lodash');
const Messages = require('../../../../../api/messages');
const EventType = require('../../../../../enums/event_types');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');

module.exports = {
    mixins: [MasterWidget],
    data() {
        return {};
    },
    methods: {
        tap(type) {
            return e => this.emitEvent(type, e);
        },
        swipe(type) {
            return (dir, e) => this.emitEvent(type, e);
        },
        emitEvent(type, e) {
            e.preventDefault();
            try {
                e.stopPropagation();
            } catch (err) {

            }
            const payload = {
                eventType: EventType.ALL,
                eventValue: 'NA',
                eventOptions: {},
                commit: this.$store.commit,
            };

            switch (type) {
            default:
            case 'home': {
                payload.event = this.events.home;
                payload.eventOptions = {
                    prequalification_path: [],
                    registration: undefined,
                    registration_type: undefined,
                    depth: 0,
                };
                this.$store.commit(Messages.RESET_GLOBAL_STATE);
                return this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
            }
            case 'info_chooser': {
                payload.eventType = EventType.PREQUAL;
                payload.event = this.events.info_chooser;
                return this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
            }
            case 'vibrator': {
                payload.event = this.events.vibrator;
                payload.eventOptions = {
                    registration_type: type,
                };
                return this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
            }
            case 'prequal': {
                payload.event = this.events.next;
                payload.eventOptions = {
                    prequalification_path: [],
                    registration: undefined,
                    registration_type: undefined,
                    depth: 0,
                };
                this.$store.commit(Messages.RESET_GLOBAL_STATE);
                return this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
            }
            case 'name': {
                payload.event = this.events.name;
                payload.eventOptions = {
                    registration_type: type,
                };
                return this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
            }
            case 'barcode': {
                payload.event = this.events.barcode;
                payload.eventOptions = {
                    registration_type: type,
                };
                return this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
            }
            case 'paper': {
                payload.event = this.events.paper;
                payload.eventOptions = {
                    registration_type: type,
                };
                return this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
            }
            case 'email': {
                payload.event = this.events[type];
                payload.eventOptions = {
                    registration_type: type,
                };
                return this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
            }
            case 'code0':
            case 'code1':
            case 'code2':
            case 'code3':
            case 'code4': {
                payload.event = this.events[type];
                payload.eventOptions = {
                    registration_type: type,
                };
                return this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
            }
            case 'phone': {
                payload.event = this.events.next_prequal;
                payload.eventOptions = {
                    registration_type: type,
                    registration: {},
                };
                payload.eventValue = 'phone';
                return this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
            }
            }
        },
    },
    computed: {
        buttonType() {
            const t = this.variables.button_type;
            let status = 'default';
            const view_infos = this.$store.state.template.view_infos;
            if ('registration' in view_infos && 'status' in view_infos.registration) {
                status = view_infos.registration.status;
            }

            if (status === 'registered' || status === 'error' || status === 'no_meeting') {
                return 'home';
            }
            return t;
        },
        buttons() {
            const suborganization = this.$store.state.infos.suborganization;
            let extra = {
                vibrator: suborganization.vibrator.activated,
                email: suborganization.email.activated,
                phone: suborganization.sms.activated,
            };
            extra = _.range(5).reduce((obj, i) => {
                if (`code${i}` in suborganization.extra_codes) {
                    obj[`code${i}`] = suborganization.extra_codes[`code${i}`].activated;
                }
                return obj;
            }, extra);

            let buttons = this.buttonType.split('+');
            buttons = buttons.filter(b => !(b in extra) || extra[b]);
            if(!suborganization.undigital) {
                buttons = buttons.filter(b => b !== 'name'); 
            }
            return buttons;
        },
    },
};
