<template>
<div class="container is-fluid prequalification container-middle is-portrait-vertical" v-if="!is_suborganization_close || !no_prequalifications_available">
    <div v-if="orientation.length > 0" class="columns is-centered orientation">
        <div class="column is-10 has-text-centered has-bottom-margin">
            <h3 v-html="orientation"></h3>
        </div>
    </div>
    <div class="columns is-centered" v-for="row in matrix">
        <div class="is-unselectable column is-5 has-text-centered" v-for="key in row">
            <div
                v-if="$store.state.infos.prequalifications[key].prm"
                :class="['card is-prm', {'card-disabled': unreachable_prequalifications.has(key)}, `prequalification-${key}`]"
                @click="clickPrequalification(key, $event)"
                :key="key"
                v-touch:tap="tap(key)"
                v-touch:swipe="swipe(key)"
            >
                <h4 class="h3-portrait" v-if="unreachable_prequalifications.has(key)" v-html="$text('suborganization_closed_prequalification')"/>
                <template v-else>
                    <h4 class="h3-portrait" v-html="$store.state.infos.prequalifications[key].html_name[lang]" />
                    <div class="is-marginless">
                        <img alt="PRM icons" :src="prm_icons" class="prm-icons responsive-img" width="100px" />
                    </div>
                </template>
            </div>
            <div
                v-else
                :class="['card', {'card-disabled': unreachable_prequalifications.has(key)}, `prequalification-${key}`]"
                :key="key"
                @click="clickPrequalification(key, $event)"
                v-touch:tap="tap(key)"
                v-touch:swipe="swipe(key)"
            >
                <h4 class="h3-portrait" v-if="unreachable_prequalifications.has(key)" v-html="$text('suborganization_closed_prequalification')"/>
                <h4 class="h3-portrait" v-else v-html="$store.state.infos.prequalifications[key].html_name[lang]" />
            </div>
        </div>
    </div>
    <div v-if="conditions.show_global_waiting_time" class="global-waiting-time-container">
        <p>{{$text('global_waiting_time')}} <span class="global-waiting-time">{{global_waiting_time}}</span></p> 
    </div>
</div>
<div v-else class="container is-fluid prequalification container-middle is-portrait-vertical">
    <div class="columns is-centered">
        <div class="column is-10 has-text-centered has-bottom-margin">
            <!--<h3 v-html="orientation"></h3>-->
            <h3 v-html="$text('suborganization_closed')"/>
        </div>
    </div>
</div>
</template>

<script>
    module.exports = require('./Prequalifications');
</script>
