function check_customer_against_desk(infos, customer) {
    if (customer.prequalification in infos.prequalifications
        && (!customer.forced_desks ||
            !customer.forced_desks.enabled ||
            customer.forced_desks.desks.indexOf(infos.device._id) !== -1)) {
        return true;
    }

    return false;
}

function check_customer_against_screen(infos, customer) {
    if (customer.prequalification in infos.prequalifications) {
        return true;
    }
    return false;
}

function am_i_the_right_device_for_this_customer(infos, customer) {
    switch (infos.device.type) {
    case 'machine':
        return true;
    case 'screen':
        return check_customer_against_screen(infos, customer);
    case 'desk':
        return check_customer_against_desk(infos, customer);
    default:
        return false;
    }
}

module.exports = {
    am_i_the_right_device_for_this_customer,
};
