const Vuex = require('vuex');
const moment = require('moment');
const momenttz = require('moment-timezone');
const _ = require('lodash');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');
const SoundMixin = require('../../../mixins/sound/Sound');

// Workaround with browserify to include all locales of moment
require('moment/min/locales.min');

module.exports = {
    mixins: [MasterWidget, SoundMixin],
    data() {
        return {
            state: {
                datetime: momenttz.tz(this.$store.state.infos.suborganization.tz),
                interval: null,
            },
        };
    },
    methods: {
        update_datetime() {
            this.state.datetime = momenttz.tz(this.$store.state.infos.suborganization.tz);
        },
    },
    computed: _.merge({
        desks() {
            const prequalifications = this.$store.state.infos.prequalifications;
            const desks = this.$store.state.infos.desks;
            const desks_set = _.reduce(prequalifications,
                (set, pr) => new Set([...pr.desks, ...set]), new Set([]));
            return Array.from(desks_set).map(did => desks[did]).sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });
        },
        customers() {
            const last_called = this.last_called_customers;
            return last_called.reduce((obj, customer) => {
                if (!customer.processed) {
                    obj[customer.desk] = customer;
                }
                return obj;
            }, {});
        },
        displayed_customers() {
            return this.desks.reduce((obj, d) => {
                obj[d._id] = d._id in this.customers ? this.customers[d._id].display : this.texts.question_mark[this.lang];
                return obj;
            }, {});
        },
        last() {
            return this.$store.state.screen.last || '';
        },
    }, Vuex.mapGetters(['last_called_customers'])),
    watch: {
        last(l) {
            if (l && l.trim() !== '') {
                this.play_sound();
            }
        },
    },
    beforeCreate() {
        moment.locale(this.$store.state.interfaceLang.toLowerCase());
    },
    beforeDestroy() {
        if (this.state.interval) {
            clearInterval(this.state.interval);
        }
    },
    mounted() {
        this.state.interval = setInterval(this.update_datetime.bind(this),
                this.variables.refresh_datetime_interval);
    },
};
