module.exports = {
    LOADING: 'loading',
    SUCCESS: 'success',
    FAILURE: 'failure',
    FETCH: 'fetch',
    ERROR: 'error',
    CONNECT_SOCKET: 'connect_socket',
    SOCKET_EVENT: 'socket_event',
    SETUP_TEMPLATE: 'setup_template',
    UPDATE_TEMPLATE: 'update_template',
    RESET_GLOBAL_STATE: 'reset_global_state',
    UPDATE_TEMPLATE_TEXT: 'update_template_text',
    UPDATE_TEMPLATE_VIEW: 'update_template_view',
};
