const _ = require('lodash');
const API = require('../api');
const AuthUtils = require('../utils/auth');
const BrowserUtils = require('../utils/browser');
const APIRoutes = require('../api/routes');
const Messages = require('../api/messages');
const StringUtils = require('../utils/strings');
const SocketDispatcher = require('../utils/socket_dispatcher');

module.exports = {
    recover_screen: async (ctx) => {
        const type = ctx.state.infos.device.type;
        const mac = ctx.state.infos.device.mac;
        const key = ctx.state.infos.device.auth.token;
        const path = StringUtils.format_with_obj(APIRoutes.device_information, {
            type,
            mac,
        });
        const method = 'GET';

        const payload = {
            path,
            method,
            commit: ctx.commit,
            signature: AuthUtils.get_api_headers(key, method, path),
        };

        const response = await API.fetch(payload);

        if (response.type !== Messages.SUCCESS) {
            return ctx.commit(Messages.ERROR, {
                error_type: 'unknown',
            });
        }

        if (Object.keys(response.content).length === 0) {
            return ctx.commit(Messages.ERROR, {
                error_type: 'unrecognized-device',
            });
        }

        ctx.commit(Messages.ERROR, {
            error_type: '',
        });

        ctx.state.infos.waiting_customers = response.content.waiting_customers;
        ctx.state.infos.meeting_customers = response.content.meeting_customers;
        ctx.state.infos.last_called_customers =
      response.content.last_called_customers;
        ctx.state.infos.customer = response.content.customer;
        return true;
    },
    instantiate_device: async (ctx, { type, mac }) => {
        const { key, secret } = BrowserUtils.localGet('device_account') || {};
        const path = StringUtils.format_with_obj(APIRoutes.device_information, {
            type,
            mac,
        });
        const method = 'GET';
        const payload = {
            path,
            method,
            commit: ctx.commit,
            signature: AuthUtils.get_api_headers(
        { key: key || mac, secret: secret || mac },
        method,
        path,
      ),
        };

        const response = await API.fetch(payload);

        if (response.type !== Messages.SUCCESS) {
            const {
        content: { error },
      } = response;
            return ctx.commit(Messages.ERROR, {
                error_type: error || 'unknown',
            });
        }

        if (Object.keys(response.content).length === 0) {
            return ctx.commit(Messages.ERROR, {
                error_type: 'unrecognized-device',
            });
        }

        ctx.state.infos = response.content;
        ctx.state.desk.customer =
      ctx.state.infos.last_called_customer_for_desk || null;
        ctx.state.machine.global_waiting = ctx.state.infos.global_waiting;

        if (ctx.state.socket == null) {
            ctx.commit(Messages.CONNECT_SOCKET, ctx);
        }

        ctx.state.interfaceLang = ctx.state.template.view_infos.lang;
        if (ctx.state.interfaceLang == null) {
            ctx.state.interfaceLang = ctx.state.infos.suborganization.langs.find(
        l => l === ctx.state.browserLanguage,
      );
        }
        if (ctx.state.interfaceLang == null) {
            ctx.state.interfaceLang = ctx.state.infos.suborganization.langs[0];
        }

        ctx.commit(Messages.SETUP_TEMPLATE, ctx);

        if ('Kiosk' in window) {
            Kiosk[Kiosk.current.app].setApplicationStatus({
                status: 'Ok',
                statusDetail: '',
                statusDescription: 'OK - Device instantiated',
            });
        }
        return true;
    },

    register: async (
    ctx,
    { body, registration_type, prequalifications_queue_enabled },
  ) => {
        const path = prequalifications_queue_enabled
      ? APIRoutes.register_queue
      : APIRoutes.register;
        const method = 'POST';
        const key = ctx.state.infos.device.auth.token;
        const payload = {
            path,
            method,
            body,
            commit: ctx.commit,
            signature: AuthUtils.get_api_headers(key, method, path),
        };

        const pkey = body.prequalification;
        const prequalifications = ctx.state.infos.prequalifications;

        const response = await API.fetch(payload);
        let registration = {};
        if (response.type !== Messages.SUCCESS) {
            if (
        prequalifications[pkey].meeting &&
        response.content.error === 'UnableToCreateEntity'
      ) {
                registration = {
                    post_registration: true,
                    no_meeting: true,
                    registered: null,
                    sending: false,
                    expired_meeting: false,
                    status: 'no_meeting',
                    unknown_error: false,
                };
            } else {
                registration = {
                    post_registration: true,
                    unknown_error: true,
                    registered: null,
                    status: 'error',
                    sending: false,
                    expired_meeting: false,
                };
            }
            ctx.state.template.view_infos.registration = registration;
        } else {
            if (
        response.content.change === 'Validation' &&
        ((response.content.errors.phone || []).length >= 1 ||
          (response.content.errors.email || []).length >= 1)
      ) {
                registration = {
                    registered: null,
                    post_registration: true,
                    no_meeting: false,
                    expired_meeting: false,
                    sending: false,
                    already_registered: true,
                    status: 'registered',
                    unknown_error: false,
                };
            } else {
                registration = {
                    registered: response.content.entity,
                    post_registration: true,
                    no_meeting: false,
                    expired_meeting: response.content.entity.expired,
                    sending: false,
                    status: response.content.entity.expired
            ? 'meeting_expired'
            : 'registered',
                    unknown_error: false,
                };
            }
            ctx.state.template.view_infos.registration = registration;
            ctx.commit(Messages.UPDATE_TEMPLATE_TEXT, {
                pageNumber: ctx.state.template.currentPage,
            });
        }
    },

    call_api: async (ctx, { path, method, body }) => {
        const key = _.get(ctx.state, ['infos', 'device', 'auth', 'token'], {
            key: '',
            secret: '',
        });
        const payload = {
            path,
            method,
            body,
            commit: ctx.commit,
            signature: AuthUtils.get_api_headers(key, method, path),
        };

        ctx.commit(Messages.LOADING, true);
        const response = await API.fetch(payload);
        ctx.commit(Messages.LOADING, false);
        ctx.commit(Messages.FETCH, response);
    },
    next: async (ctx, my_payload) => {
        my_payload = my_payload || {};

        const path =
      my_payload && my_payload.customer
        ? APIRoutes.pick_someone
        : APIRoutes.next;
        const method = 'POST';
        const key = ctx.state.infos.device.auth.token;
        const payload = {
            method,
            path,
            body: {
                desk: ctx.state.infos.device._id,
                suborganization: ctx.state.infos.suborganization._id,
                customer: my_payload ? my_payload.customer : null,
                desk_custom_action: my_payload.desk_custom_action
          ? my_payload.desk_custom_action
          : undefined,
                vendor: my_payload.vendor,
            },
            commit: ctx.commit,
            signature: AuthUtils.get_api_headers(key, method, path),
        };

        const response = await API.fetch(payload);
        ctx.state.template.view_infos.selectedVendorOpen = 'na';
        if (response.type === Messages.SUCCESS) {
            ctx.state.desk.customer = response.content.entity;
            if (!ctx.state.desk.customer) {
                ctx.state.desk.next_error = 'NoNewCustomer';
            } else {
                ctx.state.desk.next_error = '';
            }
        } else if (response.content.error === 'TooSoonToCallMeeting') {
            ctx.state.desk.next_error = 'TooSoonToCallMeeting';
        } else if (response.content.error === 'TooSoonToCallNext') {
            ctx.state.desk.next_error = 'TooSoonToCallNext';
        } else {
            ctx.state.desk.next_error = 'UnknownError';
        }
    },
    end_call: async (ctx, my_payload) => {
        my_payload = my_payload || {};
        const path = APIRoutes.end_someone;
        const method = 'POST';
        const key = ctx.state.infos.device.auth.token;
        const payload = {
            method,
            path,
            body: {
                desk: ctx.state.infos.device._id,
                suborganization: ctx.state.infos.suborganization._id,
                desk_custom_action: my_payload.desk_custom_action
          ? my_payload.desk_custom_action
          : undefined,
            },
            commit: ctx.commit,
            signature: AuthUtils.get_api_headers(key, method, path),
        };

        const response = await API.fetch(payload);
        ctx.state.template.view_infos.selectedVendorOpen = 'na';
        if (response.type === Messages.SUCCESS) {
            ctx.state.desk.customer = null; // response.content.entity;
            ctx.state.desk.next_error = '';
        } else {
            ctx.state.desk.next_error = 'UnknownError';
        }
    },

    remove_name: async (ctx, { body }) => {
        const path = APIRoutes.remove_name;
        const method = 'PUT';
        const key = ctx.state.infos.device.auth.token;
        const payload = {
            path,
            method,
            body,
            commit: ctx.commit,
            signature: AuthUtils.get_api_headers(key, method, path),
        };

        const response = await API.fetch(payload);
        if (response.type === Messages.SUCCESS) {
            ctx.state.desk.remove_name = 'success';
        } else {
            ctx.state.desk.remove_name = 'error';
        }
    },

    [Messages.SOCKET_EVENT]: async (ctx, { id, data }) => {
        const device = ctx.state.infos.device;
        if (device.type === 'machine') {
            await SocketDispatcher('machine', id, data, ctx);
        } else if (device.type === 'desk') {
            await SocketDispatcher('desk', id, data, ctx);
        } else {
            await SocketDispatcher('screen', id, data, ctx);
        }
    },
};
