const _ = require('lodash');
const Vuex = require('vuex');
const Messages = require('../../../../../api/messages');
const Handlebars = require('../../../../../../../app/modules/utils/ehandlebars');
const EventType = require('../../../../../enums/event_types');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');
const WithTimeout = require('../../../mixins/with_timeout/WithTimeout');
const PrequalsMixin = require('../../../mixins/prequalifications/PrequalificationsMixin');

module.exports = {
    mixins: [MasterWidget, WithTimeout, PrequalsMixin],
    data() {
        return {
            state: {},
        };
    },
    methods: {
        clickPrequalification(key, e) {
            this.click_prequalification(key, e);
        },
        tap(key) {
            return e => this.clickPrequalification(key, e);
        },
        swipe(key) {
            return (dir, e) => this.clickPrequalification(key, e);
        },
    },
    watch: {
        depth(newDepth) {
            this.state.depth = newDepth;
            this.state.path = this.$store.state.template.view_infos.prequalification_path;
            this.state.prequalificationKeys = this.computeKeys(this.state.path);
        },
    },
    computed: _.merge({
        matrix() {
            return this.state.prequalificationKeys
                .reduce((rows, key, index) => (index % 2 === 0 ? rows.push([key])
                            : rows[rows.length - 1].push(key)) && rows, []);
        },
        global_waiting_time() {
            const {
                global_waiting, number_of_rsvp_customers = 0,
                number_of_meeting_customers = 0,
                number_of_rsvp_meeting_customers = 0,
                number_of_meetings_soon = 0,
            } = this.$store.state.infos;
            /* const min = this.variables.minWaitingTime || 5;
            const max = this.variables.maxWaitingTime || 25;
            const prefixMin = this.$text('prefix_min_waiting_time', this.lang);
            const prefixMax = this.$text('prefix_max_waiting_time', this.lang); */
            // const between = this.$text('between_waiting_time', this.lang);
            const step = this.variables.stepWaitingTime || 10;
            const prefix = this.$text('prefix_waiting_time', this.lang);
            const minutes = this.$text('minutes_waiting_time', this.lang);
            const people_waiting = this.$text('people_waiting', this.lang);
            const absVal = Math.abs(global_waiting);
            const times = parseInt(absVal / step, 10);
            const higher = times * step + step;
            const lower = higher - step;
            const remainingHours = parseInt(`${lower / 60}`, 10);
            const remainingMinutes = Math.ceil(lower - remainingHours * 60);

            // return Handlebars.compile(`{{binned_count global_waiting min=${min} max=${max} step=${step} prefixMin="${prefixMin}" prefixMax="${prefixMax}" betweenInterval="${between}" prefix="${prefix}"}} ${minutes}`)({global_waiting});

            /* if(remainingHours > 0) {
                return `${prefix} ${remainingHours}h${remainingMinutes}`
            }
            return `${prefix} ${remainingMinutes} ${minutes}`;*/

            return `${number_of_rsvp_customers + number_of_meetings_soon} ${prefix}`;
        },
        prm_icons() {
            return this.variables.prm_icons || '/public/front/imgs/prm_icons.png';
        },
    }, Vuex.mapGetters(['depth'])),
    mounted() {
        const view_infos = this.$store.state.template.view_infos;
        if ('depth' in view_infos && this.state.depth < view_infos.depth) {
            const last_idx = view_infos.prequalification_path.length - 1;
            const key = view_infos.prequalification_path[last_idx];
            this.clickPrequalification(key, { preventDefault() {} });
        }
    },
};
