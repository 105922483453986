<template>
    <section class="hero is-fullheight">
    <div class="hero-body">
        <div class="container is-fluid">
            <div class="columns is-centered">
                <div class="column is-10">
                    <div class="card">
                        <div class="card-content">
                            <div class="content">
                                <slot></slot>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
</template>

<script>
    module.exports = require('./CenteredBox');
</script>
