const BrowserUtils = require('../utils/browser');
const EventType = require('../enums/event_types');

module.exports = {
    loading: false,
    success: false,
    content: [],
    error: false,
    error_type: '',
    infos: {},
    disabled: false,
    notConnected: false,
    socket: null,
    socketReconnectionInterval: null,
    desk: {
        customer: null,
        next_error: '',
        remove_name: '',
        new_customer: '',
        vendors: [],
    },
    machine: {
        vibrator_configuration: {},
        barcode_result: '',
        printer_finished: false,
        global_waiting: 0,
    },
    screen: {
        customers: [],
        last: '',
    },
    template: {
        currentPage: 0,
        events: EventType.enumValues.reduce((o, val) => {
            o[val.toString()] = {};
            return o;
        }, {}),
        page: {
            header_view: '',
            middle_view: '',
            footer_view: '',
        },
        view_infos: {
            prequalification_path: [],
            registration: {},
            depth: 0,
            registration_type: '',
            selectedVendorOpen: 'na',
        },
    },
    browserLanguage: BrowserUtils.normalizeBrowserLanguage(BrowserUtils.getFirstBrowserLanguage()),
    reconnectionIntervalMs: 5000,
    reconnectionDefaultIntervalMs: 5000,
    interfaceLang: null,
};
