const Vuex = require('vuex');
const _ = require('lodash');
const toastr = require('toastr');
const momenttz = require('moment-timezone');
const moment = require('moment');
const EventType = require('../../../../../enums/event_types');
const StringUtils = require('../../../../../utils/strings');
const Messages = require('../../../../../api/messages');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');

module.exports = {
    mixins: [MasterWidget],
    data() {
        return {
            state: {
                wpartitions: {},
                mpartitions: {},
            },
        };
    },
    methods: {
        back(e) {
            e.preventDefault();
            this.emitEvent(this.events.home);
        },
        emitEvent(event, value = 'NA', opts = {}) {
            const payload = {
                commit: this.$store.commit,
                eventType: EventType.ALL,
                eventValue: value,
                event,
                eventOptions: opts,
            };

            this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
        },
        generatePartitions(list) {
            return list.reduce((obj, c) => {
                const prequal = c.prequalification;
                if (!(prequal in obj)) {
                    obj[prequal] = [];
                }
                obj[prequal].push(c);
                return obj;
            }, {});
        },
        format_visit(visits, days) {
            return StringUtils.format(this.texts.visits[this.lang], visits, days);
        },
        format_meeting(timestamp) {
            return momenttz.tz(timestamp, this.tz).format('HH:mm');
        },
        remove_customer_name(cid, e) {
            e.preventDefault();
            this.$store.dispatch('remove_name', { body: { _id: cid } });
        },
    },
    watch: {
        remove_name(new_remove) {
            if (new_remove === 'success') {
                toastr.success(this.texts.name_removed[this.lang], '', {
                    preventDuplicates: true,
                });
            } else if (new_remove === 'error') {
                toastr.error(this.texts.error_name_removed[this.lang], '', {
                    preventDuplicates: true,
                });
            }
        },
    },
    computed: _.merge(
        {
            queues() {
                const queues = _.reduce(
          this.state.mpartitions,
          (obj, val, key) => {
              obj[key] = val;
              return obj;
          },
          _.cloneDeep(this.state.wpartitions),
        );
                return queues;
            },
            prequal_keys() {
                const prequals = this.$store.state.infos.prequalifications;
                const keys = Object.keys(this.queues);
                keys.sort((a, b) => prequals[a].desk_order > prequals[b].desk_order);
                return keys;
            },
            all_sorted_customers() {
                const waiting = this.$store.state.infos.waiting_customers;
                const meetings = this.$store.state.infos.meeting_customers;
                const all = [...waiting, ...meetings];
                all.sort((a, b) => {
                    const at = +moment(a.prioritized ? a.stimestamp : (a.rsvp ? a.rsvptimestamp : a.stimestamp));
                    const bt = +moment(b.prioritized ? b.stimestamp : (b.rsvp ? b.rsvptimestamp : b.stimestamp));
                    return at - bt;
                });
                return all;
            },
            tz() {
                return this.$store.state.infos.suborganization.tz;
            },
            return_alert() {
                return this.$store.state.infos.suborganization.alerts.customer_return;
            },
        },
    Vuex.mapGetters(['remove_name']),
  ),
    mounted() {
        let wpartitions = this.generatePartitions(
      this.$store.state.infos.waiting_customers,
    );
        const mpartitions = this.generatePartitions(
      this.$store.state.infos.meeting_customers,
    );
    // Remove meeting prequalifications from waiting partitions
        wpartitions = _.reduce(
      wpartitions,
      (obj, val, key) => {
          if (!(key in mpartitions)) {
              obj[key] = val;
          }
          return obj;
      },
      {},
    );

        this.state.wpartitions = wpartitions;
        this.state.mpartitions = mpartitions;
    },
};
