const _ = require('lodash');
const Vuex = require('vuex');

module.exports = {
    props: ['type'],
    methods: {
        $text(key, lang) {
            if (!(key in this.texts)) {
                return '';
            }

            if (!lang) {
                return this.texts[key][this.lang] || '';
            }
            return this.texts[key][lang] || '';
        },
        $variable(key) {
            if(!(key in this.variables) && this.variables[key].trim() === '') {
                return null;
            }

            return this.variables[key];
        }
    },
    computed: {
        texts() {
            return this.$store.getters.texts(this.type);
        },
        variables() {
            const state = this.$store.state;
            const page = state.infos.template.pages[state.template.currentPage];
            if (this.type in page && page[this.type].widget != null) {
                return page[this.type].variables;
            }
            return {};
        },
        conditions() {
            const state = this.$store.state;
            const page = state.infos.template.pages[state.template.currentPage];
            if (this.type in page && page[this.type].widget != null) {
                return page[this.type].conditions;
            }
            return {};
        },
        events() {
            const state = this.$store.state;
            const page = state.infos.template.pages[state.template.currentPage];
            if (this.type in page && page[this.type].widget != null) {
                return page[this.type].widget.events;
            }
            return {};
        },
        lang() {
            const { lang } = this.$store.state.template.view_infos;
            if (lang) {
                return lang;
            }
            return this.$store.state.interfaceLang;
        },
    },
};
