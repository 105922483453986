const Vuex = require('vuex');
const _ = require('lodash');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');
const Handlebars = require('../../../../../../../app/modules/utils/ehandlebars');

module.exports = {
    mixins: [MasterWidget],
    data() {
        return {
            state: {},
        };
    },
    methods: {},
    watch: {},
    beforeCreate() {},
    beforeDestroy() {},
    mounted() {},
};
