const _ = require('lodash');
const io = require('socket.io-client/dist/socket.io');
const Messages = require('../api/messages');
const SocketUtils = require('../utils/socket');
const URLUtils = require('../utils/url');
const StringUtils = require('../utils/strings');
const EventType = require('../enums/event_types');
const SocketDispatcher = require('../utils/socket_dispatcher');
const Utils = require('../utils/utils');
const Vue = require('vue');

module.exports = {
    [Messages.LOADING]: (state, payload) => {
        if (payload !== null
            && payload !== undefined) {
            state.loading = payload;
        } else {
            state.loading = true;
            state.success = false;
            state.content = [];
        }
    },

    [Messages.FETCH]: (state, payload) => {
        state.success = payload.type === Messages.SUCCESS;
        if (payload.content == null) {
            payload.content = [];
        }

        if (state.success) {
            state.content = payload.content instanceof Array ? payload.content
                : [payload.content];
        } else {
            state.content = payload.content;
        }
        state.loading = false;
    },

    [Messages.ERROR]: (state, payload) => {
        if(payload.error_type) {
            state.error = true;
            state.error_type = payload.error_type;
        } else {
            state.error = false;
            state.error_type = null;
        }
    },

    [Messages.CONNECT_SOCKET]: (state, payload) => {
        const disconnect_function = () => {
            state.socket.off('connect_error');
            state.socket.off('message');
            state.socket.off('connect');
            state.socket.off('disconnect');
            state.disabled = true;
            state.notConnected = true;
            state.socket = null;

            if (state.socketReconnectionInterval != null) {
                clearTimeout(state.socketReconnectionInterval);
                state.socketReconnectionInterval = null;
            }

            state.socketReconnectionInterval = setTimeout(payload.commit.bind(null,
                Messages.CONNECT_SOCKET, payload), state.reconnectionIntervalMs);
            state.reconnectionIntervalMs += state.reconnectionDefaultIntervalMs;
        };

        const device = state.infos.device;
        const suborganization = state.infos.suborganization;
        // const prequalifications = state.infos.prequalifications;

        if (state.socket == null) {
            state.socket = io(URLUtils.get_ws_url(window.location), {
                reconnection: false,
                transports: ['websocket', 'polling'],
            }).connect();

            state.socket.on('connect_error', disconnect_function);
        } else {
            return;
        }

        state.socket.on('connect', () => {
            if (state.socketReconnectionInterval != null) {
                clearTimeout(state.socketReconnectionInterval);
                state.socketReconnectionInterval = null;
            }

            state.disabled = false;
            state.notConnected = false;
            state.reconnectionIntervalMs = state.reconnectionDefaultIntervalMs;
            state.socket.emit('message', SocketUtils.emit('id', { _id: device._id }));
            state.socket.emit('message', SocketUtils.emit('subscription', { _id: device._id, _sid: suborganization._id }));

            // Help recovering from connection failures
            if (device.type === 'screen') {
                payload.dispatch('recover_screen');
            }

            state.socket.on('message', (event) => {
                const [id, data] = SocketUtils.unpack(event);
                if (id == null) {
                    return;
                }

                SocketDispatcher(device.type, id, data || {}, payload)
                    .then(() => {}).catch(err => console.error(err));
            });

            state.socket.on('disconnect', disconnect_function);
        });
    },

    [Messages.SETUP_TEMPLATE]: (state, payload) => {
        const events = state.template.events;
        state.infos.template.pages.forEach((current_page, idx) => {
            const [type, event] = current_page.trigger.variable.split('/');
            const trigger_value = current_page.trigger.value;
            const all = EventType.ALL.toString();
            events[type][event] = events[type][event] || {};
            events[type][event][trigger_value] = idx;

            events[all][event] = events[all][event] || {};
            events[all][event][trigger_value] = idx;

            ['header', 'middle', 'footer'].forEach((elt) => {
                if (current_page[elt] && current_page[elt].widget != null) {
                    const events = _.reduce(current_page[elt].widget.events, (obj, val) => {
                        obj[val.name] = val.name;
                        return obj;
                    }, {});
                    current_page[elt].widget.events = events;

                    ['conditions', 'variables'].forEach((info) => {
                        const new_infos = _.reduce(current_page[elt][info], (obj, val) => {
                            obj[val.name] = val.value;
                            return obj;
                        }, {});
                        current_page[elt][info] = new_infos;
                    });
                }
            });
            payload.commit(Messages.UPDATE_TEMPLATE_TEXT, { pageNumber: idx });
        });

        payload.commit(Messages.UPDATE_TEMPLATE_VIEW, { pageNumber: state.template.currentPage });
    },

    [Messages.UPDATE_TEMPLATE]: (state, payload) => {
        if (payload.eventType == null) {
            // TODO logger.warn
            return;
        }
        if (payload.eventValue == null) {
            payload.eventValue = 'NA';
        }
        if (payload.eventOptions == null) {
            payload.eventOptions = {};
        }

        const event = payload.event;
        const type = payload.eventType;
        const value = payload.eventValue;
        const opts = payload.eventOptions;
        const events = state.template.events;
        let page_number = 0;

        if (Object.keys(opts).length > 0) {
            const view_infos = state.template.view_infos;
            const new_infos = Utils.merge_with_replacement(view_infos, opts);
            state.template.view_infos = Object.assign({}, new_infos);
            if ('lang' in new_infos && new_infos.lang != null) {
                state.interfaceLang = state.template.view_infos.lang;
            }
        }

        if (event in events[type.toString()]
            && value in events[type.toString()][event]) {
            page_number = events[type.toString()][event][value];
        } else {
            // TODO logger.warn
            return;
        }

        state.template.currentPage = page_number;
        payload.commit(Messages.UPDATE_TEMPLATE_TEXT, { pageNumber: page_number });
        payload.commit(Messages.UPDATE_TEMPLATE_VIEW, { pageNumber: page_number });
    },

    [Messages.UPDATE_TEMPLATE_VIEW]: (state, payload) => {
        const current_page = state.infos.template.pages[payload.pageNumber];
        state.template.page.name = current_page.name.replace(/ +/gi, '-').toLowerCase();

        ['header', 'middle', 'footer'].forEach((elt) => {
            if (current_page[elt] && current_page[elt].widget != null) {
                state.template.page[`${elt}_view`] = current_page[elt].widget.file;
            } else {
                state.template.page[`${elt}_view`] = '';
            }
        });
    },

    [Messages.UPDATE_TEMPLATE_TEXT]: (state, payload) => {
        const infos = state.infos;
        const { template: { view_infos: { registration } } } = state;
        const current_page = infos.template.pages[payload.pageNumber];
        const object = {
            suborganization: infos.suborganization,
            device: infos.device,
            orgnanization: infos.organization,
            customer: infos.customer,
            global_waiting: state.machine.global_waiting,
            registration: registration || {},
        };

        ['header', 'middle', 'footer'].forEach((elt) => {
            if (current_page[elt] && current_page[elt].widget != null) {
                const texts = _.reduce(current_page[elt].texts, (obj, val) => {
                    obj[val.name] = _.reduce(val.content, (o, v, k) => {
                        o[k] = StringUtils.format_with_obj(v, object);
                        return o;
                    }, {});
                    return obj;
                }, {});
                Vue.set(current_page[elt], 'view_texts', texts);
            }
        });
    },

    [Messages.RESET_GLOBAL_STATE]: (state) => {
        state.machine.barcode_result = '';
    },
};
