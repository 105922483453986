module.exports = {
    props: {
        numberOfItems: { type: Number, required: true },
        itemsPerPage: { type: Number, default: 10 },
        skip: { type: Number, default: 0 },
        useArrows: { type: Boolean, default: false },
    },
    data() {
        return {
            state: {
                firstPage: 1,
                currentPage: 1,
                lastPage: 1,
                finalItemsPerPage: this.itemsPerPage,
            },
        };
    },
    methods: {
        goto(page) {
            const { lastPage, finalItemsPerPage } = this.state;
            if (page < 1 || page > lastPage) {
                return;
            }

            this.state.currentPage = page;
            const newSkip = (page * finalItemsPerPage) - finalItemsPerPage;
            this.$emit('page-change', newSkip, page);
        },
    },
    watch: {
        numberOfItems(newNumber) {
            const { finalItemsPerPage } = this.state;
            this.state.lastPage = Math.ceil(newNumber / finalItemsPerPage);
        },
        skip(newSkip) {
            const { finalItemsPerPage } = this.state;
            this.state.currentPage = (newSkip + finalItemsPerPage) / finalItemsPerPage;
        },
        itemsPerPage(newItemsPerPage) {
            if (this.mobileModeEnabled) {
                this.state.finalItemsPerPage = 1;
            } else {
                this.state.finalItemsPerPage = newItemsPerPage;
            }
        },
    },
    mounted() {
        const { finalItemsPerPage } = this.state;
        this.state.lastPage = Math.ceil(this.numberOfItems / finalItemsPerPage);
        this.state.currentPage = (this.skip + finalItemsPerPage) / finalItemsPerPage;
    },
};
