<template>
<div class="container is-fluid desks-chooser prequalification container-middle is-portrait-vertical">
    <div class="columns is-centered has-xs-left-margin has-xs-right-margin" v-if="texts.question[lang] && texts.question[lang].trim() !== ''">
        <div class="column is-10 has-xs-bottom-margin">
            <h3 class="has-text-centered" v-html="texts.question[lang]"></h3>
        </div>
    </div>
    <div class="columns is-centered has-xs-left-margin has-xs-right-margin" v-if="this.conditions.all_is_enabled && !this.conditions.all_is_incorporated_in_desks">
        <div :class="['is-unselectable column', `is-${variables.all_desks_size || 4}`, 'has-text-centered']">
            <div
                class="card all-desks-card"
                @click.stop="clickDesk(state.ALL_KEY)"
                v-touch:tap="tap(state.ALL_KEY)"
                v-touch:swipe="swipe(state.ALL_KEY)"
            >
                <h4 class="h3-portrait" v-html="texts.all[lang]" />
            </div>
        </div>
    </div>
    <div class="columns is-centered has-xs-left-margin has-xs-right-margin" v-for="row in matrix">
        <div :class="['is-unselectable column', `is-${compute_size_of_desk_card(key)}`, 'has-text-centered']" v-for="key in row">
            <div
                class="card desk-card"
                @click.stop="clickDesk(key)"
                v-touch:tap="tap(key)"
                v-touch:swipe="swipe(key)"
            >
                <h4 v-if="key === state.ALL_KEY" class="h3-portrait" v-html="texts.all[lang]" />
                <h4 v-else class="h3-portrait" v-html="$store.state.infos.desks[key].html_name[lang]" />
            </div>
        </div>
    </div>
    <div class="columns has-xs-left-margin has-xs-right-margin is-mobile" v-if="num_of_pages > 1">
        <div  class="column is-4" v-if="state.current_page > 0">
            <div 
                @click.stop="change_page(state.current_page-1)" class="card-button is-unselectable"
                v-touch:tap="tap_page(state.current_page-1)"
                v-touch:swipe="swipe_page(state.current_page-1)"
            >
                <h6 class="has-text-centered">{{texts.previous[lang]}}</h6>
            </div>
        </div>
        <div :class="`column is-4 is-offset-${state.current_page === 0 ? 8 : 4}`" v-if="state.current_page < num_of_pages-1">
            <div 
                @click.stop="change_page(state.current_page+1)" class="card-button is-unselectable"
                v-touch:tap="tap_page(state.current_page+1)"
                v-touch:swipe="swipe_page(state.current_page+1)"
            >
                <h6 class="has-text-centered">{{texts.next[lang]}}</h6>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    module.exports = require('./Desks');
</script>
