const Crypto = require('crypto');
const moment = require('moment');
const URLUtils = require('./url');

class Auth {
    static get_api_headers(api_info, method, path) {
        if (method === 'DEL') {
            method = 'DELETE';
        }

        const parsed_url = URLUtils.parse_url(path);
        const timestamp = +moment();
        const concat = `${method}${parsed_url.pathname}${timestamp}`;
        const sign = Crypto.createHmac('sha1', api_info.secret).update(concat).digest('hex');

        return {
            timestamp,
            sign,
            key: api_info.key,
        };
    }
}

module.exports = Auth;
