const _ = require('lodash');
const EventHub = require('../../store/event_hub');
const BrowserUtils = require('../../utils/browser');
const Vuex = require('vuex');

module.exports = {
    props: ['type', 'mac'],
    data() {
        return {
        };
    },
    methods: {
        click_on(type, e) {
            // e.preventDefault();
            EventHub.$emit(`meta-click-on-${type}`, e);
        },
    },
    computed: _.merge({
        errorText() {
            switch(this.$store.state.error_type) {
                case 'NoAuthorizationHeaderError':
                case 'NoTwoPartAuthorizationError':
                case 'InvalidTimestamp':
                    return ['auth', 'You do not have access to this page. Please contact an administrator to set an account.'];
                case 'unknown':
                default:
                    return ['unknown', 'A problem occurred. Please contact an administrator to solve the problem'];
            }
        },
        component_name() {
            return part => this.$store.state.template.page[`${part}_view`];
        },
    }, Vuex.mapGetters(['device', 'suborganization'])),
    watch: {
        errorText(net) {
            const [err, txt] = net;
            if(err === 'auth') {
                this.$router.push('/account');
            }
        },
        device(d) {
            if(d){
                document.documentElement.className += ` ${d.type}-part`;
            }

            if (d && d.system && d.system.ask_before_closing) {
                BrowserUtils.ask_before_closing('Are you sure, you want to close this window?');
            }

        },
        suborganization(s) {
            if(s) {
                document.documentElement.className += ` ${s.css_template}-template`;
            }
        }
    },
    beforeMount() {
        document.documentElement.className = document.documentElement.className.replace(/account/, '');
        this.$store.dispatch('instantiate_device', { type: this.type, mac: this.mac });
    },
    mounted() {
    },
};
