// const Vue = require('Vue');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');

module.exports = {
    mixins: [MasterWidget],
    data() {
        return {
            state: {
            },
        };
    },
    computed: {
        lang() {
            return this.$store.state.interfaceLang;
        },
        headers() {
            const sep = this.variables.text_separator;
            const key = `${this.status}_headers`; 
            return this.texts[key] && this.texts[key][this.lang] ? this.texts[key][this.lang].split(sep).filter(h => h.trim() !== '') : [];
        },
        subheaders() {
            const sep = this.variables.text_separator;
            const key = `${this.status}_subheaders`; 
            return this.texts[key] && this.texts[key][this.lang] ? this.texts[key][this.lang].split(sep).filter(h => h.trim() !== '') : [];
        },
        status() {
            const view_infos = this.$store.state.template.view_infos;
            if ('registration' in view_infos
                && 'status' in view_infos.registration) {
                return view_infos.registration.status;
            }
            return 'default';
        },
    },
};
