<template>
<div>
    <audio autoplay="autoplay" v-for="(s, i) in state.sounds" :key="i">
        <source :src="`${sound_path}.mp3`" type="audio/mpeg" />
        <source :src="`${sound_path}.ogg`" type="audio/ogg" />
        <embed hidden="true" autostart="true" loop="false" :src="`${sound_path}.mp3`" />
    </audio>
    <div v-if="conditions.datetime" class="columns is-paddingless is-marginless">
        <div class="column has-text-right is-paddingless has-xxs-margins">
            <div class="h4 is-capitalized" v-html="state.datetime.format(variables.datetime_format)">
            </div>
        </div>
    </div>
    <table class="table is-fullwidth is-narrow is-marginless is-border-column-only">
        <thead>
            <tr>
                <th v-for="d in desks" class="h2 has-text-centered">{{texts.ticket[lang]}}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td v-for="d in desks" class="called customer h3 has-text-centered">{{displayed_customers[d._id]}}</td>
            </tr>
            <tr>
                <td v-for="d in desks" class="has-text-centered">
                    <i  class="h2 fa fa-arrow-down"></i>
                </td>
            </tr>
            <tr>
                <td v-for="d in desks" class="h3 has-text-centered">{{texts.adviser[lang]}}</td>
            </tr>
            <tr>
                <td v-for="d in desks" class="desk called h3 has-text-centered">{{d.name}}</td>
            </tr>
        </tbody>
    </table>
</div>
</template>

<script>
    module.exports = require('./CallingScreen');
</script>
