<template>
<div class="container is-fluid prequalification container-middle is-portrait-vertical" v-if="!is_suborganization_close || !no_prequalifications_available">
    <div v-if="orientation.length > 0" class="columns is-centered">
        <div class="column is-10 has-text-centered has-bottom-margin">
            <h3 v-html="orientation"></h3>
        </div>
    </div>
    <div class="columns is-centered" v-if="total_pages > 1">
        <div class="is-unselectable column is-10">
            <nav class="pagination is-right is-rounded is-small" role="navigation" aria-label="pagination">
                <ul class="pagination-list">
                    <li v-for="i in total_pages"><a 
                        @click.stop="change_page(i-1)" 
                        v-touch:tap="tap_page(i-1)"
                        v-touch:swipe="swipe_page(i-1)"
                        :class='["pagination-link", {"is-current": state.current_page === i-1}]'>{{i}}</a></li>
                </ul>
            </nav>
        </div>
    </div>
    <div class="columns is-centered" v-for="row in matrix(state.current_page)">
        <div class="is-unselectable column is-5 has-text-centered" v-for="key in row">
            <div
                :class="['card', {'card-disabled': unreachable_prequalifications.has(key) || max_selection_reached(key)}]"
                :key="key"
                @click="select_prequalification(key, $event)"
                v-touch:tap="tap(key)"
                v-touch:swipe="swipe(key)"
            >
                <h4 class="h3-portrait" v-if="unreachable_prequalifications.has(key)" v-html="$text('suborganization_closed_prequalification')"/>
                <template v-else>
                    <input :checked="is_checked(key)" type="checkbox" class="is-pulled-left switch" />
                    <h4 class="h3-portrait" v-html="$store.state.infos.prequalifications[key].html_name[lang]" />
                    <h6 class="h5-portrait is-text-secondary" v-html="$store.state.infos.prequalifications[key].subtitle[lang]" />
                </template>
            </div>
        </div>
    </div>
    <div class="columns is-centered has-margin-bottom">
        <div class="is-unselectable column is-10 has-text-centered">
            <div
                :class="['card', 'validate-card', {'card-disabled': state.selected.length === 0}]"
                @click="validate"
                v-touch:tap="tap_validate"
                v-touch:swipe="swipe_validate"
            >
                <h4 class="h3-portrait" v-html="$text('validate')" />
            </div>
        </div>
    </div>
</div>
<div v-else class="container is-fluid prequalification container-middle is-portrait-vertical">
    <div class="columns is-centered">
        <div class="column is-10 has-text-centered has-bottom-margin">
            <!--<h3 v-html="orientation"></h3>-->
            <h3 v-html="$text('suborganization_closed')"/>
        </div>
    </div>
</div>
</template>

<script>
    module.exports = require('./PrequalificationsMulti');
</script>
