<template>
<div class="container is-fluid">
    <div class="columns is-mobile">
        <div class="column is-12">
            <desk-customer
            :texts="texts"
            :variables="variables"
            :customer="this.$store.state.desk.customer"
            :conditions="conditions"
            :events="events"
            :lang="lang"
            />
        </div>
    </div>
    <div class="columns is-mobile">
        <div class="column is-12 has-text-centered">
            <a class="button is-dark" @click="back">
                <span class="icon">
                    <i class="fa fa-arrow-left" />
                    </span>
                    <span>{{texts.back[lang]}}</span>
                </a>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    module.exports = require('./CustomerInformation');
</script>
