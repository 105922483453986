const _ = require("lodash");
const EventType = require("../../../../enums/event_types");
const Messages = require("../../../../api/messages");
const Phones = require("../../../../../../app/modules/phones");
const MasterWidgetMixin = require("../master_widget/MasterWidget");
const ASCIIFolder = require("../../../../utils/ascii_folder");
const Validator = require("validator");

module.exports = {
  mixins: [MasterWidgetMixin],
  methods: {
    _is_input_valid(input, type, suborganization) {
      switch (type) {
        case "phone":
        case "meeting": {
          const intl = Phones.format_phone_number(
            input.trim(),
            suborganization.sms.default_code
          );
          return intl != null;
        }
        case "email":
          return Validator.isEmail(input.trim());
        case "cc": {
          if (this.conditions.no_cc_6) {
            return input.trim().length === 4;
          }
          return input.trim().length === 4 || input.trim().length === 6;
        }
        case "name":
        case "prm": {
          const folded = ASCIIFolder.fold(input.trim().toUpperCase());
          return folded.length > 0 && folded.match(/^[A-Z -]+$/) !== null;
        }
        case "vibrator":
          return true;
        case "code0":
        case "code1":
        case "code2":
        case "code3":
        case "code4": {
          if (!(type in suborganization.extra_codes)) {
            return false;
          }
          const format_re = new RegExp(
            suborganization.extra_codes[type].superformat
          );
          return format_re.test(input.trim());
        }
        case "barcode":
        case "paper":
          return true;
        default:
          return false;
      }
    },
    emit_event_as_registration(
      rtype,
      input,
      suborganization,
      title,
      event,
      value = "NA"
    ) {
      if (!this._is_input_valid(input, rtype, suborganization)) {
        // this.state.showValidation = true;
        return -1; // Input not valid
      }

      const view_infos = this.$store.state.template.view_infos;
      const opts = {
        registration: {
          customer: {},
        },
      };

      if (
        "registration" in view_infos &&
        "customer" in view_infos.registration
      ) {
        opts.registration.customer = view_infos.registration.customer;
      }
      opts.registration.customer.input = this.state.input.trim();
      opts.registration.customer = this._set_type_information(
        rtype,
        input,
        title,
        opts.registration.customer
      );

      const payload = {
        commit: this.$store.commit,
        eventType: EventType.ALL,
        event,
        eventValue: value,
        eventOptions: opts,
      };
      this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
      return 0;
    },
    _set_type_information(rtype, input, title, body = {}) {
      switch (rtype) {
        case "meeting":
        case "phone": {
          body.phone = input;
          break;
        }
        case "email": {
          body.email = input;
          break;
        }
        case "cc": {
          body.name = input;
          break;
        }
        case "vibrator": {
          body.vibrate = true;
          break;
        }
        case "code0":
        case "code1":
        case "code2":
        case "code3":
        case "code4": {
          if ("extra_codes" in body) {
            body.extra_codes.push({ name: rtype, value: input });
          } else {
            body.extra_codes = [{ name: rtype, value: input }];
          }
          break;
        }
        case "barcode":
          body = _.merge({}, body, this.$store.state.machine.barcode_result);
          break;
        case "name":
        case "prm":
        case "paper":
        default: {
          body.name = ASCIIFolder.fold(input).toUpperCase();
          body.title = title;
          body.use_paper = this.conditions.use_paper || false;
          break;
        }
      }
      return body;
    },
    register_customer(rtype, input, suborganization, lang, title, mycustomer) {
      // this.state.registered = true;

      if (!this._is_input_valid(input, rtype, suborganization)) {
        // this.state.showValidation = true;
        return -1; // Input not valid
      }

      if (rtype === "cc" && !this.conditions.no_cc_6 && input.length === 6) {
        // this.emitEvent(this.events.information_page);
        return -2;
      }

      if (rtype === "phone" || rtype === "meeting") {
        if (suborganization.sms.default_code !== "JP") {
          input = Phones.format_phone_number(
            input,
            suborganization.sms.default_code
          );
        }
      }

      const view_infos = this.$store.state.template.view_infos;
      const pid =
        view_infos.prequalification_path[
          view_infos.prequalification_path.length - 1
        ];
      const extra_information =
        "registration" in view_infos &&
        "extra_information" in view_infos.registration
          ? view_infos.registration.extra_information
          : [];
      const forced_desks =
        "registration" in view_infos &&
        "forced_desks" in view_infos.registration
          ? view_infos.registration.forced_desks
          : undefined;
      const prequals_queue =
        view_infos.registration &&
        view_infos.registration.prequalifications_queue &&
        view_infos.registration.prequalifications_queue.enabled
          ? view_infos.registration.prequalifications_queue
          : null;

      let body = {
        suborganization: `${suborganization._id}`,
        lang,
        machine: `${this.$store.state.infos.device._id}`,
        extra_information,
      };

      if (prequals_queue) {
        body.prequalifications_queue = prequals_queue;
      } else {
        body.forced_desks = forced_desks;
        body.prequalification = pid;
        body.prequalifications_path = view_infos.prequalification_path;
      }

      body = this._set_type_information(rtype, input, title, body);
      body = _.mergeWith({}, body, mycustomer, (objValue, srcValue) => {
        if (_.isArray(objValue)) {
          return objValue.concat(srcValue);
        }
      });
      this.$store.dispatch("register", {
        body,
        registration_type: rtype,
        prequalifications_queue_enabled: !!prequals_queue,
      });

      return 0;
      // this.state.sending = true;
    },
  },
};
