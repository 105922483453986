const Handlebars = require('handlebars');
const tzmoment = require('moment-timezone');
// TODO WARN Make the app totally crash... I don't know why
// const MomentHandler = require('handlebars.moment');
// MomentHandler.registerHelpers(Handlebars);
//

/**
 *
 * @example
 * ```html
 * <p>
 * {{binned_count value min=5 max=30 step=10
 *  prefixMin="less than " prefixMax="more than "
 *  prefix="between " betweenInterval=" and "}}
 *  </p>
 * ```
 *
 * In the above example, if value is 4, the function returns :
 * "less than 4", if the value is 31, the function returns : "more than 30",
 * otherwise the function computes an interval, for 10, the function returns
 * "between 5 and 15" if step is 10.
 */
Handlebars.registerHelper('binned_count', (count, options) => {
    const hash = options.hash;
    const min = hash.min;
    const max = hash.max;
    const step = hash.step || 10;
    const prefixMin = hash.prefixMin || '';
    const prefix = hash.prefix || '';
    const prefixMax = hash.prefixMax || '';
    const betweenInterval = hash.betweenInterval || '-';

    if (count < min) {
        return `${prefixMin}${min}`;
    } else if (count > max) {
        return `${prefixMax}${max}`;
    }

    if (step === 1) {
        return `${prefix}${count}`;
    }

    const absVal = Math.abs(count);
    const times = parseInt(absVal / step, 10);
    const higher = times * step + step;
    const lower = higher - step;
    return `${prefix}${lower}${betweenInterval}${higher}`;
});

Handlebars.registerHelper('join', (array, options) => {
    const hash = options.hash;
    const lastSep = hash.lastSep;
    const sep = hash.sep || ', ';

    if (lastSep) {
        if (array.length <= 2) {
            return array.join(lastSep);
        }
        const last = array[array.length - 1];
        return `${array.slice(0, -1).join(sep)}${lastSep}${last}`;
    }
    return array.join(sep);
});

Handlebars.registerHelper('pluralize', (count, options) => {
    const hash = options.hash;
    const sg = hash.singular;
    const pl = hash.plural;
    const zero = hash.zero || sg;

    if (count === 0) {
        return `${count} ${zero}`;
    } else if (count === 1) {
        return `${count} ${sg}`;
    }

    return `${count} ${pl}`;
});

Handlebars.registerHelper('now', (options) => {
    const hash = options.hash;
    const tz = hash.tz;
    const format = hash.format;
    return tzmoment().tz(tz).format(format);
});

module.exports = Handlebars;
