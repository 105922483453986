<template>
<div>
    <div class="steps">
        <div 
            v-for="step in Array(numberOfSteps).fill().map((_, i) => i)"
            :class="{'step-item': true, 'is-completed': state.current_step > step, 'is-active': step === state.current_step}"
        >
            <div class="step-marker">
                <slot 
                    name="step-marker" 
                    :id="step"
                    >
                    <span v-if="step < state.current_step"><i class="fa fa-check"></i></span>
                    <span v-else>{{step+1}}</span>
                </slot>
            </div>
            <div class="step-details">
                <p class="step-title">
                    <slot 
                        name="step-title" 
                        :id="step"
                    >
                    </slot>
                </p>
                <p>
                    <slot 
                        name="step-details" 
                        :id="step"
                    >
                    </slot>
                </p>
            </div>
        </div>
    </div>
    <div class="columns is-centered">
        <div class="column">
            <slot name="step-content" :id="state.current_step">

            </slot>
        </div>
    </div>
    <div class="columns is-centered">
        <div class="column">
            <slot name="step-buttons" :go="go" :step="state.current_step" :previous="previous" :next="next" :number-of-steps="numberOfSteps">
            </slot>
        </div>
    </div>
</div>
</template>

<script>
    module.exports = require('./Stepper');
</script>
