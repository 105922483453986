const Messages = require('../../../../../api/messages');
const EventType = require('../../../../../enums/event_types');

module.exports = {
    data() {
        return {
            state: {
                meeting_step: {
                    next: 1,
                    prev: -1,
                    current: 0,
                },
            },
        };
    },
    methods: {
        meeting_step_change(info) {
            const curr = this.state.meeting_step.current;
            this.state.meeting_step.next = info.next_step;
            this.state.meeting_step.prev = info.prev_step;
            this.state.meeting_step.current = info.step;

            const payload = {
                eventType: EventType.ALL,
                event: '',
                eventValue: 'NA',
                eventOptions: {},
                commit: this.$store.commit,
            };

            if (curr === this.variables.number_of_steps) {
                payload.event = 'finish';
            } else {
                payload.event = `step_${info.step}`;
            }
            this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
        },
    },
};
