const _ = require('lodash');
const Vuex = require('vuex');
const Rx = require('rxjs');
const RxOp = require('rxjs/operators');
const MasterWidget = require('../../../../mixins/master_widget/MasterWidget');
const Messages = require('../../../../../../api/messages');
const EventType = require('../../../../../../enums/event_types');

module.exports = {
    mixins: [MasterWidget],
    data() {
        return {
            state: {
                end: false,
                hasNextCustomer: false,
                hasCurrentCustomer: false,
                showCancel: false,
                allowCancellation: false,
                endEachCustomer: false,
                endCustomAction: false,
                customActionsEnabled: false,
                countDown: 3,
                renderedAction: 'NEXT',
                buttonsActivated: true,
                allowVendorsSelection: false,
            },
            observables: {
            },
        }
    },
    methods: {
        onEndOrNextCustomer(action, actionId, actionValue) {
            const { _id = null } = this.desk_customer || {};
            const { selectedVendor } = this.view_infos;
            const payload = {
                desk_custom_action: {
                    customer: _id,
                    action: {
                        id: actionId,
                        value: actionValue,
                    },
                },
                vendor: selectedVendor,
            }
            
            if(actionId != null){
                this.createCountDownObservable(action, payload);
            } else {
                if(selectedVendor){
                    this.createCountDownObservable(action, { vendor: selectedVendor }); 
                } else {
                    this.createCountDownObservable(action); 
                }
            }
        },
        onEndCustomer(actionId, actionValue) {
            if(!this.state.hasCurrentCustomer) {
                return; 
            }
            this.onEndOrNextCustomer('end_call', actionId, actionValue);
            
        },
        onNextCustomer(actionId, actionValue) {
            if(!this.state.hasNextCustomer) {
                return;
            }
            this.onEndOrNextCustomer('next', actionId, actionValue);
        },
        onSelectVendor() {
            this.onEmittedEvent('vendors', 'NA', { selectedVendorOpen: 'in_progress' }); 
        },
        onCancelAction() {
            this.observables.cancel$.next(true); 
        }, 
        onEmittedEvent(event, value = 'NA', opts = {}) {
            const payload = {
                commit: this.$store.commit,
                eventType: EventType.ALL,
                event,
                eventValue: value,
                eventOptions: opts,
            };
            this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
        },
        createCountDownObservable(action, payload){
            this.state.buttonsActivated = false;
            let observable$ = null;
            if(this.state.allowCancellation){
                this.state.showCancel = true;
                const timer$ = Rx.timer(this.state.countDown * 1000 + 1000); 
                observable$ = Rx.interval(1000).pipe(
                    RxOp.map(() => ({ name: 'next-or-cancel' })),
                    RxOp.takeUntil(Rx.merge(this.observables.cancel$, timer$)),
                );
            } else {
                observable$ = Rx.of(true).pipe(
                    RxOp.map(() => {
                        this.state.countDown = 0;
                        return { name: 'next-or-cancel' }
                    }),
                );
            }

            const timer$ = Rx.timer(3000).pipe(
                RxOp.startWith(false),
                RxOp.map((value) => ({name: 'timer', value}))
            )

            observable$.subscribe(() => {
                this.state.countDown -= 1; 
                if(this.state.countDown <= 0){
                    this.state.showCancel = false;
                    this.$store.dispatch(action, payload); 
                    this.view_infos.selectedVendor = null;
                }
            }, null, () => {
                const { cancel_time = 3 } = this.variables;
                this.state.showCancel = false;
                this.state.countDown = cancel_time;

                timer$.subscribe((obj) => {
                    const { name, value } = obj;
                    this.state.buttonsActivated = value;
                }, null, () => this.state.buttonsActivated = true);
            });
        },
        subscribe() {
            const { waitingCustomers$, deskCustomer$, countDown$ } = this.observables;

            waitingCustomers$.subscribe((waiting) => {
                this.state.end = this.desk_customer && waiting.length === 0;
                this.state.hasNextCustomer = waiting.length > 0;
                if(this.state.end || (this.state.hasCurrentCustomer && this.state.endEachCustomer)){
                    this.state.renderedAction = 'END';
                } else {
                    this.state.renderedAction = 'NEXT';
                }
            });
            deskCustomer$.subscribe((customer) => {
                this.state.end = customer && this.waiting_customers.length === 0;
                this.state.hasCurrentCustomer = customer != null;
                if(this.state.end){
                    this.state.renderedAction = 'END';
                } else if(customer && this.state.endEachCustomer){
                    this.state.renderedAction = 'END'
                } else {
                    this.state.renderedAction = 'NEXT';
                }
            });
        },
    },
    watch: {
        waiting_customers(nw) {
            this.observables.waitingCustomers$.next(nw);
        },
        desk_customer(nd) {
            this.observables.deskCustomer$.next(nd); 
        },
    },
    computed: _.merge({
        showNextButton: function() {
            return !this.state.allowVendorsSelection
                || (this.state.allowVendorsSelection && this.view_infos.selectedVendor);
        },
    }, Vuex.mapGetters(['waiting_customers', 'desk_customer', 'view_infos'])),
    mounted() {
        const { cancel_time = 3 } = this.variables;
        const { 
            allow_cancel_action = false, 
            end_custom_action_customer = false,
            custom_actions_enabled = false,
            allow_vendors_selection = false,
        } = this.conditions;
        const { end_each_customer = false } = this.$store.state.infos.suborganization;

        //Initialize state
        this.state.hasNextCustomer = this.desk_customer != null;
        this.state.end = this.desk_customer && this.waiting_customers.length === 0;
        this.state.countDown = cancel_time;
        this.state.allowCancellation = allow_cancel_action;
        this.state.endEachCustomer = end_each_customer;
        this.state.endCustomAction = end_custom_action_customer;
        this.state.customActionsEnabled = custom_actions_enabled; 
        this.state.allowVendorsSelection = allow_vendors_selection;

        //Create observables and subscribe
        const waitingCustomers$ = new Rx.BehaviorSubject(this.waiting_customers);
        const deskCustomer$ = new Rx.BehaviorSubject(this.desk_customer);
        const countDown$ = new Rx.BehaviorSubject(this.state.countDown);
        const end$ = new Rx.Subject();
        const next$ = new Rx.Subject();
        const cancel$ = new Rx.Subject().pipe(
            RxOp.debounceTime(250)
        );

        this.observables = { 
            waitingCustomers$, 
            deskCustomer$, 
            countDown$, 
            cancel$,
            end$,
            next$
        };
        this.subscribe();

        const {selectedVendorOpen} = this.view_infos;
        if(selectedVendorOpen === 'done'){
            this.onNextCustomer(null, null);
        }
    },
    beforeDestroy() {
        _.forEach(this.observables, (obs) => {
            obs.unsubscribe();
        }); 
    }
};
