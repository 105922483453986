<template>
<div class="container is-fluid screen-footer-static">
    <div class="columns is-centered">
        <div class="column has-text-centered" v-html="variables.content"> 
        </div>
    </div>
</div>
</template>

<script>
    module.exports = require('./StaticFooter');
</script>
