<template>
  <div v-if="variables.type === 'TB'">
    <div class="flex h-half-screen">
      <template v-if="variables.iframe1 != null && variables.iframe1 !== ''">
        <iframe
          v-if="!conditions.embed_frame1_as_video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          frameborder="0"
          allowfullscreen=""
          class="w-full"
          :src="variables.iframe1"
        >
        </iframe>
        <video
          v-else
          :src="variables.iframe1"
          muted
          autoplay
          loop
          class="w-full"
        ></video>
      </template>
      <div v-else>Please provide iframe1</div>
    </div>
    <div class="flex h-half-screen">
      <template v-if="variables.iframe2 != null && variables.iframe2 !== ''">
        <iframe
          v-if="!conditions.embed_frame2_as_video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          class="w-full"
          frameborder="0"
          allowfullscreen=""
          :src="variables.iframe2"
        >
        </iframe>
        <video
          v-else
          :src="variables.iframe2"
          muted
          autoplay
          loop
          class="w-full"
        ></video>
      </template>
      <div v-else>Please provide iframe2</div>
    </div>
  </div>
  <div v-else-if="variables.type === 'LR'" class="flex">
    <div class="flex w-half-screen h-screen">
      <template v-if="variables.iframe1 != null && variables.iframe1 !== ''">
        <iframe
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          frameborder="0"
          allowfullscreen=""
          class="h-full w-full"
          :src="variables.iframe1"
          v-if="!conditions.embed_frame1_as_video"
        >
        </iframe>
        <video
          v-else
          :src="variables.iframe1"
          muted
          autoplay
          loop
          class="w-full h-full"
        ></video>
      </template>
      <div v-else>Please provide iframe1</div>
    </div>
    <div class="flex w-half-screen h-screen">
      <template v-if="variables.iframe2 != null && variables.iframe2 !== ''">
        <iframe
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          frameborder="0"
          allowfullscreen=""
          class="h-full w-full"
          :src="variables.iframe2"
          v-if="!conditions.embed_frame2_as_video"
        >
        </iframe>
        <video
          v-else
          :src="variables.iframe2"
          muted
          autoplay
          loop
          class="w-full h-full"
        ></video>
      </template>
      <div v-else>Please provide iframe2</div>
    </div>
  </div>
</template>

<script>
module.exports = require("./SplitScreen");
</script>
