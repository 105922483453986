<template>
<stepper 
    :current-step="1" 
    :number-of-steps="variables.number_of_steps || 1" 
    style="width:100%"
    @step-change="meeting_step_change"
>
    <template slot="step-title" slot-scope="props">
        {{texts.step[lang]}} {{props.id+1}}
    </template>
    <template slot="step-details" slot-scope="props">
    <!--{{texts['step_detail_' + props.id][lang]}}--> 
    </template>
    <template slot="step-content" slot-scope="props">
        <div class="columns is-centered">
            <div class="column is-8 has-text-centered">
                <h3 class="title is-3">{{texts.select_datetime[lang]}}</h3>
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column is-8-desktop">
                <div class="card">
                    <div class="card-content">
                        <div class="content">
                            <div class="columns is-centered">
                                <div class="column is-6">
                                    <b-datepicker 
                                        inline 
                                        :unselectable-days-of-week="[0, 6]"
                                        :first-day-of-week="variables.first_day_of_week"
                                        :month-names="month_names"
                                        :day-names="day_names"
                                    >
                                    </b-datepicker>
                                </div>
                                <div class="column is-6">
                                    <div class="columns is-mobile">
                                        <div class="column is-4">
                                            <p><span class='button' href='#'>09:30</span></p>
                                            <p><span class='button' href='#'>10:00</span></p>
                                            <p><span class='button' href='#'>10:30</span></p>
                                            <p><span class='button is-success' href='#'>11:00</span></p>
                                            <p><span class='button' href='#'>11:30</span></p>
                                            <p><span class='button' href='#'>12:00</span></p>
                                            <p><span class='button' href='#'>12:30</span></p>
                                        </div>
                                        <div class="column is-4">
                                            <p><span class='button' href='#'>13:30</span></p>
                                            <p><span class='button' href='#'>14:00</span></p>
                                            <p><span class='button' href='#'>14:30</span></p>
                                            <p><span class='button' href='#'>15:00</span></p>
                                            <p><span class='button' href='#'>15:30</span></p>
                                            <p><span class='button' href='#'>16:00</span></p>
                                            <p><span class='button' href='#'>16:30</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template slot="step-buttons" slot-scope="props">
        <div class="columns is-centered">
            <div class="column is-8-desktop">
                <div class="field is-grouped is-pulled-right">
                    <div class="control" v-if="props.step > 0">
                        <button @click.prevent="props.previous" class="button is-medium is-primary">{{texts.prev[lang]}}</button>
                    </div>
                    <div class="control" v-if="props.step < props.numberOfSteps-1">
                        <button @click.prevent="props.next" class="button is-medium is-info">{{texts.next[lang]}}</button>
                    </div>
                    <div class="control" v-else>
                        <button @click.prevent="props.next" class="button is-medium is-info">{{texts.finish[lang]}}</button>
                    </div>
                </div>
            </div>
        </div>
    </template>
</stepper>
</template>

<script>
module.exports = require('./MeetingDatetime');
</script>
