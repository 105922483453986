<template>
  <div class="container is-fluid screen-logo-header container-top">
    <div class="columns is-mobile">
      <div class="column is-6 is-offset-6">
        <div class="is-flex-right logo-container" v-if="variables.logo !== ''">
          <img class="logo" :src="variables.logo" alt="Logo" title="Logo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
module.exports = require("./LogoHeader");
</script>
