<template>
    <div v-if="state.loading">

    </div>
    <div class="columns is-centered is-fullwidth is-paddingless is-marginless" v-else>
        <div class="column is-10-desktop is-full is-paddingless is-marginless">
            <div class="columns is-centered">
                <div class="column">
                    <div class="is-h-centered">
                        <a
                            class="button is-large is-outlined is-marginless"
                            @click.prevent="onCancelClicked"
                            v-html="$text('cancel', lang)"
                        ></a>
                    </div>
                </div>
            </div>
            <div class="columns is-centered">
                <div class="column is-full">
                    <div class="control has-icons-left has-icons-right">
                        <input :value="state.search" @input="onInputChanged" class="input" type="text" :placeholder="$text('search_vendor', lang)">
                        <span class="icon is-small is-right">
                            <i class="fa fa-search"></i>
                        </span>
                    </div> 
                </div>
            </div>
            <div class="columns is-centered">
                <div class="column is-full">
                    <h3><strong>{{$text('recent_vendors', lang)}}</strong></h3>
                    <hr class="is-marginless" />
                </div>
            </div>
            <div 
                class="columns is-centered is-paddingless is-marginless is-multiline" 
            >
                <div 
                    class="column is-4-desktop"
                    v-for="vendor in recentVendors" 
                    :key="`recent-${vendor.key}`"
                >
                    <div class="card vendor-card"
                       @click.prevent="onVendorSelected(vendor)"
                    >
                        <div class="card content">
                            <article class="media has-xs-padding">
                                <div class="media-left">
                                    <p class="image is-64x64">
                                        <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png" />
                                    </p>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <strong>{{vendor.value}}</strong>
                                        <h6><span class="tag is-info">{{vendor.key}}</span></h6>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns is-centered">
                <div class="column is-full">
                    <h3><strong>{{$text('other_vendors', lang)}}</strong></h3>
                    <hr class="is-marginless"/>
                </div>
            </div>
            <div class="columns is-centered is-paddingless is-marginless is-multiline"> 
                <div 
                    class="column is-4-desktop"
                    v-for="vendor in otherVendors" 
                    :key="`other-${vendor.key}`"
                >
                    <div class="card vendor-card"
                       @click.prevent="onVendorSelected(vendor)"
                    >
                        <div class="card content">
                            <article class="media has-xs-padding">
                                <div class="media-left">
                                    <p class="image is-64x64">
                                        <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png" />
                                    </p>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <strong>{{vendor.value}}</strong>
                                        <h6><span class="tag is-info">{{vendor.key}}</span></h6>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    module.exports = require('./VendorSelector');
</script>
