module.exports = {
    name: 'Key',
    props: {
        space: { default: '<i class="h5 fa fa-keyboard-o" />' },
        backspace: { default: '<i class="h5 fa fa-arrow-left" />' },
        enter: { default: '<i class="h5 fa fa-check-square" />' },
        primaryColor: { default: null },
        digit: { default: ' ' },
        onKeyChange: {},
    },
    data() {
        return {
        };
    },
    methods: {
        keyPressed(digit, e, longtap) {
            e.stopPropagation();
            this.onKeyChange(digit, e, longtap || false);
        },
        tapPressed(digit) {
            return e => this.keyPressed(digit, e);
        },
        swipePressed(digit) {
            return (dir, e) => this.keyPressed(digit, e);
        },
        longtapPressed(digit) {
            return e => this.keyPressed(digit, e, true);
        },
    },
    computed: {
        css() {
            if (this.primaryColor != null && this.primaryColor !== '') {
                return {
                    backgroundColor: this.primaryColor,
                    color: '#FFF',
                };
            }
            return {};
        },
    },
};
