const Vuex = require('vuex');
const _ = require('lodash');
const EventHub = require('../../../../../store/event_hub');
const Messages = require('../../../../../api/messages');
const FuncUtils = require('../../../../../utils/functional');
const EventType = require('../../../../../enums/event_types');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');

module.exports = {
    mixins: [MasterWidget],
    data() {
        return {
            headers: [],
            headers_subheaders: [],
            state: {
                interval: null,
                items: [],
                current: 0,
                condition: j => j % 2 === 0,
            },
        };
    },
    methods: {
        click_on_screen(e) {
            e.preventDefault();
            const payload = {
                commit: this.$store.commit,
                eventType: EventType.ALL,
                event: this.events.next,
            };

            this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
        },
        _compute_items() {
            switch (this.variables.item_type) {
            case 'header':
                return this.headers;
            case 'image':
                return this.images;
            default:
            case 'headers+subheaders':
                return this.headers_subheaders;
            }
        },
        _compute_headers() {
            const texts = this.$store.state.infos.template.pages[this.$store.state.template.currentPage][this.type].view_texts;
            const elts = texts.headers[this.lang].split(this.variables.separator);
            return elts.map(headers => headers.split(this.variables.text_separator));
        },
        _compute_headers_and_subheaders() {
            const texts = this.$store.state.infos.template.pages[this.$store.state.template.currentPage][this.type].view_texts;
            const sep = this.variables.separator;
            const text_sep = this.variables.text_separator;
            const mix = this.conditions.mix;
            const headers = texts.headers[this.lang].split(sep);
            const subheaders = texts.subheaders[this.lang].split(sep);

            const zipped = FuncUtils.zip(headers, subheaders);
            return zipped.map((elt) => {
                let [hs, shs] = elt;
                let items = [];
                hs = hs.split(text_sep);
                shs = shs.split(text_sep);
                if (mix) {
                    items = _.flatten(FuncUtils.zipLongest(undefined, hs, shs))
                        .filter(e => e !== undefined);
                    this.state.condition = j => j % 2 === 0;
                } else {
                    items = [...hs, ...shs];
                    this.state.condition = j => j < hs.length;
                }
                return items;
            });
        },
    },
    watch: {
        global_waiting() {
            this.headers = this._compute_headers();
            this.headers_subheaders = this._compute_headers_and_subheaders();
            this.state.items = this._compute_items();
            this.$forceUpdate();
        },
    },
    computed: _.merge({
        images() {
            return this.variables.images.split(this.variables.separator);
        },
    }, Vuex.mapGetters(['global_waiting'])),
    mounted() {
        this.headers = this._compute_headers();
        this.headers_subheaders = this._compute_headers_and_subheaders();
        this.state.items = this._compute_items();

        this.state.interval = setInterval(() => {
            this.state.current = this.state.current < this.state.items.length - 1 ?
                this.state.current + 1 : 0;
        }, this.variables.duration);

        EventHub.$on('meta-click-on-screen', (e) => {
            this.click_on_screen(e);
        });
    },
    beforeDestroy() {
        if (this.state.interval != null) {
            clearInterval(this.state.interval);
        }
        EventHub.$off('meta-click-on-screen');
    },
};
