<template>
  <div class="container is-fluid prequalification container-top">
    <div class="columns" v-if="!no_reachable_prequalifications">
      <div class="column">
        <div v-for="item in headers">
          <h4 class="header h3-portrait" v-html="item"></h4>
        </div>
        <div v-for="item in subheaders">
          <h4 class="subheader h3-portrait" v-html="item"></h4>
        </div>
      </div>
      <div class="column logo-container" v-if="variables.logo !== ''">
        <img class="logo" :src="variables.logo" alt="Logo" title="Logo" />
      </div>
    </div>
  </div>
</template>

<script>
module.exports = require("./PrequalificationHeader");
</script>
