function get_ws_url(location) {
    let protocol = 'ws';
    if (location.protocol === 'https:') {
        protocol = 'wss';
    }
    return `${protocol}://${location.hostname}${location.port ? `:${location.port}` : ''}`;
}

function parse_url(url) {
    let parser = document.createElement('a'),
        searchObject = {},
        queries,
        split,
        i;
    // Let the browser do the work
    parser.href = url;
    // Convert query string to object
    queries = parser.search.replace(/^\?/, '').split('&');
    for (i = 0; i < queries.length; i++) {
        split = queries[i].split('=');
        searchObject[split[0]] = split[1];
    }
    return {
        protocol: parser.protocol,
        host: parser.host,
        hostname: parser.hostname,
        port: parser.port,
        pathname: parser.pathname,
        search: parser.search,
        searchObject,
        hash: parser.hash,
    };
}

module.exports = {
    get_ws_url,
    parse_url,
};
