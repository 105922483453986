<template>
<div class="container is-fluid landing">
    <div class="columns is-fullwidth landing__columns-image" v-if="$variable('image')">
        <div class="column is-8 is-offset-2 landing__image-container">
            <img :src="$variable('image')" alt="Store" title="Image" />
        </div>
    </div>
    <div class="is-fullwidth landing__columns-text">
        <div class="columns is-fullwidth landing__columns-title">
            <div class="column is-8 is-offset-2 landing__title-container is-text-secondary">
                <h1 class="h2-portrait">{{$text('title')}}</h1>
            </div>
        </div>
        <div class="columns is-fullwidth landing__columns-subtitle">
            <div class="column is-8 is-offset-2 landing__subtitle-container is-text-secondary">
                <h4 class="h5-portrait">{{$text('subtitle')}}</h4>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    module.exports = require('./Landing');
</script>
