<template>
    <div class="hero is-fullheight">
        <div id="middle" class="hero-body">
            <div class="container has-text-centered">
                <div class="columns is-centered">
                    <div class="column is-6-widescreen is-8-tablet is-12-mobile">
                        <div class="login-brand has-text-centered has-sm-bottom-margin is-vertically-centered">
                            <img src="/public/back/imgs/logo-FV.png" alt="FileVirtuelle" title="FileVirtuelle" width="32px" />
                            <strong class="brand-color">File</strong><strong class="brand-black">Virtuelle</strong>
                        </div>
                        <div class="box">
                            <form>
                                <div class="field">
                                    <div class="control">
                                        <input class="input" type="email" placeholder="Your Email" autofocus="" v-model="state.email "/>
                                        <p v-if="!state.validated" class="has-text-danger is-size-6">The email address is incorrect</p>
                                    </div>
                                </div>

                                <div class="field">
                                    <div class="control">
                                        <input class="input" type="password" placeholder="Your Password" v-model="state.password" />
                                        <p v-if="!state.validated" class="has-text-danger is-size-6">The password is incorrect</p>
                                    </div>
                                </div>
                                <button class="button is-block is-fv is-fullwidth" @click.prevent="login">Login</button>
                            </form>
                        </div>
                        <!--<p class="has-text-grey">
                        <a href="../">Sign Up</a> &nbsp;·&nbsp;
                        <a href="../">Forgot Password</a> &nbsp;·&nbsp;
                        <a href="../">Need Help?</a>-->
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>

    <script>
module.exports = require('./Account');
</script>
