<template>
  <div class="container is-fluid screen-header">
    <div class="columns">
      <div class="column">
        <div v-for="header in headers">
          <p class="header h1" v-html="header" />
        </div>
        <div v-for="subheader in subheaders">
          <p class="subheader h1" v-html="subheader" />
        </div>
      </div>
      <div
        class="column logo-container is-flex-right"
        v-if="variables.logo !== '' && variables.logo != null"
      >
        <img class="logo" :src="variables.logo" alt="Logo" title="Logo" />
      </div>
    </div>
  </div>
</template>

<script>
module.exports = require("./ScreenHeader");
</script>
