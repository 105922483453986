const _ = require('lodash');
const Utils = require('../../../../utils/utils');
const EventType = require('../../../../enums/event_types');
const Messages = require('../../../../api/messages');

module.exports = {
    data() {
        return {
            state: {
                prequalificationKeys: this.computeKeys([]),
                depth: 0,
                path: [],
            },
        };
    },
    methods: {
        computeKeys(path) {
            const prequalifications = this.$store.state.infos.prequalifications;
            let keys = [];
            if (path.length === 0) {
                keys = Object.keys(prequalifications)
                    .filter(key => prequalifications[key].root);

                // Try to find the first root prequalification by walking up the tree
                if (keys.length === 0) {
                    const prequalifications_with_parents =
                        new Set(_.flatten(Object.keys(prequalifications)
                            .map(key => (prequalifications[key].prequalifications || []))));

                    keys = Object.keys(prequalifications)
                    .filter(key => !prequalifications_with_parents.has(key));
                }
            } else {
                const key = path[path.length - 1];
                const prequalification = prequalifications[key];
                keys = prequalification.prequalifications;
            }

            keys.sort((a, b) => (prequalifications[a].machine_order
                - prequalifications[b].machine_order));
            return keys;
        },
        trigger_payload(paths, custom_trigger = null, multi = false) {
            if (!paths || paths.length === 0) {
                return;
            }

            const payload = {
                eventType: EventType.ALL,
                event: 'none',
                eventValue: 'NA',
                eventOptions: {},
                commit: this.$store.commit,
            };

            if (multi) {
                payload.eventType = EventType.PREQUAL;
                payload.event = custom_trigger;
                payload.eventOptions = { depth: 0,
                    prequalification_path: paths[0],
                    registration: {
                        prequalifications_queue: {
                            enabled: true,
                            items: paths.map(p => p[p.length - 1]),
                            paths,
                            calling: this.variables.queue_calling || 'in_order',
                            enqueue: this.variables.queue_enqueue || 'lazy',
                        },
                    } };
            } else if (custom_trigger && custom_trigger.trim() !== '') {
                payload.eventType = EventType.PREQUAL;
                payload.event = custom_trigger.trim();
                payload.eventOptions = { depth: paths[0].length || 0,
                    prequalification_path: paths[0],
                    registration: {} };
            } else {
                payload.eventOptions = { depth: paths[0].length,
                    prequalification_path: paths[0],
                    registration: {} };
            }

            this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
        },
        click_prequalification(key, e) {
            try {
                e.preventDefault();
                e.stopPropagation();
            } catch (err) {} // eslint-disable-line

            if (this.unreachable_prequalifications.has(key)) {
                return;
            }

            const prequalification = this.$store.state.infos.prequalifications[key];
            const path = this.state.path.slice();
            path.push(key);

            if (prequalification.prequalifications
                && prequalification.prequalifications.length > 0) {
                const new_keys = prequalification.prequalifications;
                const prequals = this.$store.state.infos.prequalifications;
                new_keys.sort((a, b) => prequals[a].machine_order > prequals[b].machine_order);
                this.state.depth += 1;
                this.state.prequalificationKeys = new_keys;
                this.state.path = path;
                this.trigger_payload([path], null, false);
            } else {
                const trigger = prequalification.trigger ?
                    prequalification.trigger.trim() : this.events.next_prequal;
                this.trigger_payload([path], trigger, false);
            }
        },
    },
    watch: {
    },
    computed: {
        orientation() {
            const keys = this.state.prequalificationKeys;
            if (keys.length === 0) {
                return '';
            }

            const key = keys.find((k) => {
                const prequalification = this.$store.state.infos.prequalifications[k];
                if (prequalification.orientation
                    && prequalification.orientation[this.lang]
                    && prequalification.orientation[this.lang].trim() !== '') {
                    return true;
                }
                return false;
            });

            if (key) {
                const orientation = this.$store.state.infos.prequalifications[key].orientation;
                return orientation[this.lang];
            }
            return '';
        },
        lang() {
            return this.$store.state.interfaceLang;
        },
        no_prequalifications_available() {
            return this.unreachable_prequalifications.size
                === Object.keys(this.$store.state.infos.prequalifications).length;
        },
        unreachable_prequalifications() {
            const { allow_close_suborganization, close } = 
                this.$store.state.infos.suborganization;
            const {prequalifications} = this.$store.state.infos;
            if (allow_close_suborganization && close) {
                return Utils.find_unreachable_prequalifications(
                    Object.keys(prequalifications), prequalifications
                );
            }
            return new Set();
        },
        is_suborganization_close() {
            const suborganization = this.$store.state.infos.suborganization;
            return suborganization.allow_close_suborganization && suborganization.close;
        },
    },
};
