<template>
<div class="card">
    <div class="card-header" @click="collapse">
        <div class="card-header-title has-text-centered">
            {{customer.display_desk}} ({{customer.meeting ? texts.meeting[lang]
            : texts.registered[lang]}} {{format_meeting(customer.rtimestamp)}})
        </div>
    </div>
    <div class="card-content" v-if="!state.collapsed">
        <div class="content h5">
            {{texts.ticket[lang]}} {{customer.ticket}}
            <br />
            {{customer.rsvp && customer.meeting ?
            texts.meeting_rsvp[lang] : ''}}
            <br />
            <span>{{texts.customer_path[lang]}}</span>
            <ol>
                <li v-for="id in customer.prequalifications_path">
                {{$store.state.infos.prequalifications[id] ? $store.state.infos.prequalifications[id].name[lang] : "?"}}
                </li>
            </ol>
            <br />
            <template v-if="texts.customer_file">
                <span>{{texts.customer_file[lang]}}</span>
                <ul>
                    <li v-if="customer.name">{{customer.name}}</li>
                    <li v-if="customer.phone">{{customer.phone}}</li>
                    <li v-if="customer.email">{{customer.email}}</li>
                    <template v-for="code, i in customer.extra_codes">
                        <li v-if="'name' in code && code.name in extra_codes" :key="i">{{extra_codes[code.name].name[lang]}} : {{code.value}}</li>
                    </template>
                </ul>
            </template>
            <template v-if="texts.extra_information && customer.extra_information && customer.extra_information.length > 0">
                <span>{{texts.extra_information[lang]}}</span>
                <ul>
                    <template v-for="code, i in customer.extra_information">
                        <li v-if="'name' in code && code.name in extra_codes" :key="i">{{extra_codes[code.name].name[lang]}} : {{code.value}}</li>
                    </template>
                </ul>
            </template>
            <span v-if="return_alert.activated && customer.type === 'phone'">
                {{format_visit(customer.visits, return_alert.days)}}
            </span>
        </div>
    </div>
    <footer 
        class="card-footer"
        v-if="show_footer"
    >
    <a v-if="show_pick_someone" 
        class="card-footer-item" 
        @click="pick_someone">{{texts.pick_someone[lang]}}</a>
    <a  v-if="show_remove_name"
        class="card-footer-item"
        @click="remove_customer_name(customer._id, $event)">{{texts.remove_name[lang]}}</a>
    </footer>
</div>
</section>
</template>

<script>
module.exports = require('./DeskCustomer');
</script>
