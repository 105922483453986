<template>
<div class="container is-fluid datetime-screen-header">
    <div class="columns">
        <div class="column is-4">
            <h2 class="time">{{state.current_datetime.format(variables.time_format)}}</h2>
        </div>
        <div class="column is-8">
            <h4 class="date">{{state.current_datetime.format(variables.date_format)}}</h4>
        </div>
    </div>
</div>
</template>

<script>
    module.exports = require('./DatetimeScreenHeader');
</script>
