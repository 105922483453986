module.exports = {
    data() {
        return {
        };
    },
    methods: {
        notify() {
            const device = this.$store.state.infos.device;
            const Notification = window.Notification;
            if (Notification && Notification.permission !== 'denied'
                && device.notifications && device.notifications.text
                && device.notifications.text.activated) {
                Notification.requestPermission((/* status*/) => {  // status is "granted", if accepted by user
                    new Notification(this.texts.notification_title[this.lang], {
                        body: this.texts.notification_body[this.lang],
                    });
                });
            }
        },
    },
    mounted() {
    },
    beforeDestroy() {
    },
    computed: {
    },
};
