<template>
<div @click="click_on_screen" class="slider is-fullwidth">
    <transition v-if="variables.item_type === 'image'" name="slider-animate">
          <div key="state.current" class="image is-16by9" >
            <img src="state.items[state.current]" alt="Slider" title="Slider image" />
          </div>
    </transition>
    <transition v-else-if="variables.item_type === 'header'" name="slider-animate">
        <div v-if="state.items && state.items.length > 0" :key="state.current">
            <div v-for="h in state.items[state.current]"
                v-if="h.trim() !== ''" v-html="h"
            >
            </div>
        </div>
    </transition>
    <transition v-else name="slider-animate">
        <div v-if="state.items && state.items.length > 0" :key="state.current">
            <div
                v-for="(h, j) in state.items[state.current]"
                v-if="h.trim() !== ''"  v-html="h"
            ></div>
        </div>
    </transition>
</div>
</template>

<script>
    module.exports = require('./Slider');
</script>
