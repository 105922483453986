const Config = require('../config/index');

const prefix = `${Config.api.public.prefix}/${Config.api.public.version}`;

module.exports = {
    device_information: `${prefix}/idevice/{type}/{mac}`,
    register: `${prefix}/register`,
    register_queue: `${prefix}/register_queue`,
    next: `${prefix}/next`,
    pick_someone: `${prefix}/pick_someone`,
    end_someone: `${prefix}/end_someone`,
    remove_name: `${prefix}/icustomer/remove_name`,
    desk_close: `${prefix}/idevice/close`,
    suborganization_close: `${prefix}/isuborganization/close`,
    redirection_information: (sid, did) =>
    `${prefix}/idevice/information/redirection/${sid}/${did}`,
    searchCustomers: s => `${prefix}/customers?search=${encodeURIComponent(s)}`,
    vendors: (type, subtype) => {
        const search = [`type=${type}`, `subtype=${subtype}`];
        return `${prefix}/keystores?search=${encodeURIComponent(search.join('&'))}`;
    },
    authenticate: `${prefix}/idevice/authenticate`,
};
