<template>
<div :class="`container is-fluid customer-information is-customer-information-${itype}`">
    <div class="columns">
        <div class="column is-10 is-offset-1">
            <div class="input-section">
                <h5 class="h4-portrait has-text-centered" v-html="texts.input_header[lang]" />
                    <h4 key="phone-input" class="h3-portrait input-form" v-html="state.input.trim().length > 0 ? state.input: state.placeholder"></h4>
                <h6 v-show="state.showValidation" class="input-alert h5-portrait`">
                    {{texts.input_error[lang]}}
                </h6>
            </div>
        </div>
    </div>
    <div class="columns is-centered">
        <div class="column is-10">
            <component :is="pad_component" v-bind="pad_component_options" />
            <h6 class="has-text-centered" v-html="texts.disclaimer[lang]"></h6>
        </div>
    </div>
</div>
</template>

<script>
    module.exports = require('./CustomerInformation');
</script>
