const _ = require('lodash');
const Rx = require('rxjs');
const RxOp = require('rxjs/operators')
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');
const APIRoutes = require('../../../../../api/routes');
const AuthUtils = require('../../../../../utils/auth');
const API = require('../../../../../api/index');
const EventType = require('../../../../../enums/event_types');
const Messages = require('../../../../../api/messages');

module.exports = {
    mixins: [MasterWidget],
    data() {
        return {
            state: {
                loading: false,
                numRecentVendors: 5,
                search: '',
                filteredVendors: []
            },
            observables: {
            
            }
        }
    }, 
    methods: {
        onVendorSelected(vendor) {
            const originalVendor = this.$store.state.desk.vendors.find((v) => v.key === vendor.key);
            if(originalVendor){
                originalVendor.times += 1;
            }
            this.onEmittedEvent('home', 'NA', { selectedVendor: _.clone(vendor), selectedVendorOpen: 'done' });
        },
        onCancelClicked() {
            this.onEmittedEvent('home', 'NA', { selectedVendorOpen: 'na' }); 
        },
        fetchVendors() {
            this.state.loading = true;
            const { token = {} } = this.$store.state.infos.device.auth;
            const { store_code = '' } = this.$store.state.infos.suborganization;
            const { keystore_type = '' } = this.variables;
            const path = APIRoutes.vendors(keystore_type, store_code);

            const payload = {
                commit: this.$store.commit,
                path,
                method: 'GET',
                signature: AuthUtils.get_api_headers(token, 'GET', path),
            };

            Rx.from(API.fetch(payload)).pipe(
                RxOp.map((response) => {
                    const {content = []} = response;
                    return _.map(content, (vendor) => {
                        vendor.times = 0;
                        return vendor;
                    });
                })
            ).subscribe((vendors) => {
                this.$store.state.desk.vendors = vendors;
                this.state.filteredVendors = vendors.slice();
                this.state.filteredVendors.sort((a, b) => (b.times - a.times));
            }, null, () => this.state.loading = false);
        },
        onInputChanged(e) {
            const { target: {value = ''} } = e;
            const { search$ } = this.observables;
            this.state.search = value;
            search$.next(value);
        },
        onEmittedEvent(event, value = 'NA', opts = {}) {
            const payload = {
                commit: this.$store.commit,
                eventType: EventType.ALL,
                event,
                eventValue: value,
                eventOptions: opts,
            };
            this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
        },
    },
    watch: {
        
    },
    computed: {
        recentVendors() {
            console.log(this.state.filteredVendors.slice(0, this.state.numRecentVendors));
            return this.state.filteredVendors.slice(0, this.state.numRecentVendors);
        },
        otherVendors() {
            console.log(this.state.filteredVendors.slice(this.state.numRecentVendors));
            return this.state.filteredVendors.slice(this.state.numRecentVendors);
        }
    },
    mounted() {
        const { vendors = [] } = this.$store.state.desk 
        
        this.observables.search$ = new Rx.Subject().pipe(
            RxOp.debounceTime(500)
        ).subscribe((value) => {
            console.log('search value', value);
            this.state.filteredVendors = this.$store.state.desk.vendors.slice().filter((vendor) => {
                    return vendor.value.match(new RegExp(value, 'i')) 
                        || vendor.key.match(new RegExp(value, 'i')); 
                });
            this.state.filteredVendors.sort((a, b) => (b.times - a.times));
        });

        if(vendors.length !== 0) {
            this.state.filteredVendors = vendors.slice();
            this.state.filteredVendors.sort((a, b) => (b.times - a.times));
            return; //Vendors already fetched
        }
        
        this.fetchVendors();
    },
    beforeDestroy() {
        _.forEach(this.observables, (obs) => obs.unsubscribe());
    }
}
