const PolygonDistanceUtils = require('../../utils/polygon_distance');

module.exports = {
    name: 'GenericPad',
    props: ['keyboard', 'onKeyChange'],
    data() {
        return {
            state: {
                keyboard: this.keyboard,
                polygons: [],
                keysDOM: [],
            },
        };
    },
    methods: {
        find_closest_key(e) {
            e.preventDefault();
            e.stopPropagation();
            const point = { x: e.clientX, y: e.clientY };
            const idx = PolygonDistanceUtils.get_closest_polygon(this.state.polygons, point);
            if (idx === -1) {
                return;
            }

            const classes = this.state.keysDOM[idx].className.split(' ');
            const key_class = classes.find(cls => cls.startsWith('key-'));
            if (!key_class) {
                return;
            }

            const key = key_class.replace(/key-/, '');
            if (key.trim() === '') {
                this.onKeyChange(' ', e);
            } else {
                this.onKeyChange(key.trim().toUpperCase(), e);
            }
        },
    },
    mounted() {
        this.state.keysDOM = Array.from(document.querySelectorAll('.keyboard-row > .key'));
        this.state.polygons = PolygonDistanceUtils.get_polygons_from_divs(this.state.keysDOM);
    },
};
