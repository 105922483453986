<template>
  <centered-box
    v-if="
      $store.state.error && $store.state.error_type !== 'unrecognized-device'
    "
  >
    <span class="has-text-centered">{{ errorText[1] }}</span>
  </centered-box>
  <centered-box
    v-else-if="
      $store.state.error && $store.state.error_type === 'unrecognized-device'
    "
  >
    <p>The device does not exist.</p>
    <p>Send an email to <emph>support@filevirtuelle.com</emph> containing:</p>
    <ol>
      <li>
        This id: <strong>{{ mac }}</strong>
      </li>
      <li>The type of device: machine, screen, desk</li>
      <li>The name of the device</li>
    </ol>
  </centered-box>
  <centered-box
    v-else-if="
      Object.keys($store.state.infos).length > 0 &&
        $store.state.infos.prequalifications.length === 0
    "
  >
    <span class="has-text-centered"
      >The device is not yet configured. It does not handle any
      prequalifications.</span
    >
  </centered-box>
  <div
    @click="click_on('screen', $event)"
    v-else-if="Object.keys($store.state.infos).length > 0"
    :class="`${$store.state.infos.suborganization.css_template}-template`"
  >
    <div
      :class="[
        'hero is-fullheight',
        `${$store.state.infos.device.type}-template`,
        `${$store.state.infos.device.type}-${$store.state.infos.suborganization._id}-template`,
        `page-${$store.state.template.page.name}`,
      ]"
    >
      <div
        id="header"
        :class="`hero-head ${component_name('header')}`"
        @click="click_on('header', $event)"
      >
        <component
          type="header"
          :is="$store.state.template.page.header_view"
        ></component>
      </div>
      <div
        id="middle"
        :class="`hero-body ${component_name('middle')}`"
        @click="click_on('middle', $event)"
      >
        <component
          type="middle"
          :is="$store.state.template.page.middle_view"
        ></component>
      </div>
      <div
        id="footer"
        :class="`hero-foot ${component_name('footer')}`"
        @click="click_on('footer', $event)"
      >
        <component
          type="footer"
          :is="$store.state.template.page.footer_view"
        ></component>
      </div>
    </div>
  </div>
  <loader v-else />
</template>

<script>
module.exports = require("./Meta");
</script>
