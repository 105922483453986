const Vue = require('vue');
const Router = require('vue-router');
const Home = require('./pages/home/Home.vue');
const Meta = require('./pages/meta/Meta.vue');
const Queue = require('./pages/queue/Queue.vue');
const Account = require('./pages/account/Account.vue');

Vue.use(Router);

module.exports = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
        },
        {
            path: '/queue/:sid',
            name: 'Queue',
            component: Queue,
            props: true,
        },
        {
            path: '/:type/:mac',
            name: 'Device',
            component: Meta,
            props: true,
        },
        {
            path: '/account',
            name: 'Account',
            component: Account,
            props: true,
        },
    ],
});
