// const Vue = require('Vue');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');

module.exports = {
    mixins: [MasterWidget],
    data() {
        return {
            state: {
            },
        };
    },
};
