const Enum = require('enumify').Enum;

class EventType extends Enum {
    toString() {
        return this.name.toLowerCase();
    }
}
EventType.initEnum(['ALL', 'HEADER', 'MIDDLE', 'FOOTER', 'PREQUAL']);

module.exports = EventType;
