<template>
    <div class="container is-fluid registration container-top">
        <div class="columns is-mobile">
            <div class="column is-6 is-offset-6">
                <div 
                    v-html="state.datetime.format(variables.datetime_format)" 
                    class="has-text-centered h2 is-capitalized is-portrait has-small-margins is-text-blue datetime">
                </div>
                <div class="is-pulled-right logo-container" v-if="variables.logo !== ''">
                    <img class="logo" :src="variables.logo" alt="Logo" title="Logo" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    module.exports = require('./SliderHeader');
</script>
