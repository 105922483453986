<template>
<stepper 
    :current-step="2" 
    :number-of-steps="variables.number_of_steps || 1" 
    style="width:100%"
    @step-change="meeting_step_change"
>
    <template slot="step-title" slot-scope="props">
        {{texts.step[lang]}} {{props.id+1}}
    </template>
    <template slot="step-details" slot-scope="props">
    <!--{{texts['step_detail_' + props.id][lang]}}--> 
    </template>
    <template slot="step-content" slot-scope="props">
        <div class="columns is-centered">
            <div class="column is-8 has-text-centered">
                <h3 class="title is-3">{{texts.fill_in_information[lang]}}</h3>
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column is-8-desktop">
                <div class="card">
                    <div class="card-content">
                        <div class="content">
                            <div class="columns is-centered">
                                <div class="column is-6">
                                    <div class="field">
                                        <label class="label" v-html="texts.firstname[lang]"></label>
                                        <div class="control">
                                            <input class="input" type="text" :placeholder="texts.firstname_placeholder[lang]">
                                        </div>
                                    </div>
                                    <div class="field">
                                        <label class="label" v-html="texts.lastname[lang]"></label>
                                        <div class="control">
                                            <input class="input" type="text" :placeholder="texts.lastname_placeholder[lang]">
                                        </div>
                                    </div>
                                    <div class="field">
                                        <label class="label" v-html="texts.email[lang]"></label>
                                        <div class="control">
                                            <input class="input" type="email" :placeholder="texts.email_placeholder[lang]">
                                        </div>
                                    </div>
                                    <div class="field">
                                        <label class="label" v-html="texts.phone[lang]"></label>
                                        <div class="control">
                                            <input class="input" type="phone" :placeholder="texts.phone_placeholder[lang]">
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-6">
                                    <div class="field">
                                        <label class="label" v-html="texts.address[lang]"></label>
                                        <div class="control">
                                            <input class="input" type="text" :placeholder="texts.address_placeholder[lang]">
                                        </div>
                                    </div>
                                    <div class="field">
                                        <label class="label" v-html="texts.zipcode[lang]"></label>
                                        <div class="control">
                                            <input class="input" type="text" :placeholder="texts.zipcode_placeholder[lang]">
                                        </div>
                                    </div>
                                    <div class="field">
                                        <label class="label" v-html="texts.city[lang]"></label>
                                        <div class="control">
                                            <input class="input" type="text" :placeholder="texts.city_placeholder[lang]">
                                        </div>
                                    </div>
                                    <div class="field">
                                        <label class="label" v-html="texts.notes[lang]"></label>
                                        <div class="control">
                                            <textarea class="textarea" :placeholder="texts.notes_placeholder[lang]"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template slot="step-buttons" slot-scope="props">
        <div class="columns is-centered">
            <div class="column is-8-desktop">
                <div class="field is-grouped is-pulled-right">
                    <div class="control" v-if="props.step > 0">
                        <button @click.prevent="props.previous" class="button is-medium is-primary">{{texts.prev[lang]}}</button>
                    </div>
                    <div class="control" v-if="props.step < props.numberOfSteps-1">
                        <button @click.prevent="props.next" class="button is-medium is-info">{{texts.next[lang]}}</button>
                    </div>
                    <div class="control" v-else>
                        <button @click.prevent="props.next" class="button is-medium is-info">{{texts.finish[lang]}}</button>
                    </div>
                </div>
            </div>
        </div>
    </template>
</stepper>
</template>

<script>
module.exports = require('./MeetingInformation');
</script>
