<template>
<div v-if="registration && registration.post_registration" :class="`container is-fluid registration is-registration-${rtype} post-registration`">
    <div v-if="state.post_registration_image">
        <div class="is-portrait columns is-centered">
            <div class="column is-12">
              <div class="img is-16by9 has-text-centered">
                <img :src="`${this.variables.success_image}`" title="Store" />
              </div>
            </div>
        </div>
        <div class="is-landscape columns is-centered">
            <div class="column is-5">
              <div class="img is-16by9 has-text-centered">
                <img :src="`${this.variables.success_image}`" title="Store" />
              </div>
            </div>
        </div>
    </div>
    <div class="columns">
        <div class="column is-10 is-offset-1">
            <h3 class="h2-portrait has-text-centered" v-html="state.message" />
            </div>
        </div>
        <div class="columns has-margin-top">
            <div class="column is-10 is-offset-1">
                <h5 class="h4-portrait has-text-centered" v-html="state.submessage" />
            </div>
        </div>
        <div class="columns has-margin-top is-centered" v-if="state.new_meeting_btn">
            <div v-if="$store.state.infos.suborganization.meeting.meeting_on_machine" class="column is-4">
                <div
                    class="card-button"
                    @click="emitEvent(events.new_meeting)" 
                    >
                    <h6 class="h5-portrait has-text-centered" v-html="texts.new_meeting[lang]"></h6>
                </div>
            </div>
            <div class="column is-4">
                <div
                    class="card-button"
                    @click="go_to_non_meeting_prequalifications"
                    >
                    <h6 class="h5-portrait has-text-centered" v-html="texts.register_now[lang]"></h6>
                </div>
            </div>
        </div>
        <div class="columns has-margin-top" v-if="conditions.show_loader">
            <div class="column is-10 is-offset-1">
                <progress class="progress is-link" :value="state.loading_percent" max="100">{{state.loading_percent}}%</progress>
            </div>
        </div>
    </div>
<div v-else-if="state.sending" class="container is-fluid registration">
    <div class="columns">
        <div class="column is-10 is-offset-1">
            <loader primary-color="$store.state.infos.organization.colorone" />
        </div>
    </div>
</div>
<div v-else :class="`container is-fluid registration is-registration-${rtype}`">
    <div class="columns">
        <div class="column is-10 is-offset-1">
            <div v-if="['cc', 'phone', 'meeting', 'email', 'code0', 'code1', 'code2', 'code3', 'code4'].indexOf(rtype) !== -1" 
                class="input-section"
            >
                    <h5 class="h4-portrait has-text-centered" v-html="texts.input_header[lang]" />
                    <h4 v-if="!conditions.use_keyboard" key="phone-input" class="h3-portrait input-form" v-html="state.input.trim().length > 0 ? state.input: state.placeholder"></h4>

                    <div class="columns is-mobile is-centered" v-else>
                        <div class="column has-text-centered">
                            <div class="field has-addons has-xs-padding">
                                <div class="control is-expanded">
                                    <input key="name-input" class="input is-radiusless has-text-centered"
                                        @keyup.enter="register"
                                        type="text" v-model="state.input"
                                        :inputmode="numeric_keyboard_mode ? 'numeric' : null"
                                        :pattern="numeric_keyboard_mode ? '[0-9]*' : null"
                                        :placeholder="variables.keyboard_placeholder || ''" />
                                </div>
                                <div class="control">
                                        <a 
                                            @click.stop="register"
                                            v-tap="register"
                                            v-swipe="register"
                                            class="is-radiusless button has-text-white has-background-dark">{{$text('validate_input_by_keyboard')}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                <h6 v-show="state.showValidation" class="input-alert h5-portrait`">
                    {{texts.input_error[lang]}}
                </h6>
            </div>
            <div v-else-if="['vibrator'].indexOf(rtype) !== -1" 
                class="input-section"
            >
                <h4 class="h3-portrait has-small-margins has-text-centered" v-html="texts.input_header[lang]" />
                <div class="columns is-centered">
                    <div class="column is-4" v-if="texts.yes[lang] !== ''">
                        <div class="card-button" @click.prevent="register">
                            <h5 class="h4-portrait has-text-centered">{{texts.yes[lang]}}</h5>
                        </div>
                    </div>
                    <div class="column is-4" v-if="texts.no[lang] !== ''">
                        <div class="card-button" @click="go_back('phone', $event)">
                            <h5 class="h4-portrait has-text-centered">{{texts.no[lang]}}</h5>
                        </div>
                    </div>
                </div>
                <div v-if="variables.extra_image" class="columns is-centered">
                    <div class="column is-4">
                        <div class="has-1h-top-margin has-small-margins figure is-1by1">
                            <img :src="variables.extra_image" alt="Vibrating device" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="['barcode'].indexOf(rtype) !== -1" 
                class="input-section"
            >
                <h4 class="h3-portrait has-small-margins has-text-centered" v-html="texts.input_header[lang]" />
                <div v-if="variables.extra_image" class="columns is-centered">
                    <div class="column is-4">
                        <div class="has-1h-top-margin has-small-margins figure is-1by1">
                            <img :src="variables.extra_image" alt="Customer card" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="['name', 'prm'].indexOf(rtype) !== -1" 
                class="input-section"
                >
                <div class="columns is-mobile" v-if="texts.input_header[lang].length > 0">
                    <div class="column has-text-centered">
                        <h5 class="h4-portrait has-text-centered has-small-margins" v-html="texts.input_header[lang]" />
                    </div>
                </div>
                <div class="columns is-mobile">
                    <div class="column has-text-centered">
                        <label class="option-label" htmlFor="title">
                            <input
                            :checked="state.title === 'mr'"
                            type="radio" name="title"
                            value="mr" class="option-input radio"
                            v-model="state.title"
                            style="z-index:5;"
                            />
                            {{texts.mr[lang]}}
                        </label>
                    </div>
                    <div class="column has-text-centered">
                        <label class="option-label" htmlFor="title">
                            <input
                            :checked="state.title === 'ms'"
                            class="option-input radio" type="radio"
                            name="title" value="ms"
                            v-model="state.title"
                            style="z-index:5;"
                            />
                            {{texts.ms[lang]}}
                        </label>
                    </div>
                </div>
                <h4 v-if="!conditions.use_keyboard" key="name-input" 
                    class="h3-portrait input-form" v-html="state.input.trim().length > 0 ? state.input : state.placeholder"></h4>
                <div class="columns is-mobile" v-else>
                    <div class="column has-text-centered">
                        <div class="field has-addons has-xs-padding">
                            <div class="control is-expanded">
                                <input key="name-input" class="input is-radiusless has-text-centered"
                                    @keyup.enter="register"
                                    type="text" v-model="state.input"
                                    :inputmode="numeric_keyboard_mode ? 'numeric' : null"
                                    :pattern="numeric_keyboard_mode ? '[0-9]*' : null"
                                    :placeholder="variables.keyboard_placeholder || ''" />
                            </div>
                            <div class="control">
                                    <a 
                                        @click.stop="register"
                                        v-tap="register"
                                        v-swipe="register"
                                        class="is-radiusless button has-text-white has-background-dark">{{$text('validate_input_by_keyboard')}}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div class="columns is-mobile is-centered has-margin-bottom" v-if="conditions.use_keyboard">
                </div>-->
                <h6 v-show="state.showValidation" class="h5-portrait input-alert">
                    {{texts.input_error[lang]}}
                </h6>
            </div>
        </div>
    </div>
    <div class="columns is-centered">
        <div class="column is-10">
            <component :is="pad_component" v-bind="pad_component_options" v-if="!conditions.use_keyboard" />
            <h6 class="has-text-disclaimer has-text-centered" v-html="texts.disclaimer[lang]"></h6>
            <h6 v-if="texts.extra_disclaimer_information" class="has-text-extra-disclaimer has-text-centered" v-html="texts.extra_disclaimer_information[lang]"></h6>
        </div>
    </div>
</div>
</template>

<script>
    module.exports = require('./Registration');
</script>
