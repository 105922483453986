const Messages = require('../../../../../api/messages');
const EventType = require('../../../../../enums/event_types');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');
const WithTimeout = require('../../../mixins/with_timeout/WithTimeout');
const Utils = require('../../../../../utils/utils');

module.exports = {
    mixins: [MasterWidget, WithTimeout],
    data() {
        return {};
    },
    methods: {
        answer(r) {
            let opts = {};
            if (r === 'yes') {
                if (this.variables.new_registration_type && this.variables.new_registration_type.trim() !== '') {
                    opts.registration_type = this.variables.new_registration_type;
                }
                this.emit_event(this.variables.yes_event, this.variables.yes_event_value || 'NA', opts);
            } else {
                if (this.conditions.reset_information_on_no) {
                    opts = Utils.resetInformation();
                }
                this.emit_event(this.variables.no_event, this.variables.no_event_value || 'NA', opts);
            }
        },
        tap(r) {
            () => this.answer(r);
        },
        emit_event(event, value = 'NA', opts = {}) {
            const payload = {
                commit: this.$store.commit,
                eventType: EventType.ALL,
                event,
                eventValue: value,
                eventOptions: opts,
            };

            this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
        },
    },
    computed: {
    },
};
