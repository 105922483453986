const momenttz = require('moment-timezone');
const MasterWidget = require('../../../../mixins/master_widget/MasterWidget');

module.exports = {
    mixins: [MasterWidget],
    props: {
        customer: { type: Object, required: true },
        mode: { type: String, default: 'meeting' },
    },
    methods: {
        select(event, customer) {
            this.$emit('select-customer', customer);
        },
        generateMeetingTime(customer, format = 'HH:mm') {
            const tz = this.$store.state.infos.suborganization.tz;
            return momenttz(customer.mtimestamp).tz(tz).format(format);
        },
        generateExtraInformation(customer) {
            const { extraCustomerInformation } = this.variables;
            if (!extraCustomerInformation) {
                return '';
            }

            if (extraCustomerInformation.startsWith('extra_code')) {
                const code = extraCustomerInformation.split(':')[1];
                const found = customer.extra_codes.find(c => c.name === code);
                if (!found) {
                    return '';
                }
                return found.value || '';
            }
            return customer[extraCustomerInformation] || '';
        },
    },
};
