<template>
<div class="container is-fluid information">
    <div class="columns is-centered" v-if="$text('question').trim() !== ''">
        <div class="column is-8 has-text-centered has-bottom-margin">
            <h3 v-html="$text('question')"></h3>
        </div>
    </div>
    <div class="columns is-centered">
        <div class="column is-4" v-if="texts.yes[lang] !== ''">
            <div class="card-button" 
                @click.prevent="answer('yes')"
                v-touch:tap="tap('yes')"
                v-touch:swipe="tap('yes')"
            >
                <h5 class="h4-portrait has-text-centered">{{$text('yes')}}</h5>
            </div>
        </div>
        <div class="column is-4" v-if="texts.no[lang] !== ''">
            <div class="card-button" 
                @click.prevent="answer('no')"
                v-touch:tap="tap('no')"
                v-touch:swipe="tap('no')"
            >
                <h5 class="h4-portrait has-text-centered">{{$text('no')}}</h5>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    module.exports = require('./YesNoQuestion');
</script>
