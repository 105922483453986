const _ = require('lodash');

function emit(channel, info) {
    const obj = _.merge({ __ws_channel: channel }, info);
    return obj;
}

function unpack(event) {
    if (!('__ws_channel' in event)) {
        return [null, null];
    }

    return [event.__ws_channel, event];
}

module.exports = {
    emit,
    unpack,
};
