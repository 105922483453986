<template>
    <div class="keyboard-container" v-touch:tap="find_closest_key" @click="find_closest_key">
        <div class="keyboard" v-for="row in state.keyboard">
            <div class="keyboard-row">
                <key
                    v-bind="k" 
                    v-for="k in row"
                />
            </div>
        </div>
    </div>
</template>

<script>
module.exports = require('./GenericPad');
</script>
