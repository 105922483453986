<template>
<div class="container is-fluid blue-background white sliding-footer">
    <div class="columns is-centered">
        <div class="column has-text-centered is-marginless has-xs-padding">
            <marquee class="h4">
            {{displayed_customers}} <template v-if="$text('information').trim() != ''">&mdash; {{$text('information')}}</template>
            </marquee>
        </div>
    </div>
</div>
</template>

<script>
    module.exports = require('./SlidingFooter');
</script>
