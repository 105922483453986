const _ = require('lodash');

function merge_with_replacement(object, source) {
    return Object.keys(source).reduce((obj, key) => {
        if (key in obj) {
            if (source[key] == null) { // Remove from object
                delete obj[key];
            } else if (obj[key] instanceof Array) {
                obj[key] = source[key]; // Replace with new array
            } else if (obj[key] instanceof Object) {
                obj[key] = merge_with_replacement(obj[key], source[key]);
            } else {
                obj[key] = source[key];
            }
        } else {
            obj[key] = source[key];
        }
        return obj;
    }, object);
}

function resetInformation() {
    return {
        prequalification_path: [],
        depth: 0,
        registration: undefined,
        registration_type: '',
        lang: undefined,
        selectedVendorOpen: 'na',
    };
}

function find_unreachable_prequalifications(ids, prequals) {
    const myPrequals = (ids || []).reduce((obj, k) => {
        obj[k] = prequals[k];
        return obj;
    }, {});
    const unreachable_prequals = _.filter(myPrequals, (p) => {
        if (p.prequalifications && p.prequalifications.length === 0) {
            return !p.meeting;
        }
        return find_unreachable_prequalifications(p.prequalifications, prequals).size
            === p.prequalifications.length;
    });
    return new Set(unreachable_prequals.map(p => p._id));
}

function toMatrix(content, rowLength) {
    return content
        .reduce((rows, key, index) => (index % rowLength === 0 ? rows.push([key])
            : rows[rows.length - 1].push(key)) && rows, []);
}

function int16ToArray(number) {
    return [number & 0xFF,
        (number >> 8) & 0xFF];
}

function buffer2hex(buffer) {
    return Array.from(buffer)
            .map(b => b.toString(16).padStart(2, '0'))
            .join('');
}

function toBytes(string) {
    const arr = [];
    for (let i = 0; i < string.length; i++) {
        arr.push(string[i].charCodeAt(0));
    }
    return arr;
}

module.exports = {
    merge_with_replacement,
    resetInformation,
    toMatrix,
    find_unreachable_prequalifications,
    int16ToArray,
    buffer2hex,
    toBytes,
};
