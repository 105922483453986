<template>
<div v-if="depth > 0" class="container is-fluid prequalification-footer container-bottom">
    <div class="columns">
        <div class="column is-3 is-offset-1">
            <div
            class="card-button arrows"
            @click="emitEvent"
            v-touch:tap="emitEvent"
            v-touch:swipe="swipe"
            >
                <h6 class="h5-portrait has-text-centered" v-html="texts.return[lang]"></h6>
            </div>
    </div>
</div>
</div>
</template>

<script>
    module.exports = require('./PrequalificationFooter');
</script>
