const _ = require('lodash');
const MasterWidget = require('../../../../mixins/master_widget/MasterWidget');
const Utils = require('../../../../../../utils/utils');

module.exports = {
    mixins: [MasterWidget],
    data() {
        return {
            state: {
                skip: 0,
            },
        };
    },
    methods: {
        selectHour(event, hour) {
            this.$emit('select-hour', event, hour);
        },
        pageChanged(newSkip) {
            this.state.skip = newSkip;
        },
    },
    computed: {
        computedCards() {
            const { firstHour, lastHour, stepHour } = this.variables;
            const from = this.$text('from', this.lang);
            const to = this.$text('to', this.lang);

            const rng = _.range(firstHour, lastHour, stepHour);
            const hours = rng.map(elt => ({
                text: `${from} ${elt}:00 ${to} ${parseInt(elt, 10) + parseInt(stepHour, 10)}:00`,
                start: parseInt(elt, 10),
                end: parseInt(elt, 10) + parseInt(stepHour, 10),
            }));
            return hours;
        },
        matrixComputedCards() {
            const { hoursPerRow } = this.variables;
            return Utils.toMatrix(this.paginatedComputedCards, parseInt(hoursPerRow, 10));
        },
        paginatedComputedCards() {
            const { hoursPerPage } = this.variables;
            const { skip } = this.state;
            return this.computedCards.slice(skip, skip + parseInt(hoursPerPage, 10));
        },
    },
};
