<template>
<centered-box v-if="inactive_device">
    <span class="has-text-centered">{{$text('inactive', lang)}}</span>
</centered-box>
<div class="container" v-else>
    <div class="columns">
        <div class="column is-12 has-text-centered">
            <div class="box background-secondary-color" :style="{backgroundColor: $store.state.infos.organization.colortwo}">
                <h3 class="first-info" v-if="view_infos.selectedVendor">{{view_infos.selectedVendor.value}}</h3>
                <h3 class="first-info" v-else>{{$store.state.infos.device.name}}</h3>
                <a :disabled="is_desk_info_disabled()" class="button is-medium" @click.prevent="show_queue_information">
                    {{desk_info_text}}
                </a>
            </div>
        </div>
    </div>
    <div class="columns">
        <div v-if="desk_customer == null" class="column is-12 has-text-centered">
            <div class="box background-primary-color" :style="{backgroundColor: $store.state.infos.organization.colorone}">
                <h3 class="first-info">{{texts.customer[lang]}}</h3>
            </div>
        </div>
        <div v-else class="column is-12 has-text-centered">
            <div class="box background-primary-color" :style="{backgroundColor: $store.state.infos.organization.colorone}">
                <h3 class="first-info">{{texts.customer[lang]}} {{desk_customer ? desk_customer.ticket : '?'}}</h3>
                <a class="button is-medium" @click="show_customer_information">{{desk_customer == null ? '?' : desk_customer.display_desk}}</a>
                <template v-if="conditions.show_customer_path">
                    <ul>
                        <li v-for="id in desk_customer.prequalifications_path" :key="id">
                        <h4><strong>{{$store.state.infos.prequalifications[id].name[lang]}}</strong></h4>
                        </li>
                    </ul>
                </template>
                <h4 v-else>{{texts.request[lang]}} : {{desk_customer ? $store.state.infos.prequalifications[desk_customer.prequalification].name[lang] : '?'}}</h4>
            </div>
        </div>
    </div>
    <div class="columns is-centered" v-if="conditions.show_timer &&  desk_customer">
        <desk-timer 
            :time="remaining_timer_for_timer"
            :delay="remaining_delay_for_timer"
            :default-mode="variables.timer_mode || 'manual'"
            :texts="texts" 
            :lang="lang"
            :colors="{
                primary: $store.state.infos.organization.colorone,
                secondary: $store.state.infos.organization.colortwo
            }"
        />
    </div>
    <div v-if="$store.state.infos.device.status.open">
        <action-buttons type="middle" />
    </div>
    <div>
        <div v-if="notify_priorities" class="notification is-danger">
            <p class="h5">
                {{texts.priority_notification[lang]}}
            </p>
        </div>
        <div v-if="notify_customer_returns" class="notification is-danger">
            <p class="h5">
                {{texts.customer_return_notification[lang]}}
            </p>
        </div>
    </div>
    <div v-if="$store.state.notConnected" class="notification is-danger">
        <h5>{{texts.not_connected[lang]}}</h5>
        <h5>{{texts.fv_cant_work[lang]}}</h5>
    </div>
</div>
</template>

<script>
    module.exports = require('./Basic');
</script>
