<template>
<div>
    <div class="columns is-mobile is-centered">
        <div v-for="btn in buttons" :class="`column is-${buttons.length < 3 ? 4 : 3}`">
            <div
                :class="['card-button is-unselectable', `card-button-${btn}`, 'arrows']"
                @click="emitEvent(btn, $event)"
                :key="`action-${btn}`"
                v-touch:tap="tap(btn)"
                v-touch:swipe="swipe(btn)"
            >
                <component :is="variables.h || h6" class="has-text-centered">{{texts[btn][$store.state.interfaceLang]}}</component>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    module.exports = require('./RegistrationFooter');
</script>
