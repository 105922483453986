const moment = require('moment');
const Vuex = require('vuex');
const _ = require('lodash');
const toastr = require('toastr');
const EventType = require('../../../../../enums/event_types');
const Messages = require('../../../../../api/messages');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');
const NotificationMixin = require('../../../mixins/notification/Notification');
const Buttons = require('./subcomponents/Buttons.vue');
const DeskTimer = require('./subcomponents/Timer.vue');

module.exports = {
    mixins: [MasterWidget, NotificationMixin],
    components: {
        ActionButtons: Buttons,
        DeskTimer,
    },
    data() {
        return {
            state: _.merge({}, this.$store.state.template.view_infos.desk_state || {}),
        };
    },
    methods: {
        is_desk_info_disabled() {
            const waitings = this.waiting_customers;
            const meetings = this.$store.state.infos.meeting_customers;
            return waitings.length === 0 && meetings.length === 0;
        },
        show_customer_information(e) {
            e.preventDefault();
            this.emitEvent(this.events.desk_show_customer_information, 'NA', { desk_state: this.state });
        },
        show_queue_information(e) {
            e.preventDefault();
            if (this.is_desk_info_disabled()) {
                return;
            }
            this.emitEvent(this.events.desk_queue, 'NA', { desk_state: this.state });
        },
        emitEvent(event, value = 'NA', opts = {}) {
            const payload = {
                commit: this.$store.commit,
                eventType: EventType.ALL,
                event,
                eventValue: value,
                eventOptions: opts,
            };

            this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
        },
    },
    watch: {
        next_error(new_error) {
            if (new_error === 'TooSoonToCallMeeting') {
                toastr.error(this.texts.too_soon_to_call_meeting[this.lang],
                        '', { preventDuplicates: true });
                this.$store.state.desk.next_error = '';
            }

            if (new_error === 'TooSoonToCallNext') {
                toastr.error(this.texts.too_soon_to_call_next[this.lang],
                        '', { preventDuplicates: true });
                this.$store.state.desk.next_error = '';
            }

            if (new_error === 'NoNewCustomer') {
                if (this.waiting_customers.length === 0) {
                    if (this.state.end_call) {
                        this.state.end_call = false;
                    }
                }
            }
        },
        new_customer() {
            this.notify();
        },
    },
    computed: _.merge({
        notify_priorities() {
            const waiting_customers = this.$store.state.infos.waiting_customers;
            const prequalifications = this.$store.state.infos.prequalifications;

            const prioritized = waiting_customers.filter(customer => customer.prioritized);

            if (prioritized.length > 0) {
                const notify = prioritized.some((customer) => {
                    const pid = customer.prequalification;
                    return prequalifications[pid].notify;
                });
                return notify;
            }
            return false;
        },
        notify_customer_returns() {
            if (this.desk_customer == null) {
                return false;
            }

            const suborganization = this.$store.state.infos.suborganization;
            const customer_return_alert = suborganization.alerts.customer_return.activated;
            if (!customer_return_alert) {
                return false;
            }

            const critical_number = suborganization.alerts.customer_return.times;
            if (this.desk_customer.visits >= critical_number) {
                return true;
            }
            return false;
        },
        desk_info_text() {
            const { 
                waiting_customers, 
                meeting_customers, 
                multi_waiting_customers
            } = this.$store.state.infos;

            const display = [`${waiting_customers.length} ${this.$text('people')}`];
            
            if (meeting_customers.length > 0) {
                display.push(`${meeting_customers.length} ${this.$text('meetings')}`); 
            }

            if (multi_waiting_customers.length > 0) {
                display.push(`${multi_waiting_customers.length} ${this.$text('multi_waiting')}`);
            }

            return display.join(' | ');
        },
        new_customer() {
            return this.$store.state.desk.new_customer || '';
        },
        inactive_device() {
            return this.$store.state.infos.device.inactive || false;
        },
        remaining_delay_for_timer() {
            if(!this.variables.timer_delay) {
                return 0;
            } 
            
            if(!this.desk_customer) {
                return 0;
            }
            
            const { ctimestamp } = this.desk_customer;
            const timestamp = moment(ctimestamp);
            const elapsed = Math.abs(moment().diff(timestamp, 'seconds'));
            return Math.max(0, this.variables.timer_delay - elapsed);
        },
        remaining_timer_for_timer() {
            if(!this.variables.timer_time) {
                return 0;
            } 
            
            if(!this.desk_customer) {
                return 0;
            }
            
            let timestamp = null;
            const { ctimestamp } = this.desk_customer;
            if(this.variables.timer_mode === 'manual') {
                const stimestamp = parseInt(localStorage.getItem('timer_started_at'), 10);
                if(stimestamp && moment(stimestamp).isSameOrAfter(moment(ctimestamp))) {
                    timestamp = moment(stimestamp);
                } else {
                    timestamp = moment(ctimestamp);
                    localStorage.removeItem('timer_started_at');
                    localStorage.removeItem('timer_started');
                }
            } else {
                timestamp = moment(ctimestamp);
                localStorage.removeItem('timer_started_at');
                localStorage.removeItem('timer_started');
            }

            const elapsed = Math.abs(moment().diff(timestamp, 'seconds')) - ((this.variables.timer_delay || 0) - this.remaining_delay_for_timer);
            return this.variables.timer_time - elapsed;
        }
    }, Vuex.mapGetters(['desk_customer', 'next_error', 'waiting_customers', 'view_infos'])),
    mounted() {
    },
};
