const _ = require('lodash');
const EventType = require('../../../../../../enums/event_types');
const MasterWidget = require('../../../../mixins/master_widget/MasterWidget');
const MeetingStepMixin = require('../MeetingStepMixin');

module.exports = {
    mixins: [MasterWidget, MeetingStepMixin],
    computed: {
        month_names() {
            return this.texts.month_names[this.lang]
                 .split(this.variables.text_separator)
                 .map(f => f.trim()).filter(f => f.trim() !== '');
        },
        day_names() {
            return this.texts.day_names[this.lang]
                 .split(this.variables.text_separator)
                 .map(f => f.trim()).filter(f => f.trim() !== '');
        },
    },
};
