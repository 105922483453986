<template>
<stepper 
    :current-step="3" 
    :number-of-steps="variables.number_of_steps || 1" 
    style="width:100%"
    @step-change="meeting_step_change"
>
    <template slot="step-title" slot-scope="props">
        {{texts.step[lang]}} {{props.id+1}}
    </template>
    <template slot="step-details" slot-scope="props">
    <!--{{texts['step_detail_' + props.id][lang]}}--> 
    </template>
    <template slot="step-content" slot-scope="props">
        <div class="columns is-centered">
            <div class="column is-8 has-text-centered">
                <h3 class="title is-3">{{texts.confirm_meeting[lang]}}</h3>
            </div>
        </div>
        <div class="columns is-centered">
            <div class="column is-8-desktop">
                <div class="card">
                    <div class="card-content">
                        <div class="content">
                            <div class="columns is-centered">
                                <div class="column is-6">
                                    <div class="field">
                                        <label class="label">Conseil Cuisine</label>
                                        <div class="control">
                                            <p class="is-marginless"><span class="icon"><i class="fa fa-user"></i></span> {{texts.any_provider[lang]}}</p>
                                            <p class="is-marginless"><span class="icon"><i class="fa fa-calendar"></i></span> Le 30/04/2018 à 11:00</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-6">
                                    <div class="field">
                                        <label class="label">Corentin Ribeyre</label>
                                        <div class="control">
                                            <p class="is-marginless"><span class="icon"><i class="fa fa-phone"></i></span> 0688912726</p>
                                            <p class="is-marginless"><span class="icon"><i class="fa fa-envelope"></i></span> corentin.ribeyre@superpitch.fr</p>
                                            <p class="is-marginless"><span class="icon"><i class="fa fa-map-marker"></i></span> 27 rue du Chemin Vert<br />75011 Paris</p>
                                            <hr />
                                            <p class="is-marginless">Voici une note</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template slot="step-buttons" slot-scope="props">
        <div class="columns is-centered">
            <div class="column is-8-desktop">
                <div class="field is-grouped is-pulled-right">
                    <div class="control" v-if="props.step > 0">
                        <button @click.prevent="props.previous" class="button is-medium is-primary">{{texts.prev[lang]}}</button>
                    </div>
                    <div class="control" v-if="props.step < props.numberOfSteps-1">
                        <button @click.prevent="props.next" class="button is-medium is-info">{{texts.next[lang]}}</button>
                    </div>
                    <div class="control" v-else>
                        <button @click.prevent="props.next" class="button is-medium is-info">{{texts.finish[lang]}}</button>
                    </div>
                </div>
            </div>
        </div>
    </template>
</stepper>
</template>

<script>
module.exports = require('./MeetingReview');
</script>
