const Vue = require('vue');
const _ = require('lodash');
const moment = require('moment');
const momenttz = require('moment-timezone');
const Vuex = require('vuex');
const Messages = require('../../../../../api/messages');
const EventType = require('../../../../../enums/event_types');
const Phones = require('../../../../../../../app/modules/phones');
const StringUtils = require('../../../../../utils/strings');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');
const WithTimeout = require('../../../mixins/with_timeout/WithTimeout');
const RegistrationMixin = require('../../../mixins/registration/Registration');

module.exports = {
    mixins: [MasterWidget, WithTimeout, RegistrationMixin],
    data() {
        return {
            state: {
                showValidation: false,
                title: 'mr',
                input: '', // this.augmented_input,
                placeholder: '&nbsp;',
                sending: false,
                message: '',
                submessage: '',
                post_registration_image: false,
                new_meeting_btn: false,
                vibrator_interval: null,
                registered: false,
                new_rtype: null,
                loading_percent: 0,
                loading_interval: undefined,
            },
        };
    },
    methods: {
        keyboard_change(k, e, longtap) {
            e.preventDefault();
            switch (k) {
            case 'ENTER':
                this.register();
                break;
            case 'BCKSPACE': {
                if (longtap) {
                    this.state.input = '';
                } else {
                    let new_input = this.state.input.slice(0, -1);
                    if (new_input.length === 0) {
                        new_input = '';
                    }
                    this.state.input = new_input;
                }
                this.state.showValidation = false;
                break;
            }
            default: {
                this.state.input += k;
                this.state.showValidation = false;
                break;
            }
            }
        },
        go_back(type, e) {
            e.preventDefault();

            switch (type) {
            default:
            case 'home':
                return this.emitEvent(this.events.prequal, 'NA', { depth: 0, prequalification_path: [], registration: undefined });
            case 'phone':
                return this.emitEvent(this.events.next_prequal, 'phone');
            }
        },
        register() {
            // e.preventDefault();
            const rtype = this.state.new_rtype || this.rtype;
            const suborganization = this.$store.state.infos.suborganization;

            if (this.conditions.emit_event_instead_of_registration) {
                const myret = this.emit_event_as_registration(rtype,
                    this.state.input.trim(),
                    suborganization,
                    this.state.title,
                    this.variables.emitted_event,
                    this.variables.emitted_value || 'NA');
                if (myret === -1) {
                    this.state.showValidation = true;
                    return;
                }
                return;
            }

            this.state.registered = true;
            const view_infos = this.$store.state.template.view_infos;
            let input = this.state.input.trim();
            let title = this.state.title.trim();

            const mycustomer = 'registration' in view_infos
                && 'customer' in view_infos.registration ? view_infos.registration.customer : null;
            if (this.conditions.trigger_registration
                && mycustomer
                && 'input' in mycustomer) {
                input = mycustomer.input;
                title = mycustomer.title || undefined;
                delete mycustomer.input;
                delete mycustomer.title;
            }

            const ret = this.register_customer(rtype, input, suborganization,
                this.lang, title, mycustomer);

            if (ret === -1) {
                this.state.showValidation = true;
                return;
            } else if (ret === -2) {
                this.emitEvent(this.events.information_page);
                return;
            }
            this.state.sending = true;
        },
        emitEvent(event, value = 'NA', opts = {}) {
            const payload = {
                commit: this.$store.commit,
                eventType: EventType.ALL,
                event,
                eventValue: value,
                eventOptions: opts,
            };

            this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
        },
        go_to_non_meeting_prequalifications(e) {
            e.preventDefault();

            // TODO HACK HACK HACK
            const pq_path = this.variables.prequalifications_path.split(',');
            const pq_depth = this.variables.prequalification_depth;
            this.emitEvent(this.variables.register_now_event || this.events.next, 'NA',
                    { prequalification_path: pq_path, depth: pq_depth, registration: undefined });
        },
        render_success_message(registered, message_type) {
            const rtype = this.rtype;

            if (message_type === 'message') {
                switch (rtype) {
                case 'email': 
                case 'phone': { 
                    if (registered.wait) {
                        return StringUtils.format_with_obj(
                            this.texts.success_wait_message[this.lang], registered);
                    }
                    return StringUtils.format_with_obj(
                            this.texts.success_message[this.lang], registered);
                }
                default:
                    return StringUtils.format_with_obj(
                            this.texts.success_message[this.lang], registered);
                }
            } else {
                switch (rtype) {
                case 'email':
                case 'phone': {
                    if (registered.wait) {
                        return StringUtils.format_with_obj(
                            this.texts.success_wait_submessage[this.lang], registered);
                    }
                    return StringUtils.format_with_obj(
                            this.texts.success_submessage[this.lang], registered);
                }
                default:
                    return StringUtils.format_with_obj(
                            this.texts.success_submessage[this.lang], registered);
                }
            }
        },
        reset_input() {
            this.state.input = this.augmented_input;
        },
        check_vibrator() {
            const configuration = this.vibrator_configuration;
            const registered = this.registration.registered;
            if (registered == null) {
                return;
            }

            if (configuration.customer == null) {
                return;
            }

            if (configuration.customer.ticket === registered.ticket) {
                if (this.state.vibrator_interval) {
                    clearInterval(this.state.vibrator_interval);
                    this.state.vibrator_interval = null;
                }
                if (configuration.ok) {
                    this.state.message = this.render_success_message(registered, 'message');
                    this.state.submessage = this.texts.success_vibrator_configured[this.lang];
                } else {
                    this.state.message = this.render_success_message(registered, 'message');
                    this.state.submessage = this.texts.error_vibrator_configured[this.lang];
                }
            }
        },
    },
    watch: {
        registration(new_reg) {
            if (!new_reg.post_registration) {
                return;
            }

            const suborganization = this.$store.state.infos.suborganization;
            const tz = suborganization.tz;
            const registered = new_reg.registered;

            this.state.new_meeting_btn = false;
            if (new_reg.unknown_error) {
                this.state.message = this.texts.error_message[this.lang];
                this.state.submessage = this.texts.error_submessage[this.lang];
            } else if (new_reg.no_meeting) {
                this.state.message = this.texts.no_meeting_message[this.lang];
                this.state.submessage = this.texts.no_meeting_submessage[this.lang];
                this.state.new_meeting_btn = true;
            } else if (new_reg.expired_meeting) {
                this.state.message = this.texts.expired_meeting_message[this.lang];
                this.state.submessage = this.texts.expired_meeting_submessage[this.lang];
                this.state.new_meeting_btn = true;
            } else if (new_reg.already_registered) {
                this.state.message = this.texts.already_registered_message[this.lang];
                this.state.submessage = this.texts.already_registered_submessage[this.lang];
            } else {
                const mt = momenttz.tz(`${registered.mtimestamp}`, tz).format('HH:mm');
                registered.mt_str = mt;
                this.state.message = this.render_success_message(registered, 'message');
                this.state.submessage = this.render_success_message(registered, 'submessage');
                this.state.post_registration_image = this.variables.success_image !== '';

                if(this.conditions.show_loader) {
                    const secs = parseInt(this.variables.loading_seconds || 6, 10);
                    this.state.loading_percent = 0;
                    this.state.loading_interval = setInterval(() => {
                        const steps = (Math.ceil(100 / secs)) / 4;
                        this.state.loading_percent += steps;
                        this.state.loading_percent = Math.min(100, this.state.loading_percent);
                        if(this.state.loading_percent >= 100 && this.state.loading_interval) {
                            clearInterval(this.state.loading_interval);
                            this.state.loading_interval = undefined;
                        }
                    }, 250);
                }

                if (registered.vibrate) {
                    if (this.state.vibrator_interval) {
                        clearInterval(this.state.vibrator_interval);
                        this.state.vibrator_interval = null;
                    }

                    this.state.vibrator_interval = setInterval(this.check_vibrator.bind(this), 1500);
                }
            }
        },
        variables(new_vars, old_vars) {
            if (old_vars.registration_type !== new_vars.registration_type) {
                this.reset_input();
            }
        },
        view_infos(new_infos, old_infos) {
            if (old_infos.registration_type != new_infos.registration_type) {
                this.reset_input();
            }
        },
        barcode_result(res) {
            if (this.rtype === 'barcode') {
                this.register();
            } else if (this.conditions.authorize_barcode) {
                if (this.conditions.input_from_barcode) {
                    if ('extra_codes' in res) {
                        const key = res.extra_codes.find(ex => ex.name === this.rtype);
                        if (key) {
                            this.state.input = key.value.trim();
                        }
                    } else {
                        this.state.input = res[this.rtype].trim();
                    }
                } else {
                    this.state.new_rtype = 'barcode';
                    this.register();
                }
            }
            // console.log('Scannnnnnnn', res);
        },
    },
    computed: _.merge({
        pad_component() {
            const type = this.rtype;

            const suborganization = this.$store.state.infos.suborganization;
            switch (type) {
            case 'meeting':
            case 'cc':
            case 'phone':
                return 'phone-pad';
            case 'email':
                return 'email-pad';
            case 'prm':
            case 'name':
                return 'alpha-pad';
            case 'vibrator':
                return '';
            case 'code0':
            case 'code1':
            case 'code2':
            case 'code3':
            case 'code4':
                return suborganization.extra_codes[type].keyboard;
            default:
                return '';
            }
        },
        pad_component_options() {
            const organization = this.$store.state.infos.organization;
            switch (this.pad_component) {
            default:
            case 'email-pad':
            case 'alpha-pad':
            case 'alpha-num-pad':
                return {
                    primaryColor: organization.colorone,
                    keyClasses: 'h4',
                    onKeyChange: this.keyboard_change,
                };
            case 'phone-pad':
                return {
                    primaryColor: organization.colorone,
                    keyClasses: 'h5',
                    onKeyChange: this.keyboard_change,
                };
            }
        },
        augmented_input() {
            const suborganization = this.$store.state.infos.suborganization;
            const type = this.rtype;

            switch (type) {
            case 'meeting':
            case 'phone': {
                const prefix = suborganization.sms.prefix || '';
                return `${prefix}`;
            }
            case 'email': {
                const prefix = suborganization.email.prefix || '';
                return `${prefix}`;
            }
            case 'cc': {
                const { cc = {} } = suborganization;
                const prefix = cc.prefix || '';
                return `${prefix}`;
            }
            default:
                return '';
            }
        },
        rtype() {
            return this.registration_type && this.registration_type.trim() !== '' ? this.registration_type : this.variables.registration_type;
        },
        numeric_keyboard_mode() {
            switch (this.rtype) {
            case 'phone':
                return true;
            default:
                return false;
            }
        },
    }, Vuex.mapGetters(['registration', 'vibrator_configuration', 'registration_type', 'barcode_result'])),
    mounted() {
        this.reset_input();
        if (this.conditions.trigger_registration && !this.state.registered) {
            this.register({ preventDefault: () => {} });
        }

        if(this.conditions.select_default_prequalification) {
            const pq = _.find(this.$store.state.infos.prequalifications, (v) => v.default === true);
            if(!pq) {
                return;
            }
            const { view_infos } = this.$store.state.template;
            const prequalification_path = [pq._id];
            this.$set(view_infos, 'prequalification_path', prequalification_path);
            this.$set(view_infos, 'depth', 1);
        }
    },
    updated() {
        if (this.conditions.trigger_registration && !this.state.registered) {
            this.register({ preventDefault: () => {} });
        }
    },
    beforeDestroy() {
        if (this.state.vibrator_interval) {
            clearInterval(this.state.vibrator_interval);
            this.state.vibrator_interval = null;
        }
    },
};
