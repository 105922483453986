const Vuex = require('vuex');
const moment = require('moment');
const momenttz = require('moment-timezone');
const _ = require('lodash');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');
const SoundMixin = require('../../../mixins/sound/Sound');
const Handlebars = require('../../../../../../../app/modules/utils/ehandlebars');

// Workaround with browserify to include all locales of moment
require('moment/min/locales.min');

module.exports = {
    mixins: [MasterWidget, SoundMixin],
    data() {
        return {
            state: {
                datetime: momenttz.tz(this.$store.state.infos.suborganization.tz),
                interval: null,
                taskInterval: null,
                refreshKey: 0,
            },
        };
    },
    methods: {
        update_datetime() {
            this.state.datetime = momenttz.tz(this.$store.state.infos.suborganization.tz);
        },
        waiting_time_binned(customer) {
            const min = this.variables.minWaitingTime || 5;
            const max = this.variables.maxWaitingTime || 25;
            const step = this.variables.stepWaitingTime || 10;
            const prefixMin = this.$text('prefix_min_waiting_time', this.lang);
            const prefixMax = this.$text('prefix_max_waiting_time', this.lang);
            const prefix = this.$text('prefix_waiting_time', this.lang);
            const between = this.$text('between_waiting_time', this.lang);
            const minutes = this.$text('minutes_waiting_time', this.lang);
            return Handlebars.compile(`{{binned_count current_ttw min=${min} max=${max} step=${step} prefixMin="${prefixMin}" prefixMax="${prefixMax}" betweenInterval="${between}" prefix="${prefix}"}} ${minutes}`)(customer);
        },
        render_customer_status(customer) {
            const suborganization = this.$store.state.infos.suborganization;
            const tz = suborganization.tz;
            let status = '';

            if (customer.processed) {
                return status;
            }

            if (customer.called) {
                status = this.texts.its_your_turn[this.lang];
            } else if (customer.meeting) {
                status = `${this.texts.meeting_at[this.lang]} `
                + `${moment(customer.mtimestamp).tz(tz).format('HH:mm')}`;
            } else {
                status = this.texts.waiting[this.lang];
            }
            return status;
        },
        customer_desk(customer) {
            if (customer.processed) {
                return this.texts.question_mark[this.lang];
            }

            if (customer.called) {
                const { vendor } = customer;
                const desk = this.$store.state.infos.desks[customer.desk];
                const name = vendor ? (vendor.value || desk.name) : desk.name;
                const orientation = 'html_orientation' in desk &&
                    this.lang in desk.html_orientation &&
                    desk.html_orientation[this.lang].length > 0 ?
                    desk.html_orientation[this.lang] : '';

                if (orientation.length > 0) {
                    return `${name}<br />${orientation}`;
                }
                return name;
            }
            return this.texts.question_mark[this.lang];
        },
        renderStatusClasses(customer) {
            const classes = [];
            if (customer.called && !customer.processed) {
                classes.push('called');
                if (this.conditions.animate_status) {
                    classes.push('animated heartBeat slow');
                }
            }

            return classes.join(' ');
        },
    },
    computed: _.merge({
        last() {
            return this.$store.state.screen.last || '';
        },
        sortedCustomers() {
            // Force update here
            this.state.refreshKey;

            this.customers.sort((a, b) => +moment(a.rsvptimestamp) - +moment(b.rsvptimestamp));
            let called = this.customers.filter(c => c.called);
            called.sort((a, b) => +moment(b.ctimestamp) - +moment(a.ctimestamp));

            if(this.conditions.discard_called_customers_after_n_secs) {
                called = called.filter((c) => {
                    const t = moment(c.ctimestamp);
                    const elapsed_ms = moment() - t;
                    return elapsed_ms <= ((this.variables.discard_timeout_secs || 30) * 1000)
                }) 
            }
            const uncalled = this.customers.filter(c => !c.called);
            return [...called, ...uncalled];
        },
    }, Vuex.mapGetters({ customers: 'screen_customers' })),
    watch: {
        last(l) {
            if (l && l.trim() !== '') {
                this.play_sound();
            }
        },
    },
    beforeCreate() {
        moment.locale(this.$store.state.interfaceLang.toLowerCase());
    },
    beforeDestroy() {
        if (this.state.interval) {
            clearInterval(this.state.interval);
        }
        if (this.state.taskInterval) {
            clearInterval(this.state.taskInterval);
        }
    },
    mounted() {
        this.state.interval = setInterval(this.update_datetime.bind(this),
                this.variables.refresh_datetime_interval);

        if (this.conditions.discard_called_customers_after_n_secs) {
            this.state.taskInterval = setInterval(() => {
                this.state.refreshKey += 1;
            }, 5 * 1000);
        }
    },
};
