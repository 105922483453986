<template>
    <div class="desk-timer-container">
        <div clas="desk-timer-start-text" v-if="state.mode === 'delay'">{{$text('timer_starts_in')}}</div>
        <div class="desk-timer">
            <div class="desk-timer-part" :style="{backgroundColor: colors.primary}" v-if="state[state.mode].shows.days">
                <span :class="[
                    'days desk-timer-number',
                    { 'blinking-timer': state[state.mode].shouldBlink } 
                ]" :style="{backgroundColor: colors.secondary}">
                    {{state[state.mode].days.toString().padStart(2, '0')}}
                </span>
                <div :class="[
                    'desk-timer-text', 
                ]">
                    {{$text('timer_days')}}
                </div>
            </div>
            <div class="desk-timer-part" :style="{backgroundColor: colors.primary}" v-if="state[state.mode].shows.hours">
                <span :class="[
                    'hours desk-timer-number',
                    { 'blinking-timer': state[state.mode].shouldBlink } 
                ]" :style="{backgroundColor: colors.secondary}">
                    {{state[state.mode].hours.toString().padStart(2, '0')}}
                </span>
                <div :class="[
                    'desk-timer-text', 
                ]">
                    {{$text('timer_hours')}}
                </div>
            </div>
            <div class="desk-timer-part" :style="{backgroundColor: colors.primary}" v-if="state[state.mode].shows.minutes">
                <span :class="[
                    'minutes desk-timer-number',
                    { 'blinking-timer': state[state.mode].shouldBlink } 
                ]" :style="{backgroundColor: colors.secondary}">
                    {{state[state.mode].minutes.toString().padStart(2, '0')}}
                </span>

                <div :class="[
                    'desk-timer-text', 
                ]">
                    {{$text('timer_minutes')}}
                </div>
            </div>
            <div class="desk-timer-part" :style="{backgroundColor: colors.primary}" v-if="state[state.mode].shows.seconds">
                <span :class="[
                    'seconds desk-timer-number',
                    { 'blinking-timer': state[state.mode].shouldBlink } 
                ]" :style="{backgroundColor: colors.secondary}">
                    {{state[state.mode].seconds.toString().padStart(2, '0')}}
                </span>
                <div :class="[
                    'desk-timer-text', 
                ]">
                    {{$text('timer_seconds')}}
                </div>
            </div>
        </div>
        <button class="button desk-timer-start-button" v-if="defaultMode === 'manual' && !state.time.started && state.time.remaining > 0" @click.prevent="() => start('time')">{{$text('start_timer')}}</button>
    </div>
</template>

<script src="./Timer.js">
</script>
