<template>
<div class="container is-fluid information container-top">
    <div class="columns is-marginless">
        <div class="colum is-paddingless is-marginless" v-if="variables.logo !== ''">
            <img class="logo is-landscape is-20-height" :src="variables.logo" alt='Logo' title='Logo' />
            <img class="logo is-portrait is-10-height" :src="variables.logo" alt='Logo' title='Logo' />
        </div>
    </div>
</div>
</template>

<script>
    module.exports = require('./InformationHeader');
</script>
