<template>
<div>
    <div v-show="!variables.disable_keyboard && state.show_keyboard" class="fixed-keyboard">
        <email-pad :on-key-change="keyboard_change" primary-color="#EEE" />
    </div>
    <iframe
        id="iframe-widget-id"
        :src="variables.iframe"
        >{{texts.no_support[lang]}}</iframe>
</div>
</template>

<script>
    module.exports = require('./Iframe');
</script>
