<template>
  <div>
    <audio autoplay="autoplay" v-for="(s, i) in state.sounds" :key="i">
      <source :src="`${sound_path}.mp3`" type="audio/mpeg" />
      <source :src="`${sound_path}.ogg`" type="audio/ogg" />
      <embed
        hidden="true"
        autostart="true"
        loop="false"
        :src="`${sound_path}.mp3`"
      />
    </audio>
    <div
      v-if="conditions.datetime"
      class="columns is-paddingless is-marginless"
    >
      <div class="column has-text-right is-paddingless has-small-margins">
        <div
          class="h3 is-capitalized datetime"
          v-html="state.datetime.format(variables.datetime_format)"
        ></div>
      </div>
    </div>
    <table class="table is-fullwidth is-narrow is-marginless">
      <thead>
        <tr>
          <th class="h2 has-text-centered customer-column-header">
            {{ texts.customer[lang] }}
          </th>
          <th class="h2 has-text-centered status-column-header">
            {{ texts.status[lang] }}
          </th>
          <th
            v-if="!conditions.hide_adviser_column"
            class="h2 has-text-centered adviser-column-header"
          >
            {{ texts.adviser[lang] }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="sortedCustomers.filter((c) => !c.processed).length === 0">
          <td class="h2 has-text-centered" colSpan="3">
            {{ texts.nobody[lang] }}
          </td>
        </tr>
        <tr
          class="customer-row"
          v-else
          v-for="customer in sortedCustomers.filter((c) => !c.processed)"
        >
          <td class="customer-column h3 has-text-centered">
            <strong>{{ customer.display }}</strong>
          </td>
          <td
            :class="
              `status-column h3 ${renderStatusClasses(
                customer
              )} has-text-centered`
            "
          >
            {{ render_customer_status(customer) }}
            <template v-if="conditions.show_waiting_time && !customer.called">
              <br />
              <span class="waiting-time-info h6"
                >({{ $text("waiting_time", lang) }}
                {{ waiting_time_binned(customer) }})</span
              >
            </template>
          </td>
          <td
            v-if="!conditions.hide_adviser_column"
            class="adviser-column h3 has-text-centered"
            v-html="customer_desk(customer)"
          ></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
module.exports = require("./WaitingScreen");
</script>
