
<template>
<div class="container">
    <div class="columns is-mobile">
        <div class="column is-12 has-text-centered">
            <a class="button is-dark" @click="back">
                <span class="icon">
                    <i class="fa fa-arrow-left" />
                </span>
                <span>{{texts.back[lang]}}</span>
            </a>
        </div>
    </div>
    <div v-if="conditions.show_as_single_queue">
        <div v-for="customer in all_sorted_customers" class="columns is-mobile">
            <div class="column is-12">
                <desk-customer :texts="texts" :variables="variables" 
                :conditions="conditions" :customer="customer"
                :lang="lang" :events="events" />
            </div>
        </div>
    </div>
    <div v-for="key in prequal_keys" v-else>
        <div class="columns is-mobile">
            <div class="column has-text-centered is-marginless">
                <h3>{{$store.state.infos.prequalifications[key].name[lang]}}</h3>
            </div>
            <hr class="fv-hr-margin" />
        </div>
        <div v-for="customer in queues[key]" class="columns is-mobile">
            <div class="column is-12">
                <desk-customer :texts="texts" :variables="variables" 
                :conditions="conditions" :customer="customer"
                :lang="lang" :events="events"  />
            </div>
            <hr class="fv-hr-margin" />
        </div>
    </div>
    <div class="columns is-mobile">
        <div class="column is-12 has-text-centered">
            <a class="button is-dark" @click="back">
                <span class="icon">
                    <i class="fa fa-arrow-left" />
                </span>
                <span>{{texts.back[lang]}}</span>
            </a>
        </div>
    </div>
</div>
</template>

<script>
module.exports = require('./Queue');
</script>
