module.exports = {
    name: 'PhonePad',
    props: {
        keyClasses: { default: '' },
        primaryColor: {},
        secondaryColor: { default: '#424242' },
        okColor: { default: '#4CAF50' },
        cancelColor: { default: '#FDD835' },
        onKeyChange: {},
    },
    data() {
        return {
            keyboard: [
                [this.defaultKey('1'), this.defaultKey('2'), this.defaultKey('3')],
                [this.defaultKey('4'), this.defaultKey('5'), this.defaultKey('6')],
                [this.defaultKey('7'), this.defaultKey('8'), this.defaultKey('9')],
                [this.backspaceKey(), this.defaultKey('0'), this.enterKey()],
            ],
        };
    },
    methods: {
        defaultKey(digit) {
            return {
                class: this.keyClasses,
                primaryColor: this.secondaryColor,
                digit,
                onKeyChange: this.onKeyChange,
            };
        },
        backspaceKey() {
            return {
                class: this.keyClasses,
                primaryColor: this.cancelColor,
                digit: 'BCKSPACE',
                backspace: 'C',
                onKeyChange: this.onKeyChange,
            };
        },
        enterKey() {
            return {
                class: this.keyClasses,
                primaryColor: this.okColor,
                digit: 'ENTER',
                enter: 'OK',
                onKeyChange: this.onKeyChange,
            };
        },
    },
};
