const Request = require('superagent');
const Ascii = require('./ascii_folder');
const _ = require('lodash');
const EscPos = require('./escpos_encoder');
const Utils = require('./utils');

function sendToLocalServerOfRpi(data) {
    Request.post('http://localhost:3011/api/a/actions/print')
    .send(data)
    .then(() => {})
    .catch(err => console.error('Error when printing'));
}

function connectToKBPrinterAndPrint(data) {
    try {
        if (KBBluetooth.isConnected()) {
            printTicket(data);
        } else {
            KBBluetooth.disconnect();
            if (data.mac && data.mac.trim() !== '') {
                KBBluetooth.connectTo(data.mac);
            } else {
                KBBluetooth.connect();
            }
      // attempt print in 5 seconds
            setTimeout(() => printTicket(data), 5000);
        }
    } catch (err) {}
}

function printUsingIpm(data) {
    Kiosk.TicketPrinting.printRawHtml({
        html: data.html,
    });
}

function printTicket(data) {
    const esc = new EscPos(data.mode);
    const lines = data.mode.endsWith('sunmi')
    ? Ascii.fold(data.html).split('\n')
    : data.html.split('\n');
    const info = lines
    .reduce((e, l) => {
        if (l.startsWith('__A')) {
            const a = l.replace('__A', '').trim();
            return e.align(a);
        } else if (l.startsWith('__S')) {
            const s = l.replace('__S', '').trim();
            return e.size(s);
        } else if (l.startsWith('__F')) {
            return e.newline();
        } else if (l.startsWith('__QR')) {
            const q = l.replace('__QR', '').trim();
            return e.qrcode(q);
        }
        return e.line(l);
    }, esc)
    .feed()
    .feed()
    .feed()
    .feed()
    .feed()
    .cut()
    .encode(true);
    KBBluetooth.sendHexString(info);
}

module.exports = {
    connectToKBPrinterAndPrint,
    printTicket,
    printUsingIpm,
    sendToLocalServerOfRpi,
};
