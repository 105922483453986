const _ = require('lodash');
const Vuex = require('vuex');
const Messages = require('../../../../../api/messages');
const EventType = require('../../../../../enums/event_types');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');

module.exports = {
    mixins: [MasterWidget],
    data() {
        return {};
    },
    methods: {
        swipe(dir, e) {
            return this.emitEvent(e);
        },
        emitEvent(e) {
            e.preventDefault();
            try {
                e.stopPropagation();
            } catch (err) {

            }
            let path = this.$store.state.template.view_infos.prequalification_path || [];
            path = path.slice(0, -1);
            const payload = {
                eventType: EventType.ALL,
                eventValue: 'NA',
                event: 'none',
                eventOptions: {
                    depth: Math.max(this.depth - 1, 0),
                    prequalification_path: path,
                },
            };
            this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
        },
    },
    computed: _.merge({
        lang() {
            return this.$store.state.interfaceLang;
        },
    }, Vuex.mapGetters(['depth'])),
};
