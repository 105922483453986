const Messages = require("../../../../../api/messages");
const EventType = require("../../../../../enums/event_types");
const MasterWidget = require("../../../mixins/master_widget/MasterWidget");
const WithTimeout = require("../../../mixins/with_timeout/WithTimeout");

const LANG_TEXT_MAPPING = {
  aa: "Afaraf",
  ab: "Аҧсуа",
  ae: "Avestan",
  af: "Afrikaans",
  ak: "Akan",
  am: "አማርኛ",
  an: "Aragonés",
  ar: "العربية",
  as: "অসমী",
  av: "Авар",
  ay: "Aymar aru",
  az: "Azərbaycan dili",
  ba: "Башҡорт теле",
  be: "Беларуская мова",
  bg: "Български език",
  bh: "भोजपु)",
  bi: "Bislama",
  bm: "Bamanankan",
  bn: "বাং",
  bo: "བོད་སྐད",
  br: "Brezhoneg",
  bs: "Bosanski jezik",
  ca: "Català",
  ce: "Нохчийн мотт",
  ch: "Chamoru",
  co: "Corsu",
  cr: "ᓀᐦᐃᔭᐍᐏᐣ",
  cs: "Čeština",
  cu: "Ѩзыкъ словѣньскъ",
  cv: "Чӑвашла",
  cy: "Cymraeg",
  da: "Dansk",
  de: "Deutsch",
  dv: "ދިވެހިބަސް",
  dz: "རྫོང་ཁ",
  ee: "Eʋegbe",
  el: "Ελληνικά",
  en: "English",
  eo: "Esperanto",
  es: "Español",
  et: "Eesti",
  eu: "Euskara",
  fa: "فارسی",
  ff: "Fulfulde",
  fi: "Suomi",
  fj: "Vosa Vakaviti",
  fo: "Føroyskt",
  fr: "Français",
  fy: "Frysk",
  ga: "Gaeilge",
  gd: "Gàidhlig",
  gl: "Galego",
  gn: "Avañe'ẽ",
  gu: "ગુજરા",
  gv: "Gaelg",
  ha: "Hausa",
  he: "עברית",
  hi: "हिन्",
  ho: "Hiri Motu",
  hr: "Hrvatski",
  ht: "Kreyòl ayisyen",
  hu: "Magyar",
  hy: "Հայերեն",
  hz: "Otjiherero",
  ia: "Interlingua",
  id: "Bahasa Indonesia",
  ie: "Interlingue",
  ig: "Asụsụ Igbo",
  ii: "四川彝语",
  ik: "Iñupiaq",
  io: "Ido",
  is: "Íslenska",
  it: "Italiano",
  iu: "ᐃᓄᒃᑎᑐᑦ",
  ja: "日本語",
  jv: "ꦧꦱꦗꦮ",
  ka: "ქართული",
  kg: "Kikongo",
  ki: "Gĩkũyũ",
  kj: "Kuanyama",
  kk: "Қазақ тілі",
  kl: "Kalaallisut",
  km: "ភាសាខ្ម",
  kn: "ಕನ್ನಡ",
  ko: "한국어",
  kr: "Kanuri",
  ks: "कश्मी",
  ku: "Kurdî",
  kv: "Коми кыв",
  kw: "Kernewek",
  ky: "Кыргызча",
  la: "Latine",
  lb: "Lëtzebuergesch",
  lg: "Luganda",
  li: "Limburgs",
  ln: "Lingála",
  lo: "ພາສາລາວ",
  lt: "Lietuvių kalba",
  lu: "Luba-Katanga",
  lv: "Latviešu valoda",
  mg: "Malagasy",
  mh: "Kajin M̧ajeļ",
  mi: "Māori",
  mk: "Македонски јазик",
  ml: "മലയാ",
  mn: "Монгол хэл",
  mo: "Moldovenească",
  mr: "मरा",
  ms: "Bahasa Melayu",
  mt: "Malti",
  my: "မြန်မ",
  na: "Dorerin Naoero",
  nb: "Norsk Bokmål",
  nd: "isiNdebele",
  ne: "नेपा",
  ng: "Owambo",
  nl: "Nederlands",
  nn: "Norsk nynorsk",
  no: "Norsk",
  nr: "isiNdebele",
  nv: "Diné bizaad",
  ny: "ChiCheŵa",
  oc: "Occitan",
  oj: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
  om: "Afaan Oromo",
  or: "ଓଡ଼ିଆ",
  os: "Ирон æвзаг",
  pa: "ਪੰਜਾ",
  pi: "Pāli",
  pl: "Polski",
  ps: "پښتو",
  pt: "Português",
  qu: "Runa Simi",
  rc: "Romansh",
  rm: "Rumantsch",
  rn: "Ikirundi",
  ro: "Română",
  ru: "Русский",
  rw: "Kinyarwanda",
  sa: "संस्कृतम्",
  sc: "Sardu",
  sd: "سنڌي",
  se: "Sámegiella",
  sg: "Sängö",
  sh: "Српскохрватски",
  si: "සිංහ",
  sk: "Slovenčina",
  sl: "Slovenščina",
  sm: "Gagana Samoa",
  sn: "ChiShona",
  so: "Af-Soomaali",
  sq: "Shqip",
  sr: "Српски",
  ss: "SiSwati",
  st: "Sesotho",
  su: "Basa Sunda",
  sv: "Svenska",
  sw: "Kiswahili",
  ta: "தமி",
  te: "తెలుగు",
  tg: "тоҷикӣ",
  th: "ไทย",
  ti: "ትግርኛ",
  tk: "Türkmen",
  tl: "Wikang Tagalog",
  tn: "Setswana",
  to: "Lea Faka-Tonga",
  tr: "Türkçe",
  ts: "Xitsonga",
  tt: "Татар теле",
  tw: "Twi",
  ty: "Reo Tahiti",
  ug: "ئۇيغۇرچە",
  uk: "Українська",
  ur: "اردو",
  uz: "Oʻzbekcha",
  ve: "Tshivenḓa",
  vi: "Tiếng Việt",
  vo: "Volapük",
  wa: "Walon",
  wo: "Wolof",
  xh: "isiXhosa",
  yi: "ייִדיש",
  yo: "Yorùbá",
  za: "話",
  zh: "中文",
  zu: "isiZulu",
};

module.exports = {
  mixins: [MasterWidget, WithTimeout],
  data() {
    return {
      state: {},
    };
  },
  methods: {
    clickLang(key, event) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      const payload = {
        event: this.variables.next_event,
        eventType: EventType.ALL,
        eventValue: this.variables.next_event_value || "NA",
        eventOptions: { lang: key },
        commit: this.$store.commit,
      };
      return this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
    },
    tap(key) {
      return (e) => this.clickLang(key, e);
    },
    swipe(key) {
      return (dir, e) => this.clickLang(key, e);
    },
  },
  computed: {
    matrix() {
      const itemsPerRow = this.variables.items_per_row || 4;
      return this.suborganizationLangs.reduce(
        (rows, key, index) =>
          (index % itemsPerRow === 0
            ? rows.push([key])
            : rows[rows.length - 1].push(key)) && rows,
        []
      );
    },
    suborganizationLangs() {
      const { suborganization } = this.$store.state.infos;
      const { langs } = suborganization;
      return langs || [];
    },
    flagsMapping() {
      const mapping = {
        en: this.conditions.uk_flag_for_english ? "gb" : "us",
      };

      if (this.conditions.use_name_instead_of_flag) {
        return (key) => LANG_TEXT_MAPPING[key] || key;
      }
      return (key) => mapping[key] || key;
    },
  },
  mounted() {},
};
