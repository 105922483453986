const _ = require('lodash');
const moment = require('moment');
const Messages = require('../api/messages');
const QueueUtils = require('./queue');
const PrintingUtils = require('./printing');

async function execute_websocket_task(device, task) {
    const task_type = task.type;

    if (task_type !== 'websocket') {
        return;
    }

    const did = task.device;
    if (did !== device._id) {
        return;
    }

    const content = task.content.script.trim();
    switch (content) {
    case 'reload':
        window.location.reload(true);
        break;
    default:
        break;
    }
}

async function machine_dispatch(id, data, ctx) {
    const infos = ctx.state.infos;
    const device = infos.device;
    switch (id) {
    case 'queue-update-global-ttw': {
        infos.global_waiting = data.global_waiting;
        infos.number_of_rsvp_customers = data.number_of_rsvp_customers;
        infos.number_of_meeting_customers = data.number_of_meeting_customers;
        infos.number_of_rsvp_meeting_customers =
        data.number_of_rsvp_meeting_customers;
        infos.number_of_meetings_soon = data.number_of_meetings_soon;
        break;
    }
    case 'vibrator-configured': {
        ctx.state.machine.vibrator_configuration = data;
        break;
    }
    case 'print-job-finished': {
        if (`${data.device}` === `${device._id}`) {
            ctx.state.machine.printer_finished = data.ok || false;
        // Just to force an update
            ctx.commit(Messages.UPDATE_TEMPLATE_TEXT, {
                pageNumber: ctx.state.template.currentPage,
            });
        }
        break;
    }
    case 'barcode-scan-result': {
        if (`${data.device}` === `${device._id}`) {
            ctx.state.machine.barcode_result = data.content;
        // Just to force an update
            ctx.commit(Messages.UPDATE_TEMPLATE_TEXT, {
                pageNumber: ctx.state.template.currentPage,
            });
        }
        break;
    }
    case 'queue-waiting-times': {
        ctx.state.machine.global_waiting = Math.max(
        0,
        Math.round(data.global_waiting),
      );
      // Just to force an update
        ctx.commit(Messages.UPDATE_TEMPLATE_TEXT, {
            pageNumber: ctx.state.template.currentPage,
        });
        break;
    }
    case 'websocket-task': {
        await execute_websocket_task(ctx.state.infos.device, data.task);
        break;
    }
    case 'device-close-desk': {
        const desk = data.desk;
        ctx.state.infos.desks[`${desk._id}`] = desk;
        break;
    }
    case 'update-machine-infos': {
        ctx.state.infos = _.reduce(
        data.infos,
        (obj, val, key) => {
            obj[key] = val;
            return obj;
        },
        ctx.state.infos,
      );
        break;
    }
    case 'print-job': {
        if (`${data.device}` === `${device._id}`) {
            if (data.mode === 'kiosk+rpi') {
                PrintingUtils.sendToLocalServerOfRpi(data);
            } else if ('Kiosk' in window) {
                PrintingUtils.printUsingIpm(data);
            } else {
                PrintingUtils.connectToKBPrinterAndPrint(data);
            }
        }
        break;
    }
    default:
        break;
    }
}

async function desk_dispatch(id, data, ctx) {
    const infos = ctx.state.infos;
    const device = infos.device;
    switch (id) {
    case 'queue-meetings': {
        infos.meeting_customers = data.meeting_customers.filter((customer) => {
            if (
          !QueueUtils.am_i_the_right_device_for_this_customer(infos, customer)
        ) {
                return false;
            }
            return true;
        });
        break;
    }
    case 'queue-updated-customers': {
        data.customers.forEach((customer) => {
            if (
          !QueueUtils.am_i_the_right_device_for_this_customer(infos, customer)
        ) {
                return;
            }
            infos.waiting_customers = _.unionBy(
          [customer],
          infos.waiting_customers,
          e => e._id,
        );
            if (customer.meeting) {
                infos.meeting_customers = _.unionBy(
            [customer],
            infos.meeting_customers,
            e => e._id,
          );
            }
        });
        break;
    }
    case 'queue-register': {
        const customer = data.customer;
        if (QueueUtils.am_i_the_right_device_for_this_customer(infos, customer)) {
            infos.waiting_customers.push(customer);
            if (customer.meeting) {
                infos.meeting_customers = _.unionBy(
            [customer],
            infos.meeting_customers,
            e => e._id,
          );
            }
            ctx.state.desk.new_customer = customer._id;
        }
        break;
    }
    case 'queue-new-meeting': {
        const customer = data.customer;
        if (QueueUtils.am_i_the_right_device_for_this_customer(infos, customer)) {
            infos.meeting_customers.push(customer);
            infos.meeting_customers.sort(
          (a, b) => +moment(a.mtimestamp) <= +moment(b.mtimestamp),
        );
        }
        break;
    }
    case 'queue-multi-waiting':
    case 'queue-waiting-meeting':
    case 'queue-next': {
        const new_infos = _.reduce(
        data,
        (obj, val, key) => {
            if (key === 'waiting_customers_by_desk') {
                if (device._id in val) {
                    obj.waiting_customers = val[device._id];
                }
            } else if (key === 'multi_waiting_customers_by_desk') {
                if (device._id in val) {
                    obj.multi_waiting_customers = val[device._id];
                }
            } else if (key === 'meeting_customers_by_desk') {
                if (device._id in val) {
                    obj.meeting_customers = val[device._id];
                }
            } else {
                obj[key] = val;
            }
            return obj;
        },
        infos,
      );
        ctx.state.infos = Object.assign({}, new_infos);
        if (data.customer && data.customer.desk === device._id) {
            ctx.state.desk.customer = data.customer;
        }
        break;
    }
    case 'device-close-desk': {
        const desk = data.desk;
        if (`${desk._id}` === `${device._id}`) {
            infos.device = desk;
        }
        break;
    }
    case 'device-update-prequalifications-by-desk': {
        const did = device._id;
        if (did in data.prequalifications) {
            const pqs = data.prequalifications[did];
            infos.prequalifications = pqs.reduce((obj, pq) => {
                obj[`${pq._id}`] = pq;
                return obj;
            }, {});
        }
        break;
    }
    case 'websocket-task': {
        await execute_websocket_task(infos.device, data.task);
        break;
    }
    case 'update-desk-infos': {
        ctx.state.infos = _.reduce(
        data.infos,
        (obj, val, key) => {
            obj[key] = val;
            return obj;
        },
        ctx.state.infos,
      );
        break;
    }
    default:
        break;
    }
}

async function screen_dispatch(id, data, ctx) {
    const infos = ctx.state.infos;
    switch (id) {
    case 'queue-register': {
        const customer = data.customer;
        if (QueueUtils.am_i_the_right_device_for_this_customer(infos, customer)) {
            infos.waiting_customers.push(customer);
        }
        break;
    }
    case "queue-processed":
    case "queue-next": {
        const customer = data.customer;
        if (!QueueUtils.am_i_the_right_device_for_this_customer(infos, customer)) {
          return;
        }
        const waitingTimes = _.reduce(data.waiting_customers_by_desk, (acc, val) => {
          val.forEach((c) => {
              acc[c._id] = c.current_ttw;
          });
          return acc;
        }, {});

        const waitings = infos.waiting_customers;
        infos.waiting_customers = waitings.filter(
          (c) => `${c._id}` !== `${customer._id}`
        );
        infos.waiting_customers.forEach((c) => {
            const ttw = waitingTimes[c._id] || c.current_ttw;
            c.current_ttw = ttw;
        });

        const last_called = infos.last_called_customers;
        const idx = _.findIndex(last_called,
            o => `${o.desk}` === `${customer.desk}`,
        );

        if (idx === -1) {
            last_called.push(customer);
        } else {
            last_called.splice(idx, 1, customer);
        }

        ctx.state.screen.last = customer.processed ? '' : `${customer._id}`;
        break;
    }

    case 'device-remove-name': {
        const customer = data.customer;
        const waitings = infos.waiting_customers;
        const called = infos.last_called_customers;

        let idx = _.findIndex(waitings, c => `${c._id}` === `${customer._id}`);
        if (idx !== -1) {
            waitings.splice(idx, 1, customer);
        }

        idx = _.findIndex(called, c => `${c._id}` === `${customer._id}`);
        if (idx !== -1) {
            called.splice(idx, 1, customer);
        }

        break;
    }
    case 'queue-no-customer': {
        const last_called = infos.last_called_customers;
        const idx = _.findIndex(
        last_called,
        o => `${o.desk}` === `${data.did}`,
      );
        if (idx !== -1) {
            last_called[idx].processed = true;
        }
        break;
    }
    case 'websocket-task': {
        await execute_websocket_task(infos.device, data.task);
        break;
    }
    default:
        break;
    }
}

function socket_dispatch(type, id, data, ctx) {
    switch (type) {
    default:
    case 'machine':
        return machine_dispatch(id, data, ctx);
    case 'screen':
        return screen_dispatch(id, data, ctx);
    case 'desk':
        return desk_dispatch(id, data, ctx);
    }
}

module.exports = socket_dispatch;
