<template>
    <div
        @click="keyPressed(digit, $event)"
        v-touch:tap="tapPressed(digit)"
        v-touch:longtap="longtapPressed(digit)"
        v-touch:swipe="swipePressed(digit)"
        :class="`button key is-unselectable key-${digit.toLowerCase()}`"
        :style="css"
    >
        <div v-if="digit === 'BCKSPACE'" v-html="backspace">
        </div>
        <div v-else-if="digit === 'ENTER'" v-html="enter">
        </div>
        <div v-else-if="digit === ' '" v-html="space">
        </div>
        <div v-else>
            {{digit}}
        </div>
    </div>
</template>

<script>
module.exports = require('./Key');
</script>
