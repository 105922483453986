<template>
    <nav class="pagination paginator is-rounded is-small" role="navigation" aria-label="pagination">
        <ul class="pagination-list" v-if="useArrows">
            <li class="previous">
                <a
                    @click.stop="(e) => goto(state.currentPage-1)" 
                    v-touch:tap="(e) => goto(state.currentPage-1)"
                    v-touch:swipe="(dir, e) => goto(state.currentPage-1)"
                    :class='["pagination-link", {"is-background-primary white": state.currentPage > 1, "disabled": state.currentPage <= 1}]'>
                    &lt;&lt;
                </a>
            </li>
            <li class="next">
                <a
                    @click.stop="(e) => goto(state.currentPage+1)" 
                    v-touch:tap="(e) => goto(state.currentPage+1)"
                    v-touch:swipe="(dir, e) => goto(state.currentPage+1)"
                    :class='["pagination-link", {"is-background-primary white": state.currentPage < state.lastPage, "disabled": state.currentPage >= state.lastPage}]'>
                    &gt;&gt;
                </a>
            </li>
        </ul>
        <ul class="pagination-list" v-else>
            <li v-for="i in state.lastPage">
                <a 
                @click.stop="(e) => goto(i)" 
                v-touch:tap="(e) => goto(i)"
                v-touch:swipe="(dir, e) => goto(i)"
                :class='["pagination-link", {"is-current": state.currentPage === i}]'>
                    {{i}}
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
    module.exports = require('./Paginator');
</script>
