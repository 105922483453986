const Vuex = require('vuex');
const _ = require('lodash');
const toastr = require('toastr');
const momenttz = require('moment-timezone');
const EventType = require('../../enums/event_types');
const StringUtils = require('../../utils/strings');
const Messages = require('../../api/messages');

module.exports = {
    props: ['customer', 'texts', 'variables', 'conditions',
        'events', 'lang'],
    data() {
        return {
            state: {
                collapsed: this.conditions.collapse_customers_in_queue || false,
            },
        };
    },
    methods: {
        collapse() {
            this.state.collapsed = !this.state.collapsed;
        },
        emitEvent(event, value = 'NA', opts = {}) {
            const payload = {
                commit: this.$store.commit,
                eventType: EventType.ALL,
                eventValue: value,
                event,
                eventOptions: opts,
            };

            this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
        },
        format_visit(visits, days) {
            return StringUtils.format(this.texts.visits[this.lang], visits, days);
        },
        format_meeting(timestamp) {
            return momenttz.tz(timestamp, this.tz).format('HH:mm');
        },
        remove_customer_name(cid, e) {
            e.preventDefault();
            this.$store.dispatch('remove_name', { body: { _id: cid } });
        },
        pick_someone(e) {
            e.preventDefault();
            this.$store.dispatch('next', { customer: this.customer._id });
            this.emitEvent(this.events.home);
        },
    },
    watch: {
        remove_name(new_remove) {
            if (new_remove === 'success') {
                toastr.success(this.texts.name_removed[this.lang],
                        '', { preventDuplicates: true });
                this.$store.state.desk.remove_name = '';
            } else if (new_remove === 'error') {
                toastr.error(this.texts.error_name_removed[this.lang],
                '', { preventDuplicates: true });
                this.$store.state.desk.remove_name = '';
            }
        },
    },
    computed: _.merge({
        suborganization() {
            return this.$store.state.infos.suborganization;
        },
        tz() {
            return this.$store.state.infos.suborganization.tz;
        },
        return_alert() {
            return this.$store.state.infos.suborganization.alerts.customer_return;
        },
        extra_codes() {
            return this.$store.state.infos.suborganization.extra_codes;
        },
        show_remove_name() {
            if (!this.suborganization.allow_remove_name_on_screen) {
                return false;
            }
            return (this.customer.type === 'name'
                || this.customer.type === 'extra_code' || 
                (this.customer.name && this.customer.name.trim() !== '')) && !this.customer.name_removed;
        },
        show_pick_someone() {
            const suborganization = this.$store.state.infos.suborganization;
            return suborganization.pick_someone
                && !this.customer.called;
        },
        show_footer() {
            return this.show_remove_name || this.show_pick_someone;
        },
    }, Vuex.mapGetters(['remove_name'])),
};
