<template>
  <div
    class="container is-fluid lang-selector container-middle is-portrait-vertical"
  >
    <div class="columns is-centered" v-for="(row, i) in matrix" :key="i">
      <div
        :class="
          `is-${12 /
            variables.items_per_row} is-unselectable column has-text-centered`
        "
        v-for="key in row"
        :key="key"
      >
        <div
          :class="['card', `lang-${key}`]"
          :key="key"
          @click.stop.prevent="clickLang(key, $event)"
          v-touch:tap.stop.prevent="tap(key)"
          v-touch:swipe.stop.prevent="swipe(key)"
        >
          <h4 class="h3-portrait">
            <span v-if="conditions.use_name_instead_of_flag">{{
              flagsMapping(key.toLowerCase())
            }}</span>
            <img
              v-else
              :style="{ width: `${100 / variables.items_per_row}%` }"
              :src="
                `/public/front/imgs/flags/${flagsMapping(
                  key.toLowerCase()
                )}.svg`
              "
              :alt="key"
            />
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
module.exports = require("./LangSelector");
</script>
