<template>
<div class="container is-fluid information">
    <div class="columns">
        <div class="column is-8 is-offset-2">
            <h4
                class="has-text-centered"
                v-html="texts.header[lang]"
                />
            </div>
        </div>
        <div class="is-portrait columns is-centered" v-if="variables.fullimage && variables.fullimage.trim() !== ''">
            <div class="column is-8">
                <div class="image">
                    <img :src="variables.fullimage" alt="Store" title="Image" />
                </div>
            </div>
        </div>
        <div class="is-landscape columns is-centered" v-if="variables.fullimage && variables.fullimage.trim() !== ''">
            <div class="column is-6">
                <div class="image">
                    <img :src="variables.fullimage" alt="Store" title="Image" />
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column is-8 is-offset-2">
                <div class="box">
                    <p v-if="steps.length === 1" class="has-text-centered" v-html="steps[0]" />
                    <ol v-else>
                        <li v-for="step in steps" v-html="step" />
                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    module.exports = require('./Information');
</script>
