const $ = require('jquery');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');
const WithTimeout = require('../../../mixins/with_timeout/WithTimeout');

module.exports = {
    mixins: [MasterWidget, WithTimeout],
    data() {
        return {
            state: {
                show_keyboard: false,
                current: '',
                $current: null,
                current_type: null,
            },
        };
    },
    methods: {
        hooked($elt, type) {
            switch (type) {
            case 'input':
            case 'textarea':
                this.state.show_keyboard = true;
                this.state.$current = $elt;
                this.state.current = $elt.val();
                this.state.current_type = type;
                break;
            default:
                break;
            }
        },
        keyboard_change(k, e) {
            e.preventDefault();

            let newContent = '';

            if (k === 'ENTER') {
                this.state.show_keyboard = false;
                return;
            } else if (k === 'BCKSPACE') {
                newContent = this.state.current.slice(0, -1);
            } else {
                newContent = this.state.current + k;
            }

            this.state.$current.val(newContent);
            this.state.current = newContent;
        },
    },
    mounted() {
        const self = this;
        $('#iframe-widget-id').on('load', () => {
            const iframe = $('#iframe-widget-id').contents();
            iframe.click((e) => {
                const tag = e.target.tagName;
                if (!tag || (tag.toLowerCase() !== 'input' && tag.toLowerCase() !== 'textarea')) {
                    return;
                }
                self.hooked($(e.target), tag.toLowerCase());
            });
        });
    },
};
