const EventType = require('../../../../../enums/event_types');
const Messages = require('../../../../../api/messages');
const MasterWidget = require('../../../mixins/master_widget/MasterWidget');

module.exports = {
    mixins: [MasterWidget],
    methods: {
        back(e) {
            e.preventDefault();
            this.emitEvent(this.events.home);
        },
        emitEvent(event, value = 'NA', opts = {}) {
            const payload = {
                commit: this.$store.commit,
                eventType: EventType.ALL,
                eventValue: value,
                event,
                eventOptions: opts,
            };

            this.$store.commit(Messages.UPDATE_TEMPLATE, payload);
        },
    },
    computed: {
    },
};
