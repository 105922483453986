const _ = require('lodash');
const EventType = require('../../../../../../enums/event_types');
const MasterWidget = require('../../../../mixins/master_widget/MasterWidget');
const MeetingStepMixin = require('../MeetingStepMixin');

module.exports = {
    mixins: [MasterWidget, MeetingStepMixin],
    data() {
        return {};
    },
};
